import React, { useState } from "react";
import placeholder_image_6 from "../../../../core/assets/img/love2.jpg";
import {
  WavesAboutImg,
  WavesAboutImgWrap,
  WavesAboutSection,
  WavesAboutText,
  WavesAboutTextWrap,
  WavesAboutWrap,
} from "./styles";
export const NFTWhatIs = () => {
  interface StateInterface {
    showImage: string;
    startSlide: number;
  }
  const [state] = useState<StateInterface>({
    showImage: placeholder_image_6,
    startSlide: 0,
  });
  

  return (
    <WavesAboutSection className="content-wrapper">
      <h2>What is an NFT?</h2>
      <WavesAboutWrap>
        <WavesAboutImgWrap>
          <WavesAboutImg backgroundImg={state.showImage} />
        </WavesAboutImgWrap>
        <WavesAboutTextWrap>
          <WavesAboutText>
            <h3>Non Fungible Tokens</h3>
            <p>
              Non-fungible tokens, more commonly known as NFTs, are a technology
              that is revolutionizing the way we trade digital art. But what
              does it stand for? If something is fungible it means it is
              interchangeable. The most common example of a fungible item is
              money. You can exchange it and split it but the value will remain
              the same. A non-fungible object, on the other hand, can not be
              exchanged, since each object is unique and one-of-a-kind.{" "}
            </p>
          </WavesAboutText>
          <WavesAboutText>
            <h3>Digital Ownership</h3>
            <p>
              Connecting a non-fungible token to a piece of digital art makes it
              possible for it to be owned by a single owner. The token becomes a
              certificate of authenticity and ownership, which is stored in a
              public ledger so that everyone can clearly see who owns what.
              Connecting an art piece with an NFT allows you to prove that you
              indeed own a beautiful piece of art, for you to enjoy, collect,
              sell or give away.
            </p>
          </WavesAboutText>
          <WavesAboutText>
            <h3>A new era of digital art</h3>
            <p>
              The NFT technology is disrupting the way we create, own, and
              gather around our favorite art, by enabling us to connect and
              bridge the gap between the physical and digital art scene. Digital
              museums and galleries are already on the rise, reputable auction
              houses such as Christie’s are selling NFT-artworks for millions of
              dollars and exclusive ownership communities are growing rapidly on
              digital communication platforms such as Discord.{" "}
            </p>
          </WavesAboutText>
        </WavesAboutTextWrap>
      </WavesAboutWrap>
    </WavesAboutSection>
  );
};
