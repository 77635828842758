import { NFTS } from "routing/appRoute/paths"
import { ButtonLink } from "shared/components/ButtonLink"
import { NftWavesImg, NftWavesText, NtfWavesWrap } from "./styles"

export const NftWaves = () => {
    return (
        <NtfWavesWrap className="content-wrapper">
            <NftWavesText>
                <h2 className="white">NFT Photographs</h2>
                <p>For each of the seven artworks, there exists 99 unique photographs, minted as NFTs. <br></br>As an owner of these NFTs, you are invited to a guided meditation, by the artist, where you get to imbue that NFT with your own interpretation of the emotion the artwork embodies. These words are stored on the blockchain and changes the physical artwork in real time.</p>
                <ButtonLink className="small" to={NFTS}>Read more</ButtonLink>
            </NftWavesText>
            <NftWavesImg>
                <div></div>
            </NftWavesImg>
        </NtfWavesWrap>
    )
}