const { REACT_APP_USE_PROD } = process.env;

export function submitHandler() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "signup",
  });
}

const href = window.location.href;
let isProd : boolean;

isProd = true;
if (window.location.hostname.includes("localhost")) {
  isProd = false;
}
if (window.location.hostname.includes("website-dev")) {
  isProd = false;
}

export const correctNetwork: string = isProd ? "homestead" : "matic";

export const apiName = "owners";

export const nftAddress = "0x000001E1b2b5f9825f4d50bD4906aff2F298af4e";
export const minterAddress = correctNetwork === "homestead"
    ? "0x17101E805A661b7aC0282362dbC14E610cEF5148" // mainnet
    : "0x94de05d2a117d7530d282435287b2f72e97820e3"; // polygon
export const infuraId = "d8d910306de34a6e994efdcce0fae846";

export const imbuedartLink = `https://api.imbuedart.com/api/token/${isProd ? "" : "p"}`;
export const openseaLink = "https://api.opensea.io/api/v1/asset/";

export const auctionDateStart = new Date(Date.UTC(2022, 1, 9, 15, 0, 0));
export const auctionDateEnd = new Date(Date.UTC(2022, 1, 13, 19, 0, 0));

export function isAuctionStarted() {
  return Date.now() > Date.parse(auctionDateStart.toString());
}

export function isAuctionEnd() {
  return Date.now() > Date.parse(auctionDateEnd.toString());
}

export const THUMBNAILS_DIR = "weave_thumbnails";
export const MIDERS_THUMBNAILS_DIR = "weave_midres";
export const getThumbnail = (id, dir = THUMBNAILS_DIR) => {
  return `${process.env.PUBLIC_URL}/${dir}/${id}.jpg`;
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getArtworkNameById = (id) => {
  if (id >= 0 && id < 100) return 'love';
  else if (id >= 100 && id < 200) return 'awe';
  else if (process.env.REACT_APP_LIFE === 'true' && id >= 200 && id < 300) return 'life';
  else if (process.env.REACT_APP_LONGING === 'true' && id >= 300 && id < 400) return 'longing';
  else if (process.env.REACT_APP_FRIENDSHIP === 'true' && id >= 400 && id < 500) return 'friendship';
  else if (process.env.REACT_APP_GRIEF === 'true' && id >= 500 && id < 600) return 'grief';
  else if (process.env.REACT_APP_APPRECIATION === 'true' && id >= 600 && id < 700) return 'appreciation';
  
  return "";
}

export function validateEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}
