import styled from "styled-components";
import artist from "core/assets/img/artist.png";
import { buttonSmallSize, marginBottomStyle2 } from "core/styles/mixins";

export const ArtistSec = styled.div`
    margin: 60px auto;
`

export const ActorCard = styled.div`
  margin: ${({ theme }) => theme.contentSpacing} auto;
  width: 100%;
  height: 764px;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  border-radius: 0 10% 0 10%;
  background-image: url(${artist});
  background-size: cover;
  background-position: center;
  max-width: ${({ theme }) => theme.contentWidth};
  @media(min-width: 1281px) and (max-width: 1440px){
    display: flex;
    margin: auto;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 565px;
  }
  @media(max-width:1280px){
    display: flex;
    max-width: 600px;
    margin: auto;
    background-position: 50% 48%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    min-height: 505px;
  }
  @media(max-width: 1024px){
    min-height: 406px;
  }
  @media(max-width: 425px){
    width: 100%;
    height: 500px;
    ${marginBottomStyle2}
    background-position: 50% 80%;
  }
`;

export const InfoAbout = styled.div`
  height: 33%;
  width: 100%;
  padding: 33px 50px;
  background: rgba(71, 63, 63, 0.61);
  display: flex;
  justify-content: space-between;
  @media(min-width: 1281px) and (max-width: 1440px){
    height: 33%;
    width: 100%;
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
  }
  @media(max-width: 1280px){
    padding: 36px;
  }
  @media(max-width: 1024px){
    padding: 20px 36px;
  }
  @media(max-width: 425px){
    height: 43%;
    padding: 10px 36px;
  }
`;

export const ArtistInfo = styled.div`
width: 70%;
@media(max-width: 1280px){
  display: flex;
  width: 100%;
  justify-content: space-between;
}
@media(max-width: 1024px){
  flex-wrap: wrap;
    width: 100%;
    flex-direction: column;
    align-items: center;
}
& h3 {
  font-size:1.8em;
  margin-bottom: 0;
}
& p{
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: 28px;
  @media(min-width: 1281px) and (max-width: 1440px){
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: 22px;
  }
  @media(max-width: 425px){
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: 20px;
  }
  @media(max-width: 1280px){
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: 22px;
  }
}
& div{

  @media(max-width: 1280px){
    width:50%
  }
  @media(max-width: 1024px){
    width: 100%;
  }
  
}
  & .button-box {
  justify-content: space-between;
  align-items: center;
  margin-bottom:20px
  }

.button-anchor {
float: left;
  margin: 0 10px;
  @media(min-width: 1024px) and (max-width: 1440px) {
    margin-top: 10px;
    ${buttonSmallSize}
  }
  @media(max-width: 425px){
    ${buttonSmallSize}
    float:none;
   margin: 10px auto ;
   padding:20px;

  }
}
`

export const ArtistInfoText = styled.div`
  width: 50%;
  display: block;
  @media(max-width: 1280px){
    display: none;
  }
  & p{
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.white};
    @media(min-width: 1281px) and (max-width: 1440px){
      font-size: 15px;
    }
  }
`
export const ArtistInfoTextMob = styled.div`
  display: none;
  @media(max-width: 1280px){
    display: block;
    padding: 0 36px;
    margin: auto;
    margin-top: 20px;
  }
  @media(max-width: 425px){
    width: 100%;
  }
  & p{
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.mainText};
  }
`