import { FC } from "react";
import { RegisterForm } from "shared/components/RegisterForm";
import { EmailRegisterWrap } from "./style";

interface IEmailRegisterProps {
  onRegister: (email: string) => void;
}

export const EmailRegister: FC<IEmailRegisterProps> = ({onRegister}) => {

  return (
    <EmailRegisterWrap>
      <h4>Register email for your wallet</h4>
      <p>Make sure you get the latest news regarding your NFTs.</p>
      <RegisterForm onClick={onRegister} />
    </EmailRegisterWrap>
  )
}
