import { DefaultButton } from "./styles";

interface IButton {
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export const Button: React.FC<IButton> = ({ onClick = ()=>{}, disabled=false, className="", children }) => {
  return (
    <DefaultButton
      disabled={disabled}
      onClick={() => onClick()}
      className={`button ${className}`}
    >
      {children}
    </DefaultButton>
  );
};
