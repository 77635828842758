import { OwnedByWrap, LoadingNftData, BidInfo, CurrentBidAmount } from "./style";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ButtonAnchor } from "shared/components/ButtonAnchor";
import { correctNetwork, infuraId, nftAddress, openseaLink } from "core/common";
import moment from "moment";
import { Spinner } from "core/components/Spinner";
import { theme } from "core/styles/theme";
import { providers } from "ethers";

interface IOwnedByProp {
  id: number;
}

export const OwnedBy: React.FC<IOwnedByProp> = ({ id = 0 }) => {
  const infuraProvider = useMemo(() => new providers.InfuraProvider(correctNetwork, infuraId), []);

  const [nftOpenseaData, setNftOpenseaData] = useState<any>(null);
  const [ownerENSAddress, setOwnerENSAddress] = useState("");
  const [loading, setLoading] = useState(false);

  const nftOpenseaAPI = `${openseaLink}${nftAddress}/${id}`;

  const getNftData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetch(nftOpenseaAPI);
      const nftOpenseaData = await response.json();
      setNftOpenseaData(nftOpenseaData);
      const ownerENSAddress = await infuraProvider.lookupAddress(nftOpenseaData.owner.address);
      ownerENSAddress && setOwnerENSAddress(ownerENSAddress);
    }
    catch (err) {
      console.log(err);
      setLoading(false);
    }

    setLoading(false);
  }, [nftOpenseaAPI, infuraProvider]);

  useEffect(() => {
    (async function () {
      getNftData();
    })();
  }, [getNftData])


  return (
    <OwnedByWrap>
      {loading ?
        <LoadingNftData >
          <Spinner color={theme.palette.mainText} style={{ width: "80px", height: "80px" }} />
        </LoadingNftData>
        : <>
          <h3>Current owner</h3>
          <BidInfo>
            <p className={'bidInfo'}>
              <a href={`https://opensea.io/${nftOpenseaData?.owner?.address}`}>
                {ownerENSAddress || nftOpenseaData?.owner?.address}</a>
            </p>
            <p className={'bidLabel'}>Sold for:</p>
            <CurrentBidAmount>
              <span />
              <p className={'bidInfo'}>{nftOpenseaData?.last_sale?.total_price / 1000000000000000000} ETH</p>
            </CurrentBidAmount>
            <p className={'bidLabel'}>Sell date:</p>
            <p className={'bidInfo'}>{moment(new Date(nftOpenseaData?.last_sale?.event_timestamp)).format('MMMM D YYYY, h:mm A')}</p>
            {/* <p className={'bidLabel'}>Imbued words:</p>
            <p className={'bidInfo'}></p> */}
          </BidInfo>
          <ButtonAnchor
            className="secondary"
            href={`https://opensea.io/assets/${nftAddress}/${id}`}
            rel={'nofollow noreferrer noopener'}
          >
            Make offer on OpenSea
          </ButtonAnchor>
        </>}
    </OwnedByWrap>
  )
}