import {
  GridInfo,
  GridInfoBtns,
  GridClickText,
  GridInfoText,
  GridSectionWrap,
} from "./style";

import { ButtonAnchor } from "shared/components/ButtonAnchor";
import { ButtonLink } from "shared/components/ButtonLink";

import { GridWrapper } from "shared/components/Artwork/GridWrapper";

import NFTExample from 'core/assets/img/nft-friendship.jpg'


interface IGridSectionProps {
  from: number;
  to: number;
  image: string;
}

export const GridSection: React.FC<IGridSectionProps> = ({ from, to, image }) => (
  <GridSectionWrap>
    <GridInfo>
      <GridInfoText>
        <h2>The 99 Photo NFTs</h2>
        <p>Imbued with Friendship is connected to 99 unique photographic NFTs. These NFTs are designed with a pay-it-forward mechanic. <br/>
        By owning an NFT, you get invited to enter a Friendship Ritual, you meditate on one of your friendships.<br/>
        You describe your friendship in a few words, have those words be written onto the blockchain, and then gift NFT to that friend, who gets to repeat the process. Each friendship then molds the light patterns of the physical artwork.</p>
        <p>
          The Imbued with Friendship NFTs will be minted as exclusive gifts to participants of <a href="http://metaverse.miami">Metaverse Miami</a> on Nov 28 - 30.
        </p>
        <ButtonLink to={'/digital-weave'}>Read about our NFTs</ButtonLink>
        <br/>
</GridInfoText>
      <GridInfoBtns>
      <img src={NFTExample} alt={'Imbued with Friendship NFT #7'}/>
      Imbued with Friendship #7
{/*        <ButtonAnchor href={'https://opensea.io/collection/imbued-art?search[stringTraits][0][name]=Emotion&search[stringTraits][0][values][0]=Friendship'}>
          View all on OpenSea
        </ButtonAnchor>*/}
      </GridInfoBtns>
    </GridInfo>
{/*
    <GridClickText>
      <p className={'mobileInfo'}>Flip your phone sideways</p>
      <div className={'desktopInfo'}>
        <p>Click on a part of the weave<br />to see current owner<br />and imbued words</p>
        <span />
      </div>
    </GridClickText>
    <GridWrapper from={from} to={to} image={image} />*/}
  </GridSectionWrap>
)