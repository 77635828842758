import { paddingStyle1 } from "core/styles/mixins";
import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: ${({ theme }) => theme.contentSpacing};
  ${paddingStyle1}
  border-radius: 0 ${({ theme }) => theme.borderRadius.block};
  background: ${({ theme }) => theme.palette.white};
  z-index: 2;
`