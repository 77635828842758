import { SocialNav } from './styles';
import facebook from "../../../../../core/assets/img/icons/facebook.svg";
import instagram from "../../../../../core/assets/img/icons/instagram.svg";
import twitter from "../../../../../core/assets/img/icons/twitter.svg";

export const FooterSocialButtons = () => {
    return (
        <SocialNav>
            <nav>
                <a href='https://www.facebook.com/tadaaart' target="_blank" rel={'noopener noreferrer'}><img src={facebook} alt="facebook" /></a>
                <a href='https://www.instagram.com/malinbobecktadaa/' target="_blank" rel={'noopener noreferrer'}><img src={instagram} alt="instagram" /></a>
                <a href='https://twitter.com/malintadaa' target="_blank" rel={'noopener noreferrer'}><img src={twitter} alt="twitter" /></a>
            </nav>
        </SocialNav>
    )

}