import { TheArtworkInfo, TheArtworkWrap } from "./style";
import { ImbuedSlider } from "shared/components/Artwork/ImbuedSlider";

import { ARTWORK_LIFE } from 'routing/appRoute/paths';

import life1 from "core/assets/img/life7.jpg";
import life2 from "core/assets/img/life3.jpg";
import life3 from "core/assets/img/life4.jpg";
import life4 from "core/assets/img/life5.jpg";
import life5 from "core/assets/img/life2.jpg";
const lifeSlider = [
  {
    img: life1,
    link: ARTWORK_LIFE,
  },
  {
    img: life2,
    link: ARTWORK_LIFE,
  },
  {
    img: life3,
    link: ARTWORK_LIFE,
  },
  {
    img: life4,
    link: ARTWORK_LIFE,
  },
  {
    img: life5,
    link: ARTWORK_LIFE,
  }
];

export const TheArtwork = () => (
  <TheArtworkWrap>
    <TheArtworkInfo>
      <h2 className="white">The Artwork</h2>
      <p>
      Imbued with Life connects birth and death through eternal evolution. It is inspired by the cycles of life, dividing cells, and the birth of a child.
      </p><p>
      The 99 photographic NFTs invite their owners to a Life meditation, where they get to dedicate their life, through words on the blockchain. The physical art piece uses these words of life to generate the patterns flowing through the fabric.
    </p><p>
    <b>Medium:</b> Custom woven optical fiber fabric. Woven by the artist at Ekelund Weavers in Horred, Sweden. Made with a cotton warp, and optical fiber, cotton, lurex and shrinking yarn in the weft. 300 addressable LEDs, controlled by a ESP32 microcontroller. Wooden frame.
    </p><p>
      <b>Dimensions:</b> 138 x 90 cm (4.5 x 3 ft)
      </p>
            <p>
      <b>Exhibiting:</b><br/>
      <a href="https://artwithme.org/">Art with Me</a> (Nov 26 - 27)<br/>
      <a href="https://goo.gl/maps/Du5G9JuFbVGWzvrYA">ARES Design</a> (Nov 18 - Dec 12)
      </p>

    </TheArtworkInfo>
    <ImbuedSlider imgArr={lifeSlider} cls={'artworkSlider'} />
  </TheArtworkWrap>
)