import {
  FAQItem,
  FAQItemInputs,
  FAQItems,
  FAQItemsFooter,
  FAQItemTitle,
  FAQSection,
  FAQWrapper,
} from "./styles";
import { FAQInput } from "./components/FAQInput";

import { theme } from "core/styles/theme";
import { HeaderWrapper } from "shared/components/HeaderWrapper";

export const FAQ = () => {
  return (
    <FAQSection className="content-wrapper">
      <HeaderWrapper>
        <h1>FAQ</h1>
      </HeaderWrapper>
      <FAQWrapper>
        <FAQItems>
          <FAQItem>
            <FAQItemTitle>
              <h2>NFTs</h2>
            </FAQItemTitle>
            <FAQItemInputs>
            <FAQInput margin="0 0 12px 0" label="How do I buy an NFT?">
                <ul>
                  <li style={{ marginBottom: "1rem" }}>
                    1. Set up a Crypto wallet.{" "}
                    <p>
                      To own an NFT, you need an Ethereum wallet.{" "}
                      <a
                        href="https://metamask.io/"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{ color: theme.palette.greenishCyan }}
                      >
                        MetaMask
                      </a>{" "}
                      is a popular alternative for both desktop and mobile, but
                      there are other options. It is important to follow
                      security recommendations for your wallet since these can’t
                      be recovered like traditional passwords. If you lose your
                      password, you will lose your wallet.
                    </p>
                  </li>
                  <li style={{ marginBottom: "1rem" }}>
                    2. Buy Ethereum.{" "}
                    <p>
                      The payment is made in the crypto currency Ethereum. Some
                      wallets, like{" "}
                      <a
                        href="https://metamask.io/"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{ color: theme.palette.greenishCyan }}
                      >
                        MetaMask
                      </a>{" "}
                      , allow you to buy Ethereum from inside the wallet app.
                      For other wallets, you need to buy Ethereum from an
                      exchange and move to your wallet. If you are based in
                      Sweden, we recommend{" "}
                      <a
                        href="https://bt.cx"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{ color: theme.palette.greenishCyan }}
                      >
                        bt.cx
                      </a>{" "}
                      , which takes payments over Swish. For other countries,{" "}
                      <a
                        href="https://coinbase.com"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{ color: theme.palette.greenishCyan }}
                      >
                        Coinbase
                      </a>{" "}
                      is an option. You then have to make sure the Ethereum is{" "}
                      <a
                        href="https://help.coinbase.com/en/coinbase/trading-and-funding/sending-or-receiving-cryptocurrency/how-do-i-send-digital-currency-to-another-wallet.html"
                        target={"_blank"}
                        rel="noreferrer"
                        style={{ color: theme.palette.greenishCyan }}
                      >
                        transferred to your wallet.
                      </a>{" "}
                    </p>
                    <p>
                      There will be a “minting fee” when your NFT is created.
                      This is calculated based on current demands and may vary
                      between 0.01-0.03 Ethereum, so be sure to have some margin
                      in your wallet when you try to buy your NFT. The NFT
                      Weaves will cost 0.1 ETH each, about $400 (end of
                      November).
                    </p>
                  </li>
                  <li>
                    3. Buy NFT.{" "}
                    <p>
                      Go to imbuedart.com, connect your wallet and click “Mint
                      NFT”. You will then get to verify the transaction in your
                      wallet app, and you should be done!
                    </p>
                  </li>
                </ul>
              </FAQInput>
              <FAQInput
                margin="0 0 12px 0"
                label="What is the Ethereum contract adress?"
                text="The Imbued NFT Contract adress is: 0x000001e1b2b5f9825f4d50bd4906aff2f298af4e. The adress for the minting contract is: 0xfc4b4f297dcf4dcede3a5011aa90de6396dbd3e5."
              />
              <FAQInput
                margin="0 0 12px 0"
                label="How many NFT Weaves will be released?"
                text="There will be 99 NFTs for each physical artwork, along with an &quot;owner NFT&quot;, which serves as the title for the physical artwork. That's a total of 700 NFTs, throughout the project."
              />
              <FAQInput
                margin="0 0 12px 0"
                label="Will all NFT Weaves be sold to the public?"
                text="Each set of NFTs will have different mechanisms for distribution. Some will be public, some will be privately minted in connection with physical exhibtions. Also, for each set of NFTs, a few will be reserved for the buyer of the physical piece, and a few for the artist's private collection."
              />

              
            </FAQItemInputs>
          </FAQItem>
          <FAQItem>
            <FAQItemTitle>
              <h2>Physical art</h2>
            </FAQItemTitle>
            <FAQItemInputs>
              <FAQInput
                margin="0 0 12px 0"
                label="What if the electronics break?"
                text="The artist offers a 1-year warranty on the artwork. We are then able to provide introduction to a digital conservator. We will stock and provide spare LED lights and microcontrollers. We use standard components, and hardware specifications will be supplied if the artwork is bought, meaning that even decades from now a skilled conservator will be able to fix any problems."
              />
              <FAQInput
                margin="0 0 12px 0"
                label="How does shipping work?"
                text="The buyer pays for shipping and shipping insurance. We can help set it all up."
              />
              <FAQInput
                margin="0 0 12px 0"
                label="How can I ensure a safe transaction if I sell my artwork?"
                text="Selling a hybrid NFT artwork is more like selling a traditional artwork, rather than an NFT. That means that unless you know the buyer, you most likely want to work with a dealer who can provide escrow services and a secure transfer of the physical art piece. Please contact us if you're interesetd in placing a bid on a physical art piece already sold."
              />
            </FAQItemInputs>
          </FAQItem>
        </FAQItems>
        {/* <FAQItems>
                    <FAQItem>
                        <FAQItemTitle>
                            <h4>Fourth category</h4>
                        </FAQItemTitle>
                        <FAQItemInputs>
                            <FAQInput margin='0 0 12px 0' label='What is NFT?'/>
                            <FAQInput margin='0 0 12px 0' label='Where can I buy?'/>
                            <FAQInput margin='0 0 12px 0' label='How can i join the team?'/>
                        </FAQItemInputs>
                    </FAQItem>
                    <FAQItem>
                        <FAQItemTitle>
                            <h4 id="the-hash">Fourth category</h4>
                        </FAQItemTitle>
                        <FAQItemInputs>
                            <FAQInput margin='0 0 12px 0' label='What is NFT?'/>
                            <FAQInput margin='0 0 12px 0' label='Where can I buy?'/>
                            <FAQInput margin='0 0 12px 0' label='How can i join the team?'/>
                        </FAQItemInputs>
                    </FAQItem>
                </FAQItems> */}
      </FAQWrapper>
      <FAQItemsFooter></FAQItemsFooter>
    </FAQSection>
  );
};
