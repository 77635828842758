import { combineReducers } from "redux";
import { nftDataReducer } from "./nftDataReducer";

export const rootReducer = combineReducers({
  nftData: nftDataReducer
})

export type TRootReducer = {
  nftData: {
    artData: [],
    weaveData: []
  }
}
