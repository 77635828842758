import { paddingStyle1 } from "core/styles/mixins";
import styled from "styled-components";

export const TttVideo = styled.div`
  margin-bottom: -1px;
  overflow: hidden;
  @media(min-width: 992px) {
    grid-column: 1/3;
    grid-row: 1;
  }
  & video {
    object-fit: cover;
    display: flex;
    width: 100%;
    height: 400px;
    border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 0;
    @media (min-width: 768px) {
      height: auto;
    }
  }
`

export const TttIntro = styled.div`
  background: ${({ theme }) => theme.palette.white};
  width: 100%;
  height: 100%;
  ${paddingStyle1}
  grid-column: 1;
  grid-row: 2;
  @media (min-width: 992px) {
    grid-column: 2;
    grid-row: 2;
  }
  padding-bottom:0px;
  & p{
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: 20px;
    line-height: 30px;
    
    color: ${({ theme }) => theme.palette.paragraph};
    @media(min-width: 992px) {
      font-weight: ${({ theme }) => theme.fontWeight.normal};
      font-size: 22px;
    }
    &:nth-child(1) {
      padding-bottom: 9px;
    }
    @media(min-width: 1600px) {
      &:nth-child(3) {
        max-width: 600px;
      } 
    }
    & p:last-of-type {
      width: 85%;
      @media(max-width:425px){
        width: 100%;
      }
    }
  }
`
export const TttDesc = styled.div`
  ${paddingStyle1}
  background: ${({ theme }) => theme.palette.white};
  border-radius: 0 0 0 ${({ theme }) => theme.borderRadius.block};
  height: 100%;
  @media (min-width: 992px) {
    
    grid-column: 1;
    grid-row: 2;
    
  }
  @media(min-width: 1800px) {
    
  }
  & p{
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.paragraph};
    justify-self: center;
    align-self: center;
  }
`

export const TttWrap = styled.div`
  position: relative;
  z-index: 4;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto;
  }
`
