import { ButtonAnchor } from "shared/components/ButtonAnchor";
import {
  ActorCard,
  ArtistInfo,
  ArtistInfoText,
  ArtistInfoTextMob,
  ArtistSec,
  InfoAbout,
} from "./styles";
import instagram from "core/assets/img/icons/instagram.svg";


export const ArtistSection = () => {
  return (
    <ArtistSec>
      <ActorCard>
        <InfoAbout>
          <ArtistInfo>
            <div>
              <h3 className="white">Malin&nbsp;Bobeck&nbsp;Tadaa</h3>
              <p className="white">Artist</p>
            <div className="button-box">
                <ButtonAnchor 
                  className="medium" 
                  href="https://www.tadaa.se/"
                >
                  Learn more
                </ButtonAnchor>
                <ButtonAnchor 
                  className="medium" 
                  href="https://www.instagram.com/malintadaa/"
                >
                  <img src={instagram} alt="instagram" />&nbsp;&nbsp;Follow
                </ButtonAnchor>
            </div>
                        </div>

            </ArtistInfo>
          <ArtistInfoText>
            <p>
              Malin Bobeck Tadaa is an awarded artist based in Stockholm,
              Sweden. She produces large-scale textile installations using
              lights, sensors, augmented reality and microcomputers to create
              interactive, immersive worlds.
            </p>
          </ArtistInfoText>
        </InfoAbout>
      </ActorCard>
      <ArtistInfoTextMob>
        <p>
          Malin Bobeck Tadaa is an awarded artist based in Stockholm, Sweden.
          She produces large-scale textile installations, using lights, sensors,
          augmented reality and microcomputers to create interactive, immersive
          worlds.
        </p>
      </ArtistInfoTextMob>
    </ArtistSec>
  );
};
