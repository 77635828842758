import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const StyledNavLink = styled(NavLink)`
  position: relative;
  &:not(.acvite) {
    color: ${props => props.theme.palette.mainText}
  }
  &.active:after {
    left: 0;
    bottom: -4px;
    position: absolute;
    content: '';
    width: 100%;
    height: 3px;
    background: ${props => props.theme.palette.secondary};
  }
`