import "reactjs-popup/dist/index.css";
import { StyledPopUp } from "./style";

import { FC } from "react";
import { RegisterForm } from "shared/components/RegisterForm";
import { Button } from "shared/components/Button";

interface IPopUpProps {
  email: string;
  button?: string;
  onEdit: (email: string) => void;
}

export const EmailEditPopUp: FC<IPopUpProps> = ({ email, onEdit }) => {
  return (
    <StyledPopUp
      trigger={() => <Button className="open-button">Edit email</Button>}
      modal
    >
      {(close: any) => (
        <>
          <button className={"close"} onClick={close}>
            ×
          </button>
          <>
            <h4>Edit your email</h4>
            <RegisterForm email={email} button="Edit" onClick={(email) => { onEdit(email); close(); }} />
          </>
        </>
      )}
    </StyledPopUp>
  );
};
