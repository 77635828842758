import {
  WavesInterImg,
  WavesInterSection,
  WavesInterText,
  WavesInterWrap,
} from "./styles";

export const NFTBitmap = () => {
  return (
    <WavesInterSection className="content-wrapper">
      <WavesInterWrap>
        <WavesInterText>
        <h3>The birth of the bitmap</h3>
          <p>
              The jacquard loom is the origin of the modern computer, the warp
              threads moving up and down, as ones and zeroes. It
              was for these machines that the first punch cards were developed,
              the first storage of binary code. Even to this day, jacquard looms
              use black and white bitmap images to control the up and down
              movement of each{" "}
              <a href="https://en.wikipedia.org/wiki/Warp_and_weft">
                warp thread for every weft thread
              </a>{" "}
              added to the weave.
            </p>
            <p>&nbsp;</p>
             <p>
              Each of the Imbued artworks uses custom-woven jacquard fabric. The bitmaps used to create these can be seen here, in the background elements of the site, as well as in the art.
            </p>

        </WavesInterText>
        <WavesInterImg>
          <p />
        </WavesInterImg>
      </WavesInterWrap>
    </WavesInterSection>
  );
};
