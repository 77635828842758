import { backgroundPrimary, backgroundSecondary, defaultButtonStyle, disableButtonStyle, smallButtonStyle } from "core/styles/mixins";
import styled from "styled-components";

export const DefaultButton = styled.button`
  ${defaultButtonStyle}
  ${backgroundSecondary}

  &[disabled] {
    ${disableButtonStyle}
  }

  &.small {
    ${smallButtonStyle}
  }

  &.primary {
    ${backgroundPrimary}
  }
`;
