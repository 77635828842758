import styled from "styled-components";

export const ToggleBurgerWrapper = styled.div`
  width: 79px;
  height: 79px;
  background: ${({ theme }) => theme.palette.gray900} 0% 0% no-repeat padding-box;
  border-radius: 100%;
  backdrop-filter: blur(1px);
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  z-index: 5;
  span {
    display: block;
    position: relative;
    background: ${({theme}) => theme.palette.secondary} 0% 0% no-repeat padding-box;
    border-radius: ${({ theme }) => theme.borderRadius.small};
    width: 48px;
    height: 10px;
    &:after, &:before {
      content: '';
      background: ${({theme}) => theme.palette.secondary} 0% 0% no-repeat padding-box;
      border-radius: ${({ theme }) => theme.borderRadius.small};
      width: 48px;
      height: 10px;
      position: absolute;
    }
    &:after {
      bottom: 13px;
    }
    &:before {
      bottom: -13px;
    }
  }
`