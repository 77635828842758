import { TheArtworkInfo, TheArtworkWrap } from "./style";
import { ImbuedSlider } from "shared/components/Artwork/ImbuedSlider";

import { ARTWORK_LOVE } from 'routing/appRoute/paths';

import love1 from "core/assets/img/slider1.jpg";
import love2 from "core/assets/img/love1.jpg";
import love3 from "core/assets/img/slider4.jpg";
import love4 from "core/assets/img/slider2.jpg";
import love5 from "core/assets/img/love2.jpg";

const loveSlider = [
  {
    img: love1,
    link: ARTWORK_LOVE,
  },
  {
    img: love2,
    link: ARTWORK_LOVE,
  },
  {
    img: love3,
    link: ARTWORK_LOVE,
  },
  {
    img: love4,
    link: ARTWORK_LOVE,
  },
  {
    img: love5,
    link: ARTWORK_LOVE,
  }
];


export const TheArtwork = () => (
  <TheArtworkWrap>
    <TheArtworkInfo>
      <h2 className="white">The Artwork</h2>
      <p>
      Imbued with Love is the first piece in the Imbued collection. It honors passionate love, infatuation, the kind of love you project onto others. The pattern is based on a knitting made by Malin's mother, for her granddaughter.
      </p><p>
      The 99 photographic NFTs invite their owners to a Love meditation, where they get to dedicate their love, through words on the blockchain. The physical art piece uses these words of love to generate the patterns flowing through the fabric.
    </p><p>
    <b>Medium:</b> Custom woven optical fiber fabric. Woven by the artist at Ekelund Weavers in Horred, Sweden. Made with a cotton warp and optical fiber, cotton and shrinking yarn in the weft. 150 addressable LEDs, controlled by a ESP32 microcontroller. Wooden frame.
    </p><p>
      <b>Dimensions:</b> 110 x 90 cm (3.5 x 3 ft)
      </p>
    </TheArtworkInfo>
    <ImbuedSlider imgArr={loveSlider} cls={'artworkSlider'} />
  </TheArtworkWrap>
)