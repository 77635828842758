import { paddingStyle1, marginBottomStyle2, marginBottomStyle4 } from "core/styles/mixins";
import styled from "styled-components";
import swipe_divider_with_texture from "../../../../core/assets/img/swipe_divider_with_texture.png";


export const ArtwStoryText = styled.div`
    display: flex;
    flex-direction: column;
    ${marginBottomStyle4}
    @media(max-width: 1600px){
        ${marginBottomStyle2}
    }
    @media(max-width: 1440px){
        ${marginBottomStyle2}
    }
    & p{
        font-weight: ${({ theme }) => theme.fontWeight.semiBold};
        font-size: 17px;
        color: ${({ theme }) => theme.palette.paragraph};
        @media(max-width: 1600px){
            font-size: 14px;
        }
        @media(max-width: 1440px){
            font-size: 14px;
        }
        @media(max-width: 425px){
            font-size: 14px;
        } 
    }
`
export const HpSliderSection = styled.div`
    position: relative;
`
export const HpSliderBg = styled.div`
    background-image: url(${swipe_divider_with_texture});
    position: absolute;
    left: 0;
    right: 0;
    top: 19%;
    bottom: 4%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 2;
    background-position: center;
    @media(max-width:1024px){
        display: none;
    }
`
export const HpSliderMask = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    top: 19%;
    bottom: 4%;
    z-index: 2;
    background-color: ${({ theme }) => theme.palette.gray100};
    @media(max-width:1024px){
        display: none;
    }
`

export const HpSliderWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: ${({ theme }) => theme.palette.white};
    width: 100%;
    position: relative;
    z-index:2;
    @media(min-width: 426px){
      border-radius: ${({ theme }) => theme.borderRadius.block} 0 0 0;
    }
    @media(min-width: 992px) {
      margin: 0 auto;
      flex-direction: row;
      border-radius: 0 ${({ theme }) => theme.borderRadius.block};
    }
`
export const HpSliderTextWrap = styled.div`
    padding: 4%;    
    width:100%;
    @media(max-width: 992px){
        ${paddingStyle1}
    }
    & div:nth-last-child(1) {
        margin-bottom: 0;
    }
`
export const HpSliderImgWrap = styled.div`
    order: -1;
    width: 100%;
    height: 514px;
    @media(min-width: 992px) {
      height: auto;
      order: 0;
    }
`
export const HpSliderImg = styled.p<{ backgroundImg?: string }>`
    background-image: url(${props => props.backgroundImg});
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: ${({ theme }) => theme.borderRadius.block} 0 0 0;
    @media(min-width: 992px) {
      background-size: cover;
      border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 0;
    }
`
