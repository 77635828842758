import React from 'react';
import {PUBLIC_ROUTES} from "./routing/appRoute/routes";
import {RenderRoutes} from "./routing/appRoute/index";

function App() {
    return (
        <>
            <RenderRoutes routes={PUBLIC_ROUTES}/>
        </>
    );
}

export default App;
