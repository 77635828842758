import styled from "styled-components";
import Popup from "reactjs-popup";

export const StyledPopUp = styled(Popup)`
   &-content {
    width: 500px;
    border-radius: 0 ${({ theme }) => theme.borderRadius.block};
    background: ${({ theme }) => theme.palette.white};
    padding: 30px;
    position: relative;

    & .close {
      cursor: pointer;
      position: absolute;
      display: block;
      padding: 2px 5px;
      right: 0;
      top: 0;
      font-size: 24px;
      line-height: 20px;
      color: ${({ theme }) => theme.palette.white};
      background: ${({ theme }) => theme.palette.gray700};
      border-radius: 18px;
      border: 1px solid ${({ theme }) => theme.palette.gray400};
    }
  }
`;