import {
  ImbuedInfoSection,
  ImbuedVideoWrap,
  ImbuedInfoSlogan,
} from "./style";
import videoNft from "core/assets/longing-nft-720p.mp4";

export const ImbuedInfo = () => (
  <ImbuedInfoSection>

    <ImbuedVideoWrap>
      <p>Imbued with Longing</p>
      <video src={videoNft} autoPlay muted playsInline loop />
    </ImbuedVideoWrap>
    <ImbuedInfoSlogan>
      <p>
”Your longing is a gift, given to you by evolution. <br/>
It's what makes us human.<br/>
What makes us want to do better,<br/>be better, give better. <br/>
It is our ability to dream<br/>of something that does not yet exist.<br/>
And then make it happen.”</p>
    </ImbuedInfoSlogan>
  </ImbuedInfoSection>
)