import React, { useState } from "react";
import botb from "../../../../core/assets/img/nft-love.jpg";
import {
  HpSliderBg,
  HpSliderImg,
  HpSliderImgWrap,
  HpSliderSection,
  HpSliderText,
  HpSliderTextWrap,
  HpSliderWrap,
} from "./styles";
export const NFTAbout = () => {
  interface StateInterface {
    showImage: string;
    startSlide: number;
  }
  const [state] = useState<StateInterface>({
    showImage: botb,
    startSlide: 0,
  });

  return (
    <HpSliderSection>
      <HpSliderBg />
      <HpSliderWrap className="content-wrapper">
        <HpSliderTextWrap>
          <HpSliderText>
            <h3>A unique photograph</h3>
            <p>
              Each NFT represents one unique photograph of one of the Imbued artworks. As a NFT owner, you can get this photo printed, framed and signed to hang on your wall. You also get access to a high resolution image file to display on your screen or photo frame.
            </p>
          </HpSliderText>
          <HpSliderText>
            <h3>Imbue your NFT</h3>
            <p>
              As the owner of an NFT, gain access to a ritual, a guided meditation by the artist. Through this ritual, you are invited to Imbue the art with your own words. These words are written on to the Ethereum blockchain in plain text, and can never be changed or removed. The physical art uses these words to generate the color and light patterns that flow through the fabric.
            </p>
          </HpSliderText>
          <HpSliderText>
            <h3>It's just the beginning</h3>
            <p>
              An NFT forms a permanent relationship between the artist and her community. It's an opportunity for patrons and fans to invest in art they love and be able to get something back. In June 2022, we gifted high-quality, signed and framed photo prints to all our NFT owners. As the project goes on, we will provide additional perks and opportunities.
            </p>
          </HpSliderText>
          <HpSliderText>
          <p>&nbsp;</p>
          <hr />
            <p>
              To Imbue you NFT, download the image or claim a print, please log in and click on "My Wallet" in the top menu.
            </p>
          </HpSliderText>
        </HpSliderTextWrap>
        <HpSliderImgWrap>
          <HpSliderImg backgroundImg={state.showImage} />
        </HpSliderImgWrap>
      </HpSliderWrap>
    </HpSliderSection>
  );
};
