import { useEffect } from "react";
import { NFTWhatIs } from "./components/NFTWhatIs";
import { NFTBitmap } from "./components/NFTBitmap";
import { NFTHeader } from "./components/NFTHeader";
import { NFTAbout } from "./components/NFTAbout";
import { NFTHowToBuy } from "./components/NFTHowToBuy";
import { DigitalWeaveWrapper } from "./styles";
// import { NFTMint } from "./components/NFTMint";

export const DigitalWeave = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <DigitalWeaveWrapper>
      <NFTHeader />
      {/*<NFTMint />*/}
      <NFTAbout />
      <NFTBitmap />
      <NFTHowToBuy />
      <NFTWhatIs />
    </DigitalWeaveWrapper>
  );
};
