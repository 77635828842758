import { useRouteMatch } from "react-router-dom";
import { DownloadedNftWeavesSection, NftWeavesImg, NftWeavesInfo, NftWeavesInfoBtns } from "./style";
import { ButtonLink } from "shared/components/ButtonLink";
import { NFT } from "routing/appRoute/paths";
import { capitalizeFirstLetter, apiName, getArtworkNameById } from "core/common";

import { Button } from "shared/components/Button";

import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

import { Amplify, API } from 'aws-amplify';
import awsconfig from 'aws-exports';
import { useEffect, useState } from "react";

Amplify.configure(awsconfig);

export const DownloadedNftWeaves = ({ item }) => {
  const [claimed, setClaimed] = useState<boolean>(false);
  const [claimedFetched, setClaimedFetched] = useState<boolean>(false);
  const [shipped, setShipped] = useState<boolean>(false);
  const [shippedFetched, setShippedFetched] = useState<boolean>(false);

  let match = useRouteMatch();
  const { library } = useWeb3React<Web3Provider>();
  const malinbobeckLink = `https://www.tadaa.se/checkout/?add-to-cart=`;

  const cartStartIds = [17085/*Love*/, 17288/*Awe*/];

  const getMalinBobeckOrderId = () => {
    const malinBobeckCartId = cartStartIds[Math.floor(item.id/100)];
    const malinBobeckItemId = item.id % 100;
    return `${malinBobeckCartId + malinBobeckItemId}`;
  }

  const getMalinBobeckLink = () => {
    return `${malinbobeckLink}${getMalinBobeckOrderId()}`;
  }

  const onClaimHandler = async () => {
    const signer = library?.getSigner();
    const salt = "I want to claim my print for Weave #";
    const message = salt + item.id;
    const signature = await signer?.signMessage(message);

    API.put(apiName, '/nfts', {headers: {}, body: {
      pk: item.id,
      sk: "claim",
      salt: salt,
      signature: signature,
    }}).then((res) => {
      window.location.replace(getMalinBobeckLink());
    }, (err) => {
      console.error(err);
    });
  }

  const onShipHandler = async () => {
    window.open(getMalinBobeckLink(), '_blank');
  }

  useEffect(() => {
    API.get(apiName, `/nfts/object/${item.id}/claim`, {headers: {}}).then((res) => {
      if (res.claim) {
        setClaimed(true);
      } else {
        setClaimed(false);
      }
      setClaimedFetched(true);
    });
    API.get(apiName, `/nfts/object/${item.id}/ship`, {headers: {}}).then((res) => {
      if (res.ship) {
        setShipped(true);
      } else {
        setShipped(false);
      }
      setShippedFetched(true);
    });
  }, [item.id]);

  return (
    <DownloadedNftWeavesSection>
      <NftWeavesInfo>
        <p className={'itemName'}>Imbued with {capitalizeFirstLetter(getArtworkNameById(item.id))} #{item.id%100}</p>
        <p className={'itemComment'}>{item.comment ? item.comment : 'Not Imbued'}</p>
        <NftWeavesInfoBtns>
          <ButtonLink to={`${NFT}/${item.id}`}>
            View
          </ButtonLink>

          {/* 4 states for claim button */
          claimedFetched && shippedFetched ?
            (shipped ? <Button disabled>Print claimed</Button>
              : claimed ? <Button className="primary" onClick={onShipHandler}>Ship Print</Button>
                        : <Button onClick={onClaimHandler}>Claim Print</Button>)
            : <Button disabled>Loading</Button>
          }

          <ButtonLink to={`${match.path}/${item.id}`}>
            Imbue
          </ButtonLink>
        </NftWeavesInfoBtns>
      </NftWeavesInfo>
      <NftWeavesImg>
        <img src={item.thumbnail} alt="Weave" />
      </NftWeavesImg>
    </DownloadedNftWeavesSection>
  )
}
