import styled from "styled-components";

export const FormWrap = styled.div`
  & form {
    display: flex;
    max-width: 600px;
    margin: 20px 0;
    & .email {
      background: ${({ theme }) => theme.palette.white};
      border: 1px solid ${({ theme }) => theme.palette.mainText};
      border-radius: ${({ theme }) => theme.borderRadius.default} 0 0 ${({ theme }) => theme.borderRadius.default};
      font-weight: ${({ theme }) => theme.fontWeight.semiBold};
      font-size: 15px;
      color: ${({ theme }) => theme.palette.paragraph};
      width: 64%;
      padding: 10px 0 10px 8%;
      @media (min-width: 992px) {
        width: 64%;
        font-size: 16px;
      }
    }

    & .subbutton {
      padding: 10px 20px;
      font-size: 15px;
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      border-radius: 0 ${({ theme }) => theme.borderRadius.default} ${({ theme }) => theme.borderRadius.default} 0;
      background: ${({ theme }) => theme.palette.secondary};
      color: ${({ theme }) => theme.palette.white};
      border: 1px solid ${({ theme }) => theme.palette.mainText};
      border-left: none;
      width: 35%;
      -webkit-appearance: none;
      cursor: pointer;
      @media (min-width: 992px) {
        width: 35%;
        padding: 8px 20px 9px;
        font-size: 17px;
      }
    }
  }

  .error {
    height: 24px;
    font-size: 14px;
    color: ${({ theme }) => theme.palette.vermilion};
  }
`;