
import React, { useState } from "react";
import placeholder_image_4 from "core/assets/img/awe4.jpg"
import { ArtwStoryText, HpSliderBg, HpSliderImg, HpSliderImgWrap, HpSliderSection, HpSliderTextWrap, HpSliderWrap } from "./styles";


export const ArtwStory = () => {
    interface StateInterface {
        showImage: string;
        startSlide: number;
    }
    const [state] = useState<StateInterface>({
        showImage: placeholder_image_4,
        startSlide: 0
    });
    return (
        <HpSliderSection>
            <HpSliderBg />
            <HpSliderWrap className="content-wrapper">
                <HpSliderTextWrap>
                    <ArtwStoryText>
                        <h3>The story</h3>
                        <p>The jacquard loom is the origin of the modern computer. The warp threads of the weave, moving up and down, as ones and zeroes. It was for these machines that the first punch cards were developed, the first storage of binary code. Digitalization and textile production are intricately connected through time, and this project aims to tell that story through art and technology.<br></br><br></br>Historically, a textile was something unique. It was mainly produced in the home, becoming family treasures lasting for generations, through mending, recycling, and reuse. Today, textiles are mass-produced. The “unique” and “personal” are sold by the yard. Textile has become a fungible commodity, where every piece of fabric has its individuality shaved away to make sure that each inch, each roll is replaceable by another. <br></br><br></br>Through digitalization, we have gotten used to the fact that everything can be copied, and nothing can be unique. It’s been impossible to separate one copy from another or claim ownership of a specific digital file. But through Non-Fungible Tokens, cryptography has allowed for the reverse journey, for the digital to become unique. <br></br><br></br>This project aims to use the technology and philosophical framework of the Blockchain to shed light on the history of textiles, in a time when fabric existed as something personal and filled with memories.</p>
                    </ArtwStoryText>
                </HpSliderTextWrap>
                <HpSliderImgWrap>
                    <HpSliderImg backgroundImg={state.showImage} />
                </HpSliderImgWrap>
            </HpSliderWrap>
        </HpSliderSection>
    )
}