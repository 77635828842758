import { HeaderWrapper } from "shared/components/HeaderWrapper";
import {
  WavesIntroDesc,
  WavesIntroWrap,
  WeavesDescWrap,
  WeavesImg,
} from "./styles";

export const NFTHeader = () => {
  return (
    <WavesIntroWrap className="content-wrapper">
      <HeaderWrapper>
        <h1>Imbued NFTs</h1>
      </HeaderWrapper>
      <WeavesDescWrap>
        <WavesIntroDesc>
          <p className={"slogan"}>
            “The Analytical Engine weaves algebraic patterns, just as the
            Jacquard loom weaves flowers and leaves.”
          </p>
          <p className={"name"}>Ada Lovelace, 1815-1852</p>
          <p className={"name"}>The first computer programmer</p>
        </WavesIntroDesc>
        <WeavesImg />
      </WeavesDescWrap>
    </WavesIntroWrap>

  );
};

