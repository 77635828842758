import { useEffect } from "react";
import { Wrapper } from "./style";
import { BottomSlider } from "./style";
import { ImbuedInfo } from "./components/ImbuedInfo";
import { TheArtwork } from "./components/TheArtwork";
import { GridSection } from "./components/GridSection";
import { GridSlogan } from "./components/GridSlogan";

import { HeaderWrapper } from "shared/components/HeaderWrapper";
import { OwnedBy } from "./components/OwnedBy";
import { ImbuedSlider } from "shared/components/Artwork/ImbuedSlider";

import tempBg from 'core/assets/img/map-love.jpg';

export const ArtworkLove = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Wrapper className="content-wrapper">
      <ImbuedInfo />
      <TheArtwork />
      {/*<OwnedBy id={0} />*/}
      <GridSection from={0} to={99} image={tempBg} />
      <GridSlogan />
      <BottomSlider>
        <h3>Discover the Imbued series</h3>
        <ImbuedSlider cls={'BottomSlider'} />
      </BottomSlider>
    </Wrapper>
  )
}

