import { marginBottomStyle4 } from "core/styles/mixins";
import styled from "styled-components";
import faq1 from "../../core/assets/img/faq.jpg"

export const FAQWrapper = styled.div`
    position: relative;
    display: flex;
    background: ${({ theme }) => theme.palette.white};
    border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 0;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 3;
    @media(max-width:700px){
      width: 100%;
    }
`;
export const FAQSection = styled.div``;

export const FAQItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 50px;
  @media(max-width:700px){
    flex-wrap: wrap;
    margin-bottom: 0;
  }
`;
export const FAQItem = styled.div`
  width: 45%;
  @media(max-width:700px){
    width: 100%;
    ${marginBottomStyle4}
  }

`;

export const FAQItemTitle = styled.div`
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 42px;
    color: ${({ theme }) => theme.palette.mainText};
`;

export const FAQItemInputs = styled.div``;

export const FAQItemsFooter = styled.div`
  position: relative;
  height: 320px;
  border-radius: 0 0 0 ${({ theme }) => theme.borderRadius.block};
  background-image: url(${faq1});
  background-repeat: no-repeat;
  z-index: 3;
  @media(max-width:700px){
    width: 100%;
  }
`;
