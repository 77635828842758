import { MyArtworkDesc, MusicLink } from "pages/MyWeaveDetailed/style";
import { ImbueArtwork } from "../ImbueArtwork";

export const ImbueAwe = ({ id }) => (
  <>
    <ImbueArtwork
      id={id}
      headerText={`Imbued with Awe #${id}`}
      videoId="774501645"
    >
      <MyArtworkDesc>
        <p>
          As the caretaker of an NFT Photograph, you have the power to Imbue the artwork with your own sense of Awe, your own interpretation of that which is bigger than yourself.
          Each imbued NFT will generate a 3 minute "prayer", an animation of color and light, to be displayed on the physical artwork.<br />
        </p>
        <p>
          Make sure to find yourself in a space where you can be alone and at peace.<br />We suggest you use headphones.<br/>
          Turn on sound and start the meditation.
        </p>
        <MusicLink>
        <p>
            Music: <a href="https://open.spotify.com/track/4VpSvAqOUhn3D6bxw4nL40?si=5eef2ecfb08b41d2">Starlit, by Ola Claesson</a>.
            </p>
        </MusicLink>
      </MyArtworkDesc>
    </ImbueArtwork>
  </>
)