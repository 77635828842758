import {
  ImbuedInfoSection,
  ImbuedVideoWrap,
  ImbuedInfoSlogan,
} from "./style";
import videoNft from "core/assets/friendship-nft-720p.mp4";

export const ImbuedInfo = () => (
  <ImbuedInfoSection>

    <ImbuedVideoWrap>
      <p>Imbued with Friendship</p>
      <video src={videoNft} autoPlay muted playsInline loop />
    </ImbuedVideoWrap>
    <ImbuedInfoSlogan>
      <p>Night has arrived<br/>
      but you feel safe,<br/>
      hand in hand,<br/>
      together. </p>
    </ImbuedInfoSlogan>
  </ImbuedInfoSection>
)