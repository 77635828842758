import {GridSloganWrap} from "./style";

export const GridSlogan = () => (
    <GridSloganWrap>
      <p>
        It’s just there, the love.<br/>
        It’s obvious. Transparent. <br/>
        The history we share together is now a mountain of experience. <br/>
        Of small gestures that are just ours. Putting my lips on your cheek, stroking a lock of hair
        behind your ear. Our bodies know each other, they have created their own language. Imprinted in the frame that
        is me.<br/>
        I know how your chest is moving as I listen to you sleep. Breathing in synchrony with our son.<br/>
        I finish your sentences, I know it’s corny, but it’s true. In my head there is a small representation of
        you. <br/>
        I don’t know anymore who created what, where do I end and you begin?<br/>
        I am this way because you are beside me. <br/>
      </p>
    </GridSloganWrap>
  )