import styled from "styled-components";
import idvimg from "core/assets/img/badw.png";
import { paddingStyle1 } from "core/styles/mixins";

export const WavesInterImg = styled.div`
  width: 50%;
  z-index: 3;
  @media (max-width: 992px) {
    width: 100%;
    height: 514px;
  }
  & p {
    background-image: url(${idvimg});
    z-index: 3;
    width: 100%;
    height: 100%;
    background-size: cover;
    border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 0;
    image-rendering: pixelated;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
`;
export const WavesInterWrap = styled.div`
  display: flex;
  z-index: 3;
  flex-direction: row;
  position: relative;
  @media (max-width: 992px) {
    width: 100%;
    flex-wrap: wrap-reverse;
  }
`;

export const WavesInterText = styled.div`
  z-index: 3;
  width: 50%;
  padding: 4%;
  background-color: ${({ theme }) => theme.palette.white};
  background-size: cover;
  border-radius: 0 0 0 ${({ theme }) => theme.borderRadius.block};
  @media (max-width: 992px) {
    width: 100%;
    ${paddingStyle1}
  }
  & p {
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.paragraph};
    @media (max-width: 1600px) {
      font-size: 14px;
    }
    @media (max-width: 1440px) {
      font-size: 14px;
    }
    @media (max-width: 992px) {
      font-size: 17px;
    }
  }
  & a {
    text-align: center;
    color: ${({ theme }) => theme.palette.secondary};
    text-decoration: underline;
  }
  & div {
    text-align: center;
  }
`;
export const WavesInterSection = styled.div`
  z-index: 3;
  @media (max-width: 425px) {
    padding-top: 40px;
    padding-bottom: 0;
  }
`;
