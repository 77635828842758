import styled from "styled-components";

export const SocialNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0px;
  & nav{
    display: flex;
    flex-direction: row;
  }
  & nav a{
      color:${({theme}) => theme.palette.white};
      margin-right: 10px;
  }
`;