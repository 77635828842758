import { paddingStyle1, marginBottomStyle4 } from "core/styles/mixins";
import styled from "styled-components";
import swipe_divider_with_texture from "../../../../core/assets/img/swipe_divider_with_texture.png";

export const HpSliderText = styled.div`
  display: flex;
  flex-direction: column;
  ${marginBottomStyle4}

  & p {
    font-size: 17px;
    color: ${({ theme }) => theme.palette.paragraph};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    @media (max-width: 1600px) {
      font-size: 14px;
    }
    @media (max-width: 1440px) {
      font-size: 15px;
    }
    @media (max-width: 1024px) {
      font-size: 15px;
      line-height: 25px;
    }
    @media (max-width: 425px) {
      font-size: 15px;
      line-height: 25px;
    }
  }
`
export const HpSliderSection = styled.div`
  position: relative;
`
export const HpSliderBg = styled.div`
  background-image: url(${swipe_divider_with_texture});
  position: absolute;
  left: 0;
  right: 0;
  top: 19%;
  bottom: 10%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
  background-position: center;
  @media (max-width: 1024px) {
    display: none;
  }
`
export const HpSliderMask = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 19%;
  bottom: 10%;
  z-index: 2;
  background-color: ${({ theme }) => theme.palette.gray100};
  @media (max-width: 1024px) {
    display: none;
  }
`

export const HpSliderWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 0 ${({ theme }) => theme.borderRadius.block};
  background: ${({ theme }) => theme.palette.gray900};
  position: relative;
  z-index: 3;
  @media (max-width: 1024px) {
    width: 100%;
  }
  @media (max-width: 425px) {
    width: 100%;
    margin: 40px 0 0;
  }

  & .homeSlider {
    border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 0;
    width: 60%;
    height: auto;
    @media (max-width: 1024px) {
      display: none;
    }
  }

`
export const HpSliderTextWrap = styled.div`
  padding: 4%;
  width: 50%;

  & div:nth-last-child(1) {
    margin-bottom: 0;
  }
  @media (max-width: 1024px) {
    width: 100%;
    ${paddingStyle1}
    background: ${({ theme }) => theme.palette.gray900};
    border-radius: 0 ${({ theme }) => theme.borderRadius.block};
  }
  @media (max-width: 425px) {
    width: 100%;
    ${paddingStyle1}
  }
`
export const HpSliderImgWrap = styled.div`
  width: 50%;
  @media (max-width: 1024px) {
    width: 100%;
    display: block;
    height: 514px;
    ${marginBottomStyle4}
  }
  @media (max-width: 768px) {
    display: none;
  }
`
export const HpSliderImg = styled.p<{ backgroundImg?: string }>`
  background-image: url(${props => props.backgroundImg});
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  border-top-right-radius: ${({ theme }) => theme.borderRadius.block};
  @media (max-width: 1024px) {
    border-radius: 0 ${({ theme }) => theme.borderRadius.block};
  }
  @media (max-width: 425px) {
    border-radius: 0 ${({ theme }) => theme.borderRadius.block};
  }
`