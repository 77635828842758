import React, { useState } from "react";
import slider1 from "../../../../core/assets/img/longing3.jpg";
import {
  ArtwSliderImg,
  ArtwSliderImgWrap,
  ArtwSliderSection,
  ArtwSliderText,
  ArtwSliderTextWrap,
  ArtwSliderWrap,
  ArtwSliderTitle,
} from "./styles";
export const ArtwSlider = () => {
  interface StateInterface {
    showImage: string;
    startSlide: number;
  }
  const [state] = useState<StateInterface>({
    showImage: slider1,
    startSlide: 0,
  });
  return (
    <ArtwSliderSection className="content-wrapper">
      <ArtwSliderTitle>
        <h2>What are they?</h2>
      </ArtwSliderTitle>
      <ArtwSliderWrap>
        <ArtwSliderImgWrap>
          <ArtwSliderImg backgroundImg={state.showImage} />
        </ArtwSliderImgWrap>
        <ArtwSliderTextWrap>
          <ArtwSliderText>
            <h3>Optical fibre textile art</h3>
            <p>
              The artworks are made from custom woven optical fiber textiles,
              using techniques developed by Malin. The textiles are made in an
              industrial jacquard loom at Ekelunds Linneväveri in Horred,
              Sweden. They are then processed and assembled by hand, using
              3D-printed attachments to connect hundreds of programmable LEDs to
              the ends of the optical fibers.
            </p>
          </ArtwSliderText>
          <ArtwSliderText>
            <h3>Blockchain interactivity</h3>
            <p>
              Each work has a corresponding Non-Fungible Token, or NFT, that is
              stored on the Ethereum blockchain and that acts as proof of
              authenticity and ownership. Through interacting with this NFT, an
              owner of this artwork may <b>imbue the art</b> with their own
              words, which will be permanently stored on the blockchain. Another
              99 NFT weaves will be sold, representing slices of the digital
              weave file that created the fabric.<br></br>
              <br></br>The physical artwork has a microcontroller that controls
              the LEDs and lights up the fabric.{" "}
            </p>
          </ArtwSliderText>
          <ArtwSliderText>
            <h3>Imbued with meaning</h3>
            <p>
              This controller <b>connects via WiFi</b> and read data from the
              blockchain in real-time. This enables it to use the words of its
              current and past caretakers, along with its 99 weave holders, to
              generate the light pattern that is expressed through the fabric.
              The expression of each piece, both digital and physical, becomes
              unique to its owner, and constantly changing as{" "}
              <b>more people imbue their own meaning</b> into the work.
            </p>
          </ArtwSliderText>
        </ArtwSliderTextWrap>
      </ArtwSliderWrap>
    </ArtwSliderSection>
  );
};
