import { useCallback, useEffect, useState } from "react";
import { BigNumber, ethers, providers } from "ethers";
import { Link } from "react-router-dom";
import { TooltipSlider } from "core/components/Slider";
import { nftAbi, minterAbiV4 } from "core/abi";
import { MiamiMintWrap, MintCounter, MintWrap, FormWrap } from "./style";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { Spinner } from "core/components/Spinner";
import { Modal } from "shared/components/WalletPopUp";

import styled from "styled-components";

import { correctNetwork, infuraId, nftAddress, minterAddress, getArtworkNameById } from "core/common";
import { theme } from "core/styles/theme";
import { Button } from "shared/components/Button";
import { useForm } from "react-hook-form";

let price: BigNumber;
let signer: any;
const infuraProvider = new providers.InfuraProvider(correctNetwork, infuraId);
const readMinterContract = new ethers.Contract(
  minterAddress,
  minterAbiV4,
  infuraProvider
);

const readNftContract = new ethers.Contract(nftAddress, nftAbi, infuraProvider);

export const V4Mints = ({actualEdition, img}) => {

  const MintImg = styled.div`
    height: 100%;
    width: 100%;
    align:center;
    background: {theme.palette.white};
    vertical-align:middle;
    margin:auto;
    text-align:center;
    display: block;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    & div {
     background: {theme.palette.white};
    }
    & img {
      width:80%;
      margin: auto;
    }
    @media (min-width: 992px) {
      height: auto;
    }
  `;

  let artworkName = getArtworkNameById(actualEdition * 100);
  artworkName = artworkName.charAt(0).toUpperCase() + artworkName.slice(1);

  interface IFormInputs {
    friend: string,
    imbuement: string,
  }

  const { register, handleSubmit, formState: { errors }, reset } = useForm<IFormInputs>();

  const [disabled, setDisabled] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [nextMinor, setNextMinor] = useState(1);
  const [maxMinor, setMaxMinor] = useState(0);
  const [openForMint, setOpenForMint] = useState(false);
  const [canSelfMint, setCanSelfMint] = useState(true);
  const [perTokenPrice, setPerTokenPrice] = useState("0");
  const [userChosenAmount, setUserChosenAmount] = useState(1);

  const context = useWeb3React<Web3Provider>();
  const { account } = useWeb3React();
  const { library, connector } = context;

  if (connector) {
    //If network Changed - reload page
    window.ethereum?.on("chainChanged", (_chainId: string) =>
      window.location.reload()
    );
  }

  function errorHandler(err: any) {
    if (err) {
      if (err.code) {
        switch (err.code) {
          case 4001:
            setError("Transaction rejected");
            break;
          default:
            setError("Unknown error: " + err.message);
        }
      }
    }
  }

  //check if network chosen correct
  function isOnCorrectNetwork() {
    return (
      library &&
      library.getNetwork().then((data: any) => data.name === correctNetwork)
    );
  }

  function mintBtn() {
    if (nextMinor > maxMinor) {
      return (
        <Button disabled>Minting is closed</Button>
      );
    }
    if (!account) {
      return <Modal context={context} />;
    }

    const mintSliderMax = 10;
    return (
      <div className="mintSlider">
        { openForMint ? (
          <FormWrap >
            <form onSubmit={handleSubmit(mint)}>
              { actualEdition === 4 &&
              <div>
                <input type="text" placeholder={"(Optional) A friend's address or ENS"} {...register("friend", {
                })} />
              </div>
              }
              <div>
                <input type="text" placeholder={"Imbue your NFT"} {...register("imbuement", {
                  maxLength: {
                    value: 32,
                    message: 'Not more than 32 characters'
                  }
                })} />
              </div>
              <button type={"submit"} disabled={disabled}>Mint and imbue</button>
            </form>
            {errors.imbuement && <p className={'formError'}>{errors.imbuement.message}</p>}
          </FormWrap>
        ) : (
          ""
        )}
      </div>
    );
  }

  //After component rendered get minor number, balance of ETH and price for mint
  useEffect(() => {
    (async function () {
      const mintInfo = readMinterContract.mintInfos(actualEdition);
      let newNextMinor, newMaxMinor, openForMint, canSelfMint, newPrice;
      [newNextMinor, newMaxMinor, openForMint, canSelfMint, newPrice] = await mintInfo;
      price = newPrice;
      setPerTokenPrice(ethers.utils.formatEther(price));
      setOpenForMint(openForMint);
      setCanSelfMint(canSelfMint);

      newMaxMinor -= actualEdition * 100;
      newNextMinor -= actualEdition * 100;

      const editionMax = (actualEdition + 1) * 100 - 1;

      newMaxMinor = newMaxMinor > editionMax ? editionMax : newMaxMinor;
      // Normalize according to the relevant edition
      if (newNextMinor > editionMax) {
        newMaxMinor = editionMax;
        newNextMinor = editionMax + 1;
      }

      setMaxMinor(newMaxMinor);
      setNextMinor(newNextMinor);
    })();
  }, []);

  /*
  //Minting function
  const mint = async () => {
    if (!account) {
      setError("Please connect your wallet");
      return;
    }
    if (!(await isOnCorrectNetwork())) {
      setError("Please choose the " + correctNetwork + " network in your wallet");
      return;
    }

    //Store signer if wallet connected
    setDisabled(true);
    setError(null);
    signer = library!.getSigner();
    const minterWithSigner = new ethers.Contract(
      minterAddress,
      minterAbiV4,
      signer
    );
    let mintPrice = price.mul(userChosenAmount);
    console.log("mint price", ethers.utils.formatEther(mintPrice));
    minterWithSigner
      .mint(actualEdition, userChosenAmount, { value: mintPrice })
      .then((data: any) => {
        if (data) {
          setDisabled(false);
        }
      })
      .catch((e: any) => {
        errorHandler(e);
        setDisabled(false);
      });
  };
  */

  const mint = async (data : IFormInputs ) => {
    if (!account) {
      setError("Please connect your wallet");
      return;
    }
    if (!(await isOnCorrectNetwork())) {
      setError("Please choose the " + correctNetwork + " network in Metamask");
      return;
    }

    //Store signer if wallet connected
    setDisabled(true);
    setError(null);
    signer = library!.getSigner();
    const minterWithSigner = new ethers.Contract(
      minterAddress,
      minterAbiV4,
      signer
    );
    if (!data.friend) {
      data.friend = account;
    }
    minterWithSigner
      .mint(actualEdition, userChosenAmount, data.friend, data.imbuement)
      .then((data: any) => {
        if (data) {
          setDisabled(false);
        }
      })
      .catch((e: any) => {
        errorHandler(e);
        setDisabled(false);
      });
  };

  return (
    <MintWrap>
      <MiamiMintWrap>
        <p className="nftWeave">NFT Photograph - Price: {perTokenPrice} ETH</p>
        <h3>Imbued with {artworkName}</h3>
        <MintCounter>
          <p className="mintCounter">
            <span>{nextMinor - 1}</span>sold,{" "}
            <span>{maxMinor - nextMinor + 1}</span> remaining
          </p>
        </MintCounter>
        {actualEdition === 4 ?
        <><p className="nftWeave__desc">
        This is a <b>"Pay it Forward NFT"</b>. As you mint it, you enter the wallet adress of a friends of yours, along with a few words to dedicate your friendship. Those words gets stored on the NFT, as it gets transferred to your friend. Your friend will then be able to repeat the ritual, and pass the NFT along to another friend, creating a chain of connection and trust.
        </p>
        <p className="nftWeave__desc">
        Every new friendship changes the physical artwork, as it uses your Imbued words to generate new patterns of light and color throuogh the fabric.
        </p></>
        : ""}
        <p className="nftWeave__desc">
          Each NFT represents a unique photograph of the artwork, and allows you to Imbue your own words onto the blockchain.<br />
        </p>
        {/*}
        <p className="nftWeave__desc">
          Each NFT also includes the right to a 50x50cm photo print on Hahnemuhle Studio Enhanced 210gsm Fine Art Paper and framed in a black 20×30mm wooden frame with anti-reflective art glass. A proof of authenticity, signed by the artist, is attached to the print. Shipping costs apply.
        </p>
        */}
        {mintBtn()}
        {error ? <p className={"errorLabel"}>{error}</p> : null}
        <p className="mintInstructions">
          <br />
          <span>
            <Link to="/faq" className="instructionsLink">
              Click here
            </Link>
          </span>{" "}
          for step by step instructions.
          <br />
        </p>
      </MiamiMintWrap>
      <MintImg>
        <img src={img} /><br/>
        Example
      </MintImg>
      <p className="nftLicence">
        Imbued with {artworkName} is an NFT sold by Tadaa Art AB using the Dapper{" "}
        <Link to={"#"}>Labs open NFT Licence</Link>
        <br />
      </p>
    </MintWrap>
  );
};
