import styled from "styled-components";
import footer from "../../assets/img/footer.svg";

export const FooterWrapper = styled.footer`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
`;

export const CommunityAndInfoSection = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  & > div:not(:last-child) {
    margin-right: 50px;
  }
`;

export const CommunityAndInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 30%;
  max-width: 600px;
`;

export const FAQSection = styled.div``;

export const FooterBottomSection = styled.div`
  padding-top: 166px;
  padding-bottom: 40px;
  position: relative;
  overflow: hidden;
  background-image: url(${footer});
  background-size: cover;
  background-position: left top;
  image-rendering: pixelated;
`;

export const FooterCAIWrap = styled.div`
  &.content-wrapper {
    @media (max-width: 1200px) {
      max-width: 80%;
    }
  }
  width: 80%;
  margin: 0px auto 0;
  background: ${({ theme }) => theme.palette.white};
  border-radius: 0 ${({ theme }) => theme.borderRadius.block};
  display: flex;
  flex-direction: column;
  padding: 36px;
  @media (min-width: 1200px) {
    flex-direction: row;
  }
  @media (min-width: 1440px) {
    padding: 50px;
    justify-content: center;
  }
`;

export const FooterCAI = styled.div`
  @media (min-width: 1200px) {
    margin-right: 155px;
  }
  & p {
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.paragraph};
    margin-bottom: 0px;
    max-width: 615px;
    @media (min-width: 1281px) and (max-width: 1440px) {
      font-size: 15px;
    }
    @media (min-width: 1025px) and (max-width: 1280px) {
      margin-bottom: 0px;
    }
  }
`;

export const FooterCAIBtns = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  @media (min-width: 768px) {
    flex-direction: column;
  }
  @media (min-width: 1800px) {
    flex-direction: row;
  }
`;
