import { TheArtworkInfo, TheArtworkWrap } from "./style";
import { ImbuedSlider } from "shared/components/Artwork/ImbuedSlider";

import { ARTWORK_FRIENDSHIP } from 'routing/appRoute/paths';

import friendship1 from "core/assets/img/friendship1.jpg";
import friendship2 from "core/assets/img/friendship2.jpg";
import friendship3 from "core/assets/img/friendship3.jpg";
import friendship4 from "core/assets/img/friendship4.jpg";
import friendship5 from "core/assets/img/friendship5.jpg";
const friendshipSlider = [
  {
    img: friendship1,
    link: ARTWORK_FRIENDSHIP,
  },
  {
    img: friendship2,
    link: ARTWORK_FRIENDSHIP,
  },
  {
    img: friendship3,
    link: ARTWORK_FRIENDSHIP,
  },
  {
    img: friendship4,
    link: ARTWORK_FRIENDSHIP,
  },
  {
    img: friendship5,
    link: ARTWORK_FRIENDSHIP,
  }
];

export const TheArtwork = () => (
  <TheArtworkWrap>
    <TheArtworkInfo>
      <h2 className="white">The Artwork</h2>
      <p>
      Imbued with Friendship is the largest piece in the Imbued collection. Throughout all ages, through all cultures, we have used tokens to honor our friendships. By binding our sense of connection, love and dedication into things, we make them more real, transparent and lasting.
      </p><p>
      The 99 photographic NFTs invite their owners to a Friendship ritual, where they get to pass their NFT on to a friend, writing a testament of their friendship permanently on the blockchain. The physical art piece uses these words of love to generate the patterns flowing through the fabric.
    </p><p>
    <b>Medium:</b> Custom woven optical fiber fabric. Woven by the artist at Ekelund Weavers in Horred, Sweden. Made with a cotton warp and optical fiber, cotton and shrinking yarn in the weft. 270 addressable LEDs, controlled by a ESP32 microcontroller. Wooden frame.
    </p><p>
      <b>Dimensions:</b> 200 x 180 cm (6.5 x 6 ft)
      </p>
            <p>
      <b>Exhibiting:</b><br/>
      <a href="https://artwithme.org/">Art with Me</a> (Nov 26 - 27)<br/>
      <a href="https://metaverse.miami">Metaverse Miami</a> (Nov 28 - 30)<br/>
      <a href="https://wonderfairart.org/">Wonder Fair 5.0</a> (Dec 1 - Dec 4)
      </p>
    </TheArtworkInfo>
    <ImbuedSlider imgArr={friendshipSlider} cls={'artworkSlider'} />
  </TheArtworkWrap>
)