import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { getArts, getWeaves } from "redux/actions";
import { nftAbi } from "core/abi";
import { nftAddress, getThumbnail, apiName, imbuedartLink } from "core/common";
import { YourArtworks } from "./components/YourArtworks";
import { YourNftWeaves } from "./components/YourNftWeaves";
import { NotConnectedWallet } from "./components/NotConnectedWallet";
import { MyWalletSection } from "./style";
import { injected } from "core/components/Header/components/connectors";
import { TRootReducer } from "redux/rootReducer";
import { HeaderWrapper } from "shared/components/HeaderWrapper";
import { EmailRegister } from "./components/EmailRegister";
import { EmailInfo } from "./components/EmailInfo";

import { Amplify,  API } from 'aws-amplify';
import awsconfig from 'aws-exports';

Amplify.configure(awsconfig);

export const MyWallet = () => {
  interface INftData {
    img: string;
    thumbnail: string;
    id: number;
    comment?: string;
    name?: string;
  }

  const context = useWeb3React<Web3Provider>();
  const { account, activate } = useWeb3React();

  const [loadingArtworks, setLoadingArtworks] = useState<boolean>(true);
  const [loadingWeaves, setLoadingWeaves] = useState<boolean>(true);
  const { library } = context;
  const contract = useMemo(() => new ethers.Contract(
    nftAddress,
    nftAbi,
    library
  ), [library]);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const weaves = useSelector((state: TRootReducer) => state.nftData.weaveData)
  const arts = useSelector((state: TRootReducer) => state.nftData.artData)

  if (!account) {
    injected.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized) {
        activate(injected, undefined, true)
          .catch((e) => {
            console.log(e)
          })
      }
    })
  }

  const getOwnNftId = useCallback(async () => {
    try {
      //get amount of tokens
      const bal = await contract.balanceOf(account)
      const res: Array<number> = [];
      // Fetch all token ids in parallel.
      await Promise.all(Array.from(Array(parseInt(bal)).keys()).map(async (i) => {
        const tokenId = await contract.tokenOfOwnerByIndex(account, i)
        res.push(parseInt(tokenId))
      }));
      // return sorted array of token ids
      res.sort((x,y) => x - y);
      return res;
    } catch (e) {
      console.log(e)
    }
  }, [account, contract]);


  // get images and comments of weaves and artworks
  const getNftData = useCallback(async (idArr) => {
    if (!idArr) return

    // fetch image of all received weaves by ids
    await Promise.all<Response>(idArr.map((id) => fetch(`${imbuedartLink}${id}`)))
      .then(responses => 
        // Parse json
        Promise.all(responses.map(resp => resp.json())),
      ).then((responses) => {

        console.log(responses);
        const weaveArr: Array<INftData> = [];
        const artArr: Array<INftData> = [];
        idArr.forEach((id, index) => {
          // All artworks have ids 0, 100, 200, 300 etc.
          let comment = "";
          const attributes = responses[index].attributes;
          let imbuer, imbuement;
          for (let i = 0; i < attributes.length; i++) {
            if (attributes[i].trait_type === "Last imbued by") {
               imbuer = attributes[i].value;
            }
            if (attributes[i].trait_type === "Last imbued with") {
               imbuement = attributes[i].value;
            }
          }
          if (imbuer?.toLowerCase() == account?.toLowerCase()) {
            comment = imbuement;
          }
          if (id === 0 || (id % 100 === 0)) {
            artArr.push({ id, name: responses[index].name, img: responses[index].image, thumbnail: getThumbnail(id), comment: comment })
          } else {
            // All else ids is weaves
            weaveArr.push({ id, img: responses[index].image, thumbnail: getThumbnail(id), comment: comment })
          }
        })
        // Store all data to redux state.
        dispatch(getArts(artArr))
        dispatch(getWeaves(weaveArr))
      }).catch(e => console.log(e));
  }, [account, contract, dispatch])

  const emailRegisterHandler = async (email: string) => {
    const signer = await context?.library?.getSigner();
    if (!signer) {
      throw Error("No wallet connected");
    }
    const salt = "I want to register the following e-mail for my Ethereum address on Imbued Art: ";
    const message = salt + email;
    const signature = await signer.signMessage(message);
    API.put(apiName, '/owners', {headers: {}, body: {
      pk: account,
      sk: "email",
      email: email,
      salt: salt,
      signature: signature,
    }}).then((res) => {
      setEmail(email);
    });
  }

  useEffect(() => {
    if (!account) return
    getOwnNftId()
      .then(res => {
        if (!res) return;
        // Check whether we can mark loading as complete early,
        // if the user either has no artworks or no weaves.
        let ownsArtwork : boolean = false;
        let ownsWeave   : boolean = false;
        for (let i = 0; i < res.length; i++) {
          if (res[i] % 100 === 0) {
            ownsArtwork = true;
          } else if (res[i] % 100 !== 0) {
            ownsWeave = true;
          }
        }
        if (!ownsArtwork) {
          setLoadingArtworks(false);
        }
        if (!ownsWeave) {
          setLoadingWeaves(false);
        }
        return getNftData(res)
      })
      .then(() => {
        setLoadingArtworks(false);
        setLoadingWeaves(false)
      })
  }, [account, getNftData, getOwnNftId]);

  return (
    <MyWalletSection className="content-wrapper">
      <HeaderWrapper>
        <h1>Your wallet</h1>
      </HeaderWrapper>

      {!account ?
        <NotConnectedWallet />
        : (<>
          <YourArtworks
            loading={loadingArtworks}
            artData={arts}
          />
          <YourNftWeaves
            loading={loadingWeaves}
            weaveData={weaves}
          />
          <>
            {email ? (<EmailInfo email={email} onEmailEdit={emailRegisterHandler} />) :
              (<EmailRegister onRegister={emailRegisterHandler} />)}
          </>
        </>)
      }
    </MyWalletSection>
  );
}