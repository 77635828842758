import { TheArtworkInfo, TheArtworkWrap } from "./style";
import { ImbuedSlider } from "shared/components/Artwork/ImbuedSlider";

export const TheArtwork = () => (
  <TheArtworkWrap>
    <TheArtworkInfo>
      <h2 className="white">The Artwork</h2>
      <p>
        Imbued with Love measures 110 x 90 cm and is made from custom woven textile, developed and woven by the artist. The materials are cotton, chenille, shrink yarn, and optical fibres. These fibres are attached to 100 LEDs, using 3D-printed connectors. These LEDs are controlled by an microcontroller, which connects in real-time via Wi-Fi to the Ethereum blockchain.
      </p>
      <p>
        The artwork is attached to an "owner NFT" which grants right of ownership to the artwork and allows the caretaker to imbue the artwork with words of love. This is the NFT that will be auctioned on this page Dec 27 - Jan 2.
      </p>
      <p>
      </p>
    </TheArtworkInfo>
    <ImbuedSlider cls={'artworkSlider'} />
  </TheArtworkWrap>
)