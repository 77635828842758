import styled from "styled-components";
import tempBg from 'core/assets/img/nftwp.jpg'
import { paddingStyle1, marginBottomStyle2, marginBottomStyle4 } from "core/styles/mixins";

export const GridSectionWrap = styled.div`
  margin-top: ${({ theme }) => theme.contentSpacing};
`;

export const GridInfo = styled.div`
  background: ${({ theme }) => theme.palette.white};
  ${paddingStyle1}
  border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 ${({ theme }) => theme.borderRadius.block};
  @media (min-width: 992px) {
    padding: 50px 50px 30px;
    margin: ${({ theme }) => theme.contentSpacing} auto 0;
  }
  @media (min-width: 1200px) {
    display: flex;
    align-items: center;
  }
`;

export const GridInfoText = styled.div`
  @media (min-width: 1200px) {
    max-width: 478px;
  }

  & p {
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.paragraph};
    ${marginBottomStyle2}
  }
`;

export const GridInfoBtns = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1200px) {
    display: block;
    margin-left: 40px;
  }
  @media (min-width: 1440px) {
    margin-left: 80px;
  }
  @media (min-width: 1700px) {
    margin-left: 100px;
  }

  .button-anchor {
    background: ${({ theme }) => theme.palette.secondary};
    margin:20px auto;
    
  }
    & p {
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.paragraph};
    ${marginBottomStyle4}
  }

`;

export const GridClickText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0 20px;
  background: ${({ theme }) => theme.palette.white};
  align-items: center;

  & p {
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: 22px;
    color: ${({ theme }) => theme.palette.gray800};
    text-align: center;
    max-width: 370px;
    margin: auto;
    @media (min-width: 1200px) {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      font-size: 28px;
      max-width: 520px;
    }
  }

  & span {
    display: block;
    width: 15px;
    height: 15px;
    border-right: 3px solid ${({ theme }) => theme.palette.gray800};
    border-bottom: 3px solid ${({ theme }) => theme.palette.gray800};
    margin: 5px auto 0;
    transform: rotate(45deg);
    @media (min-width: 1200px) {
      width: 22px;
      height: 22px;
    }
  }

  @media (orientation: portrait) {
    & .desktopInfo {
      display: none;
    }
  }
  @media (orientation: landscape) {
    & .mobileInfo {
      display: none;
    }
  }
`;

export const GridWrapper = styled.div`
  background-image: url(${tempBg});
  background-repeat: no-repeat;
  background-size: cover;
  image-rendering: pixelated;
  position: relative;
  display: grid;
  border-top: 1px solid ${({ theme }) => theme.palette.paragraph};
  border-left: 1px solid ${({ theme }) => theme.palette.paragraph};
  grid-template-columns: repeat(11, 1fr);
  grid-template-rows: repeat(9, auto);



 
`;