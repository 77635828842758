import { paddingStyle1 } from "core/styles/mixins";
import styled from "styled-components";

export const EmailRegisterWrap = styled.div`
  ${paddingStyle1}
  background: ${({ theme }) => theme.palette.white};
  max-width: 632px;
  margin: ${({ theme }) => theme.contentSpacing} auto;
  border-radius: 0 ${({ theme }) => theme.borderRadius.block};
  box-shadow: 0 20px 35px ${({ theme }) => theme.palette.gray1000};
`;