import { useEffect, useState, useCallback, useMemo } from "react";
import { Spinner } from "core/components/Spinner";
import { theme } from "core/styles/theme";
import { useParams } from "react-router-dom";
import { ButtonLink } from "shared/components/ButtonLink";
import {
  NFTDetailedSection,
  NFTDetailedHeader,
  LoadingNftData,
  NftImage,
  NftInformationWrapper,
} from "./style";
import { ButtonAnchor } from "shared/components/ButtonAnchor";
import { capitalizeFirstLetter, correctNetwork, getArtworkNameById, getThumbnail, imbuedartLink, infuraId, MIDERS_THUMBNAILS_DIR, nftAddress, openseaLink, apiName } from "core/common";
import moment from "moment";
import { Contract, providers } from "ethers";
import { nftAbi } from "core/abi";

import { Amplify, API } from 'aws-amplify';
import awsconfig from 'aws-exports';

Amplify.configure(awsconfig);

interface INftDataAttributes {
  display_type?: string;
  trait_type: string;
  value: number;
  max_value?: number;
}

interface INftData {
  image: string;
  name: string;
  description: string;
  collection_name: string;
  tokenID: number;
  website: string;
  aspect_ratio: number;
  is_dynamic: boolean;
  attributes: INftDataAttributes[];
}

export const NFTDetailed = () => {
  const infuraProvider = useMemo(() => new providers.InfuraProvider(correctNetwork, infuraId), []);
  const [ownerAddress, setOwnerAddress] = useState("");
  const [ownerENSAddress, setOwnerENSAddress] = useState("");
  const [claimed, setClaimed] = useState<boolean>(false);
  const [claimedFetched, setClaimedFetched] = useState<boolean>(false);
  const readNftContract = new Contract(nftAddress, nftAbi, infuraProvider);

  const { id } = useParams<{ id?: string }>();
  const [nftData, setNftData] = useState<INftData | null>(null);
  const [nftOpenseaData, setNftOpenseaData] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const nftAPI = `${imbuedartLink}${id}`;
  const nftOpenseaAPI = `${openseaLink}${nftAddress}/${id}`;

  const isLoveNft = () => {
    return Number(id) < 100;
  }

  const getNftData = useCallback(async () => {
    setLoading(true);

    try {
      const ownerPromise = readNftContract.ownerOf(Number(id));
      const responsePromise = fetch(nftAPI);
      const response2Promise = fetch(nftOpenseaAPI);

      const owner = await ownerPromise;
      if (owner === "0x0000000000000000000000000000000000000000") {
        return;
      }
      const response = await responsePromise;
      const nftData = await response.json();
      if (nftData.message === "No such token") {
        throw new Error(nftData.message);
      }
      setNftData(nftData);

      const response2 = await response2Promise;
      const nftOpenseaData = await response2.json();
      setNftOpenseaData(nftOpenseaData);

      setOwnerAddress(owner);
      if (owner) {
        const ownerENSAddress = await infuraProvider.lookupAddress(owner);
        ownerENSAddress && setOwnerENSAddress(ownerENSAddress);
      }

    }
    catch (error) {
      setLoading(false);
    }

    setLoading(false);
  }, [nftAPI, nftOpenseaAPI, infuraProvider]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getArtworkNameById(id) && getNftData();
    API.get(apiName, `/nfts/object/${id}/claim`, {headers: {}}).then((res) => {
      if (res.claim) {
        setClaimed(true);
      } else {
        setClaimed(false);
      }
      setClaimedFetched(true);
    });
  }, [getNftData, id, claimed, claimedFetched]);

  const displayId = (id?: string) : number | undefined => {
    if(id) {
      return parseInt(id) % 100;
    }
    return undefined;
  }
  
  return (
    <NFTDetailedSection className="content-wrapper">
      {getArtworkNameById(id) ? <>
        <NFTDetailedHeader>
          <h1>Imbued with {capitalizeFirstLetter(getArtworkNameById(id))} #{displayId(id)}</h1>
          <ButtonLink to={`/artworks/${getArtworkNameById(id)}`}>View Artwork</ButtonLink>
        </NFTDetailedHeader>

        {loading ?
          <LoadingNftData >
            <Spinner color={theme.palette.mainText} style={{ width: "80px", height: "80px" }} />
          </LoadingNftData>
          :
          <>
            <NftImage className={getArtworkNameById(id)} >
              <img src={getThumbnail(id, MIDERS_THUMBNAILS_DIR)} alt="nft" />
            </NftImage>
            {nftData?.image && <ButtonAnchor href={nftData?.image}>View Full Resolution Photo</ButtonAnchor>}
            {nftData && (
              <NftInformationWrapper>
                <div>
                  <h4>Properties:</h4>
                  {nftData?.attributes?.map((item, index) => {
                    return <p key={index}><span>{item.trait_type}:</span> {item.value}</p>
                  })}
                </div>
                <div>
                  { nftOpenseaData?.last_sale &&
                    <>
                      <h4>Last price:</h4>
                      <p className="eth-price-wrapper">
                        <span className="eth-icon"></span>
                        { nftOpenseaData?.last_sale.total_price / 10 ** 18 }
                      </p>
                    </>
                  }
                  {nftOpenseaData?.last_sale?.event_timestamp && (
                    <>
                      <h4>Sell date:</h4>
                      <p>{moment(new Date(nftOpenseaData?.last_sale?.event_timestamp)).format('MMMM D YYYY, h:mm A')}</p>
                    </>
                  )}

                  <h4>Owned by:</h4>
                  <p>{ownerENSAddress || ownerAddress}</p>
                  <h4>Physical print</h4>
                  <p>{claimedFetched
                          ?  <>Print <b>has {claimed ? "" : "not"}</b> been claimed.</>
                          : "Fetching claim status ..."}
                  </p>
                  {nftOpenseaData?.permalink && <ButtonAnchor href={nftOpenseaData?.permalink}>View On Opensea</ButtonAnchor>}
                </div>
              </NftInformationWrapper>
            )}
          </>
        }
      </> :
        <h1>NFT ID is not valid!</h1>
      }
    </NFTDetailedSection>
  )
}

