import { useEffect } from "react";
import { Wrapper } from "./style";
import { BottomSlider } from "./style";
import { ImbuedInfo } from "./components/ImbuedInfo";
import { TheArtwork } from "./components/TheArtwork";
import { GridSection } from "./components/GridSection";
import { GridSlogan } from "./components/GridSlogan";
import { Exhibition } from "./components/Exhibition";
import { ForSale } from "./components/ForSale";
import { HeaderWrapper } from "shared/components/HeaderWrapper";
import { OwnedBy } from "shared/components/Artwork/OwnedBy";
import { ImbuedSlider } from "shared/components/Artwork/ImbuedSlider";
import { ArtistSection } from "shared/components/ArtistSection";

import tempBg from 'core/assets/img/nftwp.jpg';

export const ArtworkLonging = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Wrapper className="content-wrapper">
      <ImbuedInfo />
      <TheArtwork />
      {/*<ForSale id={300} />*/}
      {/*<OwnedBy id={300} />*/}
      <GridSection from={300} to={399} image={tempBg} />
      {/*<GridSlogan />*/}
      <ArtistSection />
      <BottomSlider>
        <h3>Discover the Imbued series</h3>
        <ImbuedSlider cls={'BottomSlider'} />
      </BottomSlider>
    </Wrapper>
  )
}

