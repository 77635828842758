import styled from "styled-components";
import { paddingStyle1 } from "core/styles/mixins";

export const MintWrap = styled.div`
  position: relative;
  z-index: 2;
  @media (min-width: 992px) {
    margin: ${({ theme }) => theme.contentSpacing} auto;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  & .nftLicence {
    text-align: right;
    font-style: italic;
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 15px;
    color: ${({ theme }) => theme.palette.mainText};
    margin: 20px 0 0 auto;
    padding-right: 36px;
    max-width: 342px;
    @media (min-width: 992px) {
      grid-column: 1/3;
      justify-self: end;
      max-width: unset;
    }

    & a {
      color: ${({ theme }) => theme.palette.greenishCyan};
      text-decoration: underline;
    }
  }
`;

export const MiamiMintWrap = styled.div`
  ${paddingStyle1}
  margin-top: ${({ theme }) => theme.contentSpacing};
  background: ${({ theme }) => theme.palette.white};
  border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 0;
  @media (min-width: 992px) {
    margin-top: 0;
    border-radius: 0 0 0 ${({ theme }) => theme.borderRadius.block};
  }

  & .nftWeave {
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 22px;
    color: ${({ theme }) => theme.palette.paragraph};
  }

  & .nftWeave__desc {
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.paragraph};
    margin: 20px 0;
  }

  & .errorLabel {
    font-weight: ${({theme}) => theme.fontWeight.medium};
    font-size: 17px;
    margin: 0.5rem 0 0;
    color: ${({ theme }) => theme.palette.red100};
  }

  & .mintInstructions {
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 15px;
    color: ${({ theme }) => theme.palette.paragraph};
    margin-top: 10px;
  }

  & .instructionsLink,
  & .supportEmailLink,
  & .discordLink {
    color: ${({ theme }) => theme.palette.greenishCyan};
    text-decoration: underline;
  }
  & .rc-slider-mark-text {
    font-weight: ${({theme}) => theme.fontWeight.medium};
    font-size: 17px;
  }
`;

export const MintCounter = styled.div`
  font-weight: ${({theme}) => theme.fontWeight.semiBold};
  font-size: 17px;
  color: ${({ theme }) => theme.palette.paragraph};
  padding: 20px 0;
  border-top: 1px solid ${({ theme }) => theme.palette.paragraph};
  border-bottom: 1px solid ${({ theme }) => theme.palette.paragraph};
  & span {
    font-weight: ${({theme}) => theme.fontWeight.bold};
    font-size: 28px;
    color: ${({ theme }) => theme.palette.mainText};
    padding: 0 2px;
    background: ${({ theme }) => theme.palette.gray200};
    margin-right: 2px;
    &:nth-child(3) {
      margin-left: 2px;
    }
  }
`;


export const FormWrap = styled.div`
  margin: auto;
  max-width: 420px;

  & form {
    display: flex;
    display: block;
  }

  & input {
    background: ${({ theme }) => theme.palette.gray100};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    padding: 14px 22px;
    margin: 5px;
    width: 100%;
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 15px;
    color: ${({ theme }) => theme.palette.paragraph};

    &::placeholder {
      font-weight: ${({theme}) => theme.fontWeight.medium};
      font-size: 15px;
      color: ${({ theme }) => theme.palette.gray500};
    }
  }

  & button {
    padding: 14px 25px;
    border-radius: ${({ theme }) => theme.borderRadius.default};
    background: ${({ theme }) => theme.palette.gray200};
    color: ${({ theme }) => theme.palette.mainText};
    
  }

  & .formError {
    font-weight: ${({theme}) => theme.fontWeight.bold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.greenishCyan};
    margin: 10px 0 0;
  }
`;