import { createGlobalStyle } from 'styled-components'
import { marginBottomStyle2 } from './mixins'
import { theme } from './theme'

export default createGlobalStyle`
  body {
    font-family: ${theme.mainFontFamily};
    background: ${theme.palette.background};
    color: ${theme.palette.mainText};
  }

  .content-wrapper {
    width: 100%;
    margin: 0 auto ${theme.contentSpacing};

    @media (min-width: 992px) {
      max-width: ${theme.contentWidth};
    }
  }

  p {
    line-height: 1.25;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${theme.palette.mainText};
    line-height: 1.3;
  }

  h2, h3, h4, h5, h6 {
    ${marginBottomStyle2}
  }

  h1 {
    text-align: center;
    font-size: 42px;
    @media (max-width: 630px) {
      font-size: 38px;
    }
    @media (min-width: 1500px) {
      font-size: 52px;
    }
  }

  h2 {
    font-size: 38px;
    @media (max-width: 630px) {
      font-size: 31px;
    }
    @media (min-width: 1500px) {
      font-size: 42px;
    }
  }

  h3 {
    font-size: 31px;
    @media (max-width: 630px) {
      font-size: 28px;
    }
    @media (min-width: 1500px) {
      font-size: 34px;
    }
  }

  h4 {
    font-size: 28px;
    @media (max-width: 630px) {
      font-size: 24px;
    }
    @media (min-width: 1500px) {
      font-size: 31px;
    }
  }

  .white {
    color: ${theme.palette.white};
  }

  .error {
    color:  ${theme.palette.vermilion};
  }
`