import { LoadingNftWeaves, YourNftWeavesTitle, YourNftWeavesWrap } from "./style";
import { EmptyNftWeaves } from "./EmptyNftWeaves";
import { DownloadedNftWeaves } from "./DownloadedNftWeaves";
import { Spinner } from "core/components/Spinner";
import { theme } from "core/styles/theme";

export const YourNftWeaves = ({ weaveData, loading }) => {

  return (
    <YourNftWeavesWrap>
      <YourNftWeavesTitle>
        Your NFT Weaves
      </YourNftWeavesTitle>
      {loading ?
        <LoadingNftWeaves>
          <p>Loading your weaves</p>
          <Spinner color={theme.palette.mainText} />
        </LoadingNftWeaves> :
        (!weaveData.length ?
          <EmptyNftWeaves /> :
          (weaveData.map((item) => {
            return (
              <DownloadedNftWeaves key={item.id} item={item} />
            )
          }))
        )
      }
    </YourNftWeavesWrap>
  )
}