import styled from "styled-components";
import swipe_divider_with_texture from "core/assets/img/swipe_divider_with_texture.png";
import {paddingStyle1, marginBottomStyle2 } from "core/styles/mixins";

export const HpSliderText = styled.div`
  display: flex;
  flex-direction: column;
  ${marginBottomStyle2}

  & p {
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.paragraph};
    @media (max-width: 1600px) {
      font-size: 14px;
    }
    @media (max-width: 1440px) {
      font-size: 14px;
    }
    @media (max-width: 992px) {
      font-size: 17px;
    }

    & a {
      color: ${({ theme }) => theme.palette.greenishCyan};
    }
  }
`;
export const HpSliderSection = styled.div`
  position: relative;
`;
export const HpSliderBg = styled.div`
  background-image: url(${swipe_divider_with_texture});
  position: absolute;
  left: 0;
  right: 0;
  top: -20%;
  bottom: -20%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
  background-position: center;
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const HpSliderWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap-reverse;
  border-radius: 0 ${({ theme }) => theme.borderRadius.block};
  background: ${({ theme }) => theme.palette.gray900};
  position: relative;
  z-index: 2;
  @media (max-width: 992px) {
    width: 100%;
    flex-direction: column;
  }
`;
export const HpSliderTextWrap = styled.div`
  padding: 4%;
  width: 50%;
  @media (max-width: 992px) {
    width: 100%;
    ${paddingStyle1}
  }

  & div:nth-last-child(1) {
    margin-bottom: 0;
  }
`;
export const HpSliderImgWrap = styled.div`
  width: 50%;
  @media (max-width: 992px) {
    width: 100%;
    height: 514px;
    order: -1;
  }
`;
export const HpSliderImg = styled.p<{ backgroundImg?: string }>`
  background-image: url(${(props) => props.backgroundImg});
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  border-top-right-radius: ${({ theme }) => theme.borderRadius.block};
`;
