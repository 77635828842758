import {ToggleBurgerWrapper} from "./styles";

interface IToggleBurger {
    onClick: () => void
}

export const ToggleBurger: React.FC<IToggleBurger> = (props) => {
    return (
        <ToggleBurgerWrapper id='burger' {...props}>
            <span/>
        </ToggleBurgerWrapper>
    )
}