import { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { ethers } from "ethers";
import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { nftAddress } from "core/common";
import { dataAbi, nftAbi } from "core/abi";
import { injected } from "core/components/Header/components/connectors";
import {
  MyArtworkComment,
  MyArtworkDetailedSection,
  MyArtworkVideo,
  MyArtworkInfo,
  FormWrap,
  MyArtworkDisclaimer,
} from "./style";
import { Spinner } from "core/components/Spinner";
import { theme } from "core/styles/theme";

interface IFormInputs {
  imbue: string,
}

interface IImbueArtworkProps {
  id: string;
  headerText: string;
  videoId: string;
  children: React.ReactNode;
}

export const ImbueArtwork: React.FC<IImbueArtworkProps> = ({
  id,
  headerText,
  videoId,
  children,
}) => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm<IFormInputs>();
  const [currentComment, setCurrentComment] = useState<string>('');
  const [statusLoaded, setStatusLoaded] = useState<boolean>(false);
  const [belongsToUser, setBelongsToUser] = useState<boolean>(false);
  const [isMinted, setIsMinted] = useState<boolean>(false);
  const [activeForm, setActiveForm] = useState<boolean>(true);

  const { account, activate } = useWeb3React();
  const context = useWeb3React<Web3Provider>();
  const { library } = context;

  if (!account) {
    injected.isAuthorized().then((isAuthorized: boolean) => {
      if (isAuthorized) {
        activate(injected, undefined, true)
          .catch((e) => {
            console.log(e);
          })
      }
    })
  }

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    const signer = library?.getSigner();
    const contract = new ethers.Contract(
      nftAddress,
      nftAbi,
      library
    );
    contract.dataContract().then((dataContract) => {
      const imbueWithSigner = new ethers.Contract(
        dataContract,
        dataAbi,
        signer
      );

      const imbuementB32 = ethers.utils.formatBytes32String(data.imbue);
      imbueWithSigner.imbue(id, imbuementB32).then(() => {
        reset();
        setActiveForm(false);
      });
    });
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    const contract = new ethers.Contract(
      nftAddress,
      nftAbi,
      library
    );
    contract.ownerOf(id).then((owner) => {
      setIsMinted(true);
      if (owner.toLowerCase() === account?.toLowerCase()) {
        setBelongsToUser(true);
      }
      setStatusLoaded(true);
    }).catch((e) => {
      if (e.reason === "ERC721: invalid token ID" || e.data?.message === "execution reverted: ERC721: invalid token ID") {
        setIsMinted(false);
        setStatusLoaded(true);
      }
    });
    contract.dataContract().then((dataAddress) => {
      const dataContract = new ethers.Contract(
        dataAddress,
        dataAbi,
        library
      );
      dataContract.getNumImbuements(id).then((numImbuement) => {
        if (numImbuement.toNumber() > 0) {
          dataContract.getLastImbuement(id).then(([imbuement, imbuer, timestamp]) => {
            if (imbuer.toLowerCase() === account?.toLowerCase()) {
              setCurrentComment(ethers.utils.parseBytes32String(imbuement));
            }
          });
        }
      });
    }).catch((e) => console.error(e));
  }, [account, id, library]);

  return (
    <MyArtworkDetailedSection className="content-wrapper">
      <MyArtworkInfo>
        {!statusLoaded ?  <Spinner color={theme.palette.mainText} /> :
          <> { belongsToUser ? <h1>{headerText}</h1> : <h1> { isMinted ? "This work belongs to someone else" : "This work has no owner yet" } </h1> } </>
        }
      </MyArtworkInfo>
      {!belongsToUser ? <></> :
        <>
          <>
            {children}
          </>
          <MyArtworkVideo>
            <Vimeo video={videoId} className={'imbuedVideo'} responsive />
          </MyArtworkVideo>
          <MyArtworkComment>
            {currentComment && <h3>{currentComment}</h3>}
            {activeForm ?
              <FormWrap>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input type="text" placeholder={'Your words'} {...register("imbue", {
                    required: 'Can not be blank',
                    maxLength: {
                      value: 32,
                      message: 'Not more than 32 characters'
                    }
                  })} />
                  <button type={"submit"}>Imbue</button>
                </form>
                {errors.imbue && <p className={'formError'}>{errors.imbue.message}</p>}
              </FormWrap> :
              <>
                {!currentComment && <p>Reload this page after transaction end</p>}
              </>
            }
          </MyArtworkComment>
          <MyArtworkDisclaimer>
            <p>
              You may only submit 32 characters. There is a small network fee for writing the words to the blockchain.
            </p>
          </MyArtworkDisclaimer>
        </>
      }
    </MyArtworkDetailedSection>
  )
}