import {
  HpSliderBg,
  HpSliderSection,
  HpSliderText,
  HpSliderTextWrap,
  HpSliderWrap,
} from "./styles";
import {ImbuedSlider} from "shared/components/Artwork/ImbuedSlider";

import { ARTWORK_APPRECIATION, ARTWORK_AWE, ARTWORK_FRIENDSHIP, ARTWORK_GRIEF, ARTWORK_LIFE, ARTWORK_LONGING, ARTWORK_LOVE } from "routing/appRoute/paths";


import slider1 from "core/assets/img/friendship1.jpg";
import slider2 from "core/assets/img/awe1.jpg";
import slider3 from "core/assets/img/weavecloseup.jpg";
import slider4 from "core/assets/img/life5.jpg";
import slider5 from "core/assets/img/longing3.jpg";


const sliderImages = [
  {
    img: slider1,
    link: ARTWORK_FRIENDSHIP,
  },
  {
    img: slider2,
    link: ARTWORK_AWE,
  },
  {
    img: slider3,
    link: ARTWORK_LOVE,
  },
  {
    img: slider4,
    link: ARTWORK_LIFE,
  },
  {
    img: slider5,
    link: ARTWORK_LONGING,
  }
];

export const HpSlider = () => {

  return (
    <HpSliderSection>
      <HpSliderBg/>
      <HpSliderWrap className="content-wrapper">
        <HpSliderTextWrap>
          <HpSliderText>
            <h3>Imbued</h3>
            <p>
              Imbued is a series of seven wall-hanging artworks by the Swedish
              artist Malin Bobeck Tadaa.<br></br>
              <br></br>The artworks are made from custom-woven textiles, which
              are based in traditional techniques and materials, but interwoven
              with optical fiber threads. These threads light up and create
              intricate color patterns that flow through the fabric.
            </p>
          </HpSliderText>
          <HpSliderText>
            <h3>From thread to token</h3>
            <p>
              Each artwork is imbued by the artist with an emotion; intertwining
              her own memories and rituals into the piece. An owner of this
              artwork may imbue the art with their own interpretation of this
              emotion, which will be permanently stored on the blockchain. From
              there it will affect the visual appearance of the artwork, not
              only for themselves, but for every owner thereafter. The memories of
              each caretaker makes each artwork a timeless tokens, woven from digital
              and physical threads.
            </p>
          </HpSliderText>
          <HpSliderText>
            <h3>A blockchain ritual</h3>
            <p>
              Each artwork connects in real time to 100 NFTs, digital tokens
              stored on the Ethereum blockchain. Imbued is the world’s first{" "}
              <i>true</i> Hybrid NFT Artwork. Physical works of art, which
              connect via WiFi to the blockchain and use real-time data to
              change their visual appearance.
            </p>
          </HpSliderText>
        </HpSliderTextWrap>
        <ImbuedSlider imgArr={sliderImages} cls={'homeSlider'}/>
      </HpSliderWrap>
    </HpSliderSection>
  );
};
