import { validateEmail } from "core/common";
import { FC, useState } from "react";
import { FormWrap } from "../SubscribeForm/style";

interface StandardComponentProps {
  email?: string;
  button?: string;
  onClick: (email: string) => void;
}

export const RegisterForm: FC<StandardComponentProps> = ({ email = '', button = 'Register', onClick }) => {
  const [emailValue, setEmailValue] = useState(email);
  const ErrorMessage = "Email is not valid!";
  const [error, setError] = useState("");

  const registerHandler = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (!emailValue) return;
    if (!validateEmail(emailValue)) {
      return setError(ErrorMessage);
    }
    onClick(emailValue);
    setEmailValue('');
    setError('');
  }

  return (
    <FormWrap>
      <form className="headForm" >
        <input
          className="email"
          type="email"
          name="email"
          placeholder="your@mail.com"
          value={emailValue}
          onInput={(event: React.ChangeEvent<HTMLInputElement>) => setEmailValue(event.target.value)}
        />
        <input
          type="submit"
          className="subbutton"
          value={button}
          onClick={(e) => registerHandler(e)}
        />
      </form>
      <p className="error">{error}</p>
    </FormWrap>
  )
}
