import { Link } from "react-router-dom";
import loveimg from "core/assets/img/newlistbg.png";
import aweimg from "core/assets/img/nft-awe.jpg";
import lifeimg from "core/assets/img/life1.jpg";
import longingimg from "core/assets/img/longing1.jpg";
import friendshipimg from "core/assets/img/friendship2.jpg";


import {
  ArtwListSecCard,
  ArtwListSecCardList,
  ArtwListSecCardText,
  ArtwListSecHeader,
  ArtwListSecSection,
  ArtwListSecWrap,
  ArtwSectionMask,
  ArtwCardListImgText,
  CountLabel,
  CardImg
} from "./styles";
import { ARTWORK_APPRECIATION, ARTWORK_AWE, ARTWORK_FRIENDSHIP, ARTWORK_GRIEF, ARTWORK_LIFE, ARTWORK_LONGING, ARTWORK_LOVE } from "routing/appRoute/paths";
import { useState } from "react";
import { Button } from "shared/components/Button";

interface IArtwListSecProps {
  itemsToShow?: number;
}

interface ImgInterface {
  src: string;
  height: string;
  note: string;
}

interface EventItemInterface {
  link: string;
  img: ImgInterface;
  title: string;
  description: string;
}

interface EventsInterface {
  eventsToShow: number;
  events: Array<EventItemInterface>
  expanded: boolean;
}

const LOVE_DATA = {
  link: ARTWORK_LOVE,
  img: {
    src: loveimg,
    height: '410px',
    note: 'Imbued with Love'
  },
  title: 'Imbued with Love',
  description: `
  <p>
    Exhibited at Hotel El Ganzo<br />
    San Jose del Cabo, MX<br /> 
    Dec 12, 2021 - Jan 2, 2022
  </p><p>
    Sold.
  </p>
  `
};
const AWE_DATA = {
  link: ARTWORK_AWE,
  img: {
    src: aweimg,
    height: '430px',
    note: 'Imbued with Awe'
  },
  title: 'Imbued with Awe',
  description: `
  <p>
    Exhibited at Zona Maco<br/>
    Mexico City, MX<br />
    Feb 9 - Feb 13, 2022
</p><p>
    Sold.
    </p>
    `
    
};

const LIFE_DATA = {
  link: ARTWORK_LIFE,
  img: {
    src: lifeimg,
    height: '410px',
    note: 'Imbued with Life'
  },
  title: 'Imbued with Life',
  description: `
    <p>
      <b>Exhibited:</b><br/>
      Art with Me</a> (Nov 26 - 27, 2022)<br/>
      ARES Design</a> (Nov 18 - Dec 12, 2022)
  </p>
  <p>
    Sold.
  </p>
  `
};
const LONGING_DATA = {
  link: ARTWORK_LONGING,
  img: {
    src: longingimg,
    height: '410px',
    note: 'Imbued with Longing'
  },
  title: 'Imbued with Longing',
  description: `
    <p>
      <b>Exhibited:</b><br/>
      Art with Me</a> (Nov 26 - 27, 2022)<br/>
      ARES Design</a> (Nov 18 - Dec 12, 2022)
  </p><p>
    For sale.
  </p>
  `
};
const FRIENDSHIP_DATA = {
  link: ARTWORK_FRIENDSHIP,
  img: {
    src: friendshipimg,
    height: '410px',
    note: 'Imbued with Friendship'
  },
  title: 'Imbued with Friendship',
  description: `
    <p>
      <b>Exhibited:</b><br/>
      Art with Me (Nov 26 - 27, 2022)<br/>
      Metaverse Miami (Nov 28 - 30, 2022)<br/>
      Wonder Fair 5.0 (Dec 1 - Dec 1, 2022)
  </p><p>
    For sale.
  </p>
  `
};
const GRIEF_DATA = {
  link: ARTWORK_GRIEF,
  img: {
    src: loveimg,
    height: '410px',
    note: 'Imbued with Grief'
  },
  title: 'Imbued with Grief',
  description: `
    For sale<br /><br /> 
    <i>”Your umbrella is a treasure<br /> 
    It will keep me dry <br />
    I bring it with me everywhere I go<br /> 
    The sense of being held<br />
    The ease of falling <br />
    Being caught again”</i>
  `
};
const APPRECIATION_DATA = {
  link: ARTWORK_APPRECIATION,
  img: {
    src: loveimg,
    height: '410px',
    note: 'Imbued with Appreciation'
  },
  title: 'Imbued with Appreciation',
  description: `
    For sale<br /><br /> 
    <i>”Your umbrella is a treasure<br /> 
    It will keep me dry <br />
    I bring it with me everywhere I go<br /> 
    The sense of being held<br />
    The ease of falling <br />
    Being caught again”</i>
  `
};
const DEAFAULT_DATA = {
  link: '#',
  img: {
    src: '',
    height: 'auto',
    note: 'Imbued with...'
  },
  title: 'NFT Drop, TBA. Auction, TBA',
  description: ''
};

console.log(Boolean(process.env.REACT_APP_LIFE), Boolean(process.env.REACT_APP_GRIEF));

export const ArtwListSec: React.FC<IArtwListSecProps> = ({ itemsToShow = 5 }) => {
  const [artworkevents, setArtworkevents] = useState<EventsInterface>({
    events: [
      LOVE_DATA,
      AWE_DATA,
      process.env.REACT_APP_LIFE === 'true' ? LIFE_DATA : DEAFAULT_DATA,
      process.env.REACT_APP_LONGING === 'true' ? LONGING_DATA : DEAFAULT_DATA,
      process.env.REACT_APP_FRIENDSHIP === 'true' ? FRIENDSHIP_DATA : DEAFAULT_DATA,
      process.env.REACT_APP_GRIEF === 'true' ? GRIEF_DATA : DEAFAULT_DATA,
      process.env.REACT_APP_APPRECIATION === 'true' ? APPRECIATION_DATA : DEAFAULT_DATA,
    ],
    eventsToShow: itemsToShow,
    expanded: false
  });


  const showMore = () => {
    const restParams = artworkevents.eventsToShow === itemsToShow ?
      { eventsToShow: artworkevents.events.length, expanded: true } :
      { eventsToShow: itemsToShow, expanded: false };
    setArtworkevents({ ...artworkevents, ...restParams });
  }

  return (
    <ArtwListSecSection>
      <ArtwSectionMask />
      <ArtwListSecWrap className="content-wrapper">
        <ArtwListSecHeader>
          <h2>Imbued with emotion</h2>
          <p>Each artwork represents a story that relates to the human ability to create meaning in physical objects.
            This is done by embedding them with intention, ritual, and historic context. Malin uses patterns, stories,
            and memories from her own life and her own family history to create the textiles and shape the art. Through
            ritual, she attaches intention and emotion to each hand-stitched detail. <br /><br />Five pieces have been annouced so far. The last two will arrive spring 2023.</p>
        </ArtwListSecHeader>
        <div>
          {artworkevents.events.slice(0, artworkevents.eventsToShow).map((event, i) =>
            <Link className={event.link === "#" ? "disabled" : ""} to={event.link} key={i}>
              <ArtwListSecCard>
                <CardImg src={event.img.src} height={event.img.height}>
                  <CountLabel className="highlight">{i + 1}</CountLabel>
                  <ArtwCardListImgText>{event.img.note}</ArtwCardListImgText>
                </CardImg>
                <ArtwListSecCardText>
                  <h4 className="white">{event.title}</h4>
                  <p
                    className={event.description ? "" : "highlight"}
                    dangerouslySetInnerHTML={{ __html: event.description || 'To be announced' }}
                  ></p>
                </ArtwListSecCardText>
              </ArtwListSecCard>
            </Link>
          )}
          <ArtwListSecCardList>
            <Button className="small primary" onClick={showMore}>
              <span>{artworkevents.expanded ? 'View less' : 'View all'}</span>
            </Button>
          </ArtwListSecCardList>
        </div>
      </ArtwListSecWrap>
    </ArtwListSecSection>
  )
}
