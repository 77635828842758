import styled from "styled-components";
import bgImg from 'core/assets/img/nftwp.jpg'
import { paddingStyle1, marginBottomStyle4 } from "core/styles/mixins";

export const NotConnectedWalletSection = styled.div`
  @media (min-width: 992px) {
    display: flex
  }
`;
export const NotConnectedWalletWrap = styled.div`
  ${paddingStyle1}
  background: ${({ theme }) => theme.palette.white};
  @media (min-width: 992px) {
    width: 50%;
    padding: 50px 60px;
    border-radius: 0 0 0 ${({ theme }) => theme.borderRadius.block};
  }

  & p {
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.paragraph};
    ${marginBottomStyle4}
  }
`;
export const NotConnectedWalletImg = styled.div`
  background: url(${bgImg}) no-repeat;
  height: 172px;
  border-radius: 0 0 0 ${({ theme }) => theme.borderRadius.block};
  @media (min-width: 992px) {
    width: 50%;
    height: auto;
    border-radius: 0;
  }
`;