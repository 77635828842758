import { LogoPlaceholder } from "./styles";
//import TreadsToTokenLogo from "./components/LogoP";

export const AniLogo = () => {

    return (
        <LogoPlaceholder>
        <iframe title="imbuedlogo" frameBorder="0"
        src={"/logo.html?size=150"} scrolling="no"
        className="App-logo"  />
            {/* <TreadsToTokenLogo/> */}
        </LogoPlaceholder>
    )
}