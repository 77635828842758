import { SubscribeForm } from "shared/components/SubscribeForm";
import {
  ImbuedInfoSection,
  ImbuedInfoWrap,
  ImbuedVideoWrap,
  ImbuedInfoSlogan,
  ImbuedOnSale
} from "./style";
import aweMain from 'core/assets/img/nft-awe.jpg';
import { ButtonAnchor } from "shared/components/ButtonAnchor";

export const ImbuedInfo = () => (
  <ImbuedInfoSection>

    <ImbuedVideoWrap>
      <p>2. Imbued with Awe</p>
      <img src={aweMain} alt="nft" />
    </ImbuedVideoWrap>
    <ImbuedInfoSlogan>
      <p><p>"It's like a superpower, flicking the switch in my mind,<br />
        Experiencing familiar things, as if it were the first time.<br />
        A beautiful flower,<br />
        The waves crashing on the shore,<br />
        The eternity of my children's eyes,<br />
        The sensation is breaking my body open like an egg,<br />
        Making my inside melt out on the ground."</p></p>
    </ImbuedInfoSlogan>
  </ImbuedInfoSection>
)