import { MyArtworkDesc } from "pages/MyWeaveDetailed/style";
import { ImbueArtwork } from "../ImbueArtwork";

export const ImbueAppreciation = ({ id }) => (
  <>
    <ImbueArtwork
      id={id}
      headerText={`Imbued with Appreciation #${id}`}
      videoId="m14grpx96xw"
    >
      <MyArtworkDesc>
        <p>
          As the caretaker of an NFT Weave, you have the power to Imbue the Weave with your own interpretation of Love. You will express this in a few words, which will be stored on the blockchain, and imprinted into the visual appearence of the physical artwork.<br />
          Each caretaker may only do this once, and the words with be a part of the artwork forever.
        </p>
        <p>
          Make sure to find yourself in a space where you can be alone and at peace.<br />Turn on sound and start the meditation.
        </p>
      </MyArtworkDesc>
    </ImbueArtwork>
  </>
)