import { HomePageWrapper } from "./styles";
// import { Hero } from "./components/Hero";
import { ThreadToToken } from "./components/ThreadToToken";
// import { FirstNftWaveDrop } from "./components/FirstNftWaveDrop";
//import { ArtistSection } from "./components/ArtistSection";
import { ArtistSection } from "shared/components/ArtistSection";
import { ArtwListSec } from "../Artworks/components/ArtwListSec";
import { HpSlider } from "./components/HpSlider";
import { NftWaves } from "./components/NftWaves";
import { useEffect } from "react";

export const HomePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <HomePageWrapper>
            {/*<Hero />*/}
            <ThreadToToken />
            <HpSlider />
            <div  className="content-wrapper">
            <ArtistSection />
            </div>
            <ArtwListSec />
            <NftWaves />
            {/*<FirstNftWaveDrop/>*/}
        </HomePageWrapper>
    )
}