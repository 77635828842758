import { useRouteMatch } from "react-router-dom";
import { ArtworksImg, ArtworksInfo, DownloadedArtworksSection } from "./style";
import { NftWeavesInfoBtns } from "../../YourNftWeaves/DownloadedNftWeaves/style";
import { ButtonLink } from "shared/components/ButtonLink";
import { NFT } from "routing/appRoute/paths";

export const DownloadedArtworks = ({ item }) => {
  let match = useRouteMatch();
  return (
    <DownloadedArtworksSection>
      <ArtworksInfo>
        <h2>Your Artworks</h2>
        <p className={'itemName'}>{item.name}</p>
        <p className={'itemComment'}>{item.comment ? item.comment : 'Not Imbued'}</p>
        <NftWeavesInfoBtns>
          <ButtonLink to={`${NFT}/${item.id}`}>View</ButtonLink>
          {!item.comment &&
            <ButtonLink to={`${match.path}/${item.id}`}>Imbue</ButtonLink>
          }
        </NftWeavesInfoBtns>
      </ArtworksInfo>
      <ArtworksImg>
        <img src={item.thumbnail} alt="Artwork" />
      </ArtworksImg>
    </DownloadedArtworksSection>
  )
}