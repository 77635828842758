import {NotConnectedWalletImg, NotConnectedWalletSection, NotConnectedWalletWrap} from "./style";
import {useWeb3React} from "@web3-react/core";
import {Web3Provider} from "@ethersproject/providers";
import {Modal} from "shared/components/WalletPopUp";

export const NotConnectedWallet = () => {
  const context = useWeb3React<Web3Provider>();
  return (
    <NotConnectedWalletSection>
      <NotConnectedWalletWrap>
        <p>To see artworks or weaves in your possession, please connect your wallet</p>
        <Modal context={context}/>
      </NotConnectedWalletWrap>
      <NotConnectedWalletImg/>
    </NotConnectedWalletSection>
  )
}