import { ButtonAnchor } from "shared/components/ButtonAnchor";
import { EmptyNftWeavesImg, EmptyNftWeavesInfo, EmptyNftWeavesWrap } from "./style";

export const EmptyNftWeaves = () => (
  <>
    <EmptyNftWeavesWrap>
      <EmptyNftWeavesInfo>
        <p>You don’t own any weave, explore some weaves through the button below.</p>
        <ButtonAnchor href="https://opensea.io/assets/imbued-art">
          Explore NFT Weaves
        </ButtonAnchor>
      </EmptyNftWeavesInfo>
      <EmptyNftWeavesImg />
    </EmptyNftWeavesWrap>
  </>
)