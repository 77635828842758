import {
  GridInfo,
  GridInfoBtns,
  GridClickText,
  GridInfoText,
  GridSectionWrap,
} from "./style";

import { ButtonAnchor } from "shared/components/ButtonAnchor";
import { ButtonLink } from "shared/components/ButtonLink";

import { GridWrapper } from "shared/components/Artwork/GridWrapper";

import NFTExample from 'core/assets/img/nft-life.jpg'


interface IGridSectionProps {
  from: number;
  to: number;
  image: string;
}

export const GridSection: React.FC<IGridSectionProps> = ({ from, to, image }) => (
  <GridSectionWrap>
    <GridInfo>
      <GridInfoText>
        <h2>The 99 Photo NFTs</h2>
{/*        <h4>
          Minting NOW!
        </h4>
        <ButtonLink to={'/mint/life'}>Mint Imbued with Life</ButtonLink>
        <br/>*/}
        <p>Imbued with Life is connected to 99 unique photographic NFTs. By owning an NFT, you get invited to enter a Life Meditation, connecting you with the very source of life. Your interpretation of this ritual will be imprinted as words on the blockchain, and mold the light patterns of the physical artwork.
        </p>
        <p>
          Each NFT also includes the right to a 50x50cm photo print on Hahnemuhle Studio Enhanced 210gsm Fine Art Paper and framed in a black 20×30mm wooden frame with anti-reflective art glass.<br/>A proof of authenticity, signed by the artist, is attached to the print.<br/>Shipping costs apply.
        </p>
</GridInfoText>
      <GridInfoBtns>
      <img src={NFTExample} alt={'Imbued with Life NFT #18'}/>
      Imbued with Life NFT #18
        <ButtonAnchor href={'https://opensea.io/collection/imbued-art?search[stringTraits][0][name]=Emotion&search[stringTraits][0][values][0]=Life'}>
          View all on OpenSea
        </ButtonAnchor>
        <ButtonAnchor href={'https://www.tadaa.se/product-category/life/'}>
          Buy in webshop
        </ButtonAnchor>
      </GridInfoBtns>
    </GridInfo>
{/*
    <GridClickText>
      <p className={'mobileInfo'}>Flip your phone sideways</p>
      <div className={'desktopInfo'}>
        <p>Click on a part of the weave<br />to see current owner<br />and imbued words</p>
        <span />
      </div>
    </GridClickText>
    <GridWrapper from={from} to={to} image={image} />*/}
  </GridSectionWrap>
)