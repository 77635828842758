import { MyArtworkDesc, MusicLink } from "pages/MyWeaveDetailed/style";
import { ImbueArtwork } from "../ImbueArtwork";

export const ImbueFriendship = ({ id }) => (
  <>
    <ImbueArtwork
      id={id}
      headerText={`Imbued with Friendship #${id}`}
      videoId="775525567"
    >
      <MyArtworkDesc>
        <p>
          As the caretaker of Imbued with Friendship NFT, you have the power to Imbue your friendship with another into the art, and onto the blockchain. This is a "Pay it Forward NFT", meant to be transferred from friend to friend, creating a chain of trust.<br/>
          Write a few words as a dedication of your friendship, and enter the wallet adress of your friend. You NFT will be transferred to that friend. Your words and this connection will then be used to create new visual expressions in the physical art piece.
        </p>
        <p>
          Make sure to find yourself in a space where you can be alone and at peace.<br />We suggest you use headphones.<br/>
          Turn on sound and start the meditation.
        </p>
        <MusicLink>
            <p>
                Music: <a href="https://open.spotify.com/track/7qFOj0ZRl1c0pYr2TGBy9O?si=7ba26daf9d804aae">The Field, by Ola Claesson</a>.
            </p>
        </MusicLink>

        </MyArtworkDesc>
    </ImbueArtwork>
  </>
)