import styled from "styled-components";

export const AweSliderWrap = styled.div`
  height: 414px;
  border-radius: 0 0 0 ${({ theme }) => theme.borderRadius.block};
  overflow: hidden;
  @media (min-width: 768px) {
    height: 575px;
  }
  @media (min-width: 992px) {
    height: 100%;
  }

  & .swiper {
    height: 100%;

    &-slide {
      & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }

  & .swiper-button-prev {
    display: none;
  }
  
  & .swiper-button-next {
    right: 0;
    width: 18px;
    height: 60px;
    background: #FFFFFFBE;
    border-radius: ${({ theme }) => theme.borderRadius.button} 0 0 ${({ theme }) => theme.borderRadius.button};

    &::after {
      content: '';
      position: relative;
      right: 5px;
      display: block;
      width: 15px;
      height: 15px;
      border-right: 1px solid blue;
      border-top: 1px solid blue;
      transform: rotate(45deg);
    }

    @media (min-width: 1200px) {
      display: none;
    }
  }

  & .swiper-pagination {
    display: none;
    @media (min-width: 1200px) {
      display: block;
      text-align: right;
      left: -10px;
    }

    &-bullet {
      width: 18px;
      height: 18px;
      border: 1px solid ${({theme}) => theme.palette.white};

      &-active {
        background: ${({theme}) => theme.palette.greenishCyan};
      }
    }
  }
`;