import { getArtworkNameById } from "core/common";
import { PageNotFound } from "pages/PageNotFound";
import { useParams } from "react-router-dom";
import { ImbueAppreciation } from "./components/ImbueAppreciation";
import { ImbueAwe } from "./components/ImbueAwe";
import { ImbueFriendship } from "./components/ImbueFriendship";
import { ImbueGrief } from "./components/ImbueGrief";
import { ImbueLife } from "./components/ImbueLife";
import { ImbueLonging } from "./components/ImbueLonging";
import { ImbueLove } from "./components/ImbueLove";

export const MyWeaveDetailed = () => {
  const { id } = useParams<{ id: string }>();

  const getImbueComponent = () => {
    switch (getArtworkNameById(id)) {
      case 'love': return <ImbueLove id={id} />;
      case 'awe': return <ImbueAwe id={id} />;
      case 'life': return <ImbueLife id={id} />;
      case 'longing': return <ImbueLonging id={id} />;
      case 'friendship': return <ImbueFriendship id={id} />;
      case 'grief': return <ImbueGrief id={id} />;
      case 'appreciation': return <ImbueAppreciation id={id} />;
      default: return <PageNotFound />;
    }
  }

  return (
    <>
      {getImbueComponent()}
    </>
  )
}