import { submitHandler } from "core/common";
import { FormWrap } from "./style";

interface StandardComponentProps {
  wrapCls?: string,
  clsInput?: string
  clsBtn?: string
  clsSubBtn?: string
}

export const SubscribeForm = ({ wrapCls = "", clsInput = "", clsBtn = "", clsSubBtn = "" }: StandardComponentProps) => {

  return (
    <FormWrap className={wrapCls}>
      <form
        action="https://malinbobeck.us20.list-manage.com/subscribe/post?u=c81dcc9b65bb760f2e2b07305&amp;id=483c935467"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className={`headForm ${clsInput}`}
        noValidate
      >
        <div className="ariahidden" aria-hidden="true" style={{ display: 'none' }}>
          <input
            type="text"
            name="b_c81dcc9b65bb760f2e2b07305_483c935467"
            tabIndex={-1}
            value=""
            readOnly
          />
        </div>
        <input
          type="email"
          placeholder="your@mail.com"
          name="EMAIL"
          className={`required email ${clsBtn}`}
          id="mce-EMAIL"
          required
        />
        <input
          type="submit"
          value="Sign up!"
          name="subscribe"
          id="mc-embedded-subscribe"
          className={`subbutton ${clsSubBtn}`}
          onClick={submitHandler}
        />
      </form>
    </FormWrap>
  )
}