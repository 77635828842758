import { useEffect } from "react";
import { ArtworkAweWrap } from "./style";
import { ImbuedInfo } from "./components/ImbuedInfo";
import { TheArtwork } from "./components/TheArtwork";
import { GridSection } from "./components/GridSection";
import { HowAuctionWork } from "./components/HowAuctionWork/";
import { GridSlogan } from "./components/GridSlogan";
import { isAuctionEnd } from "core/common";
import { HeaderWrapper } from "shared/components/HeaderWrapper";
// import { isAuctionStarted } from "core/common";
// import { Bidding } from "./components/Bidding";
// import { BidHistory } from "./components/BidHistory";
import { OwnedBy } from "./components/OwnedBy";
import { ImbuedSlider } from "shared/components/Artwork/ImbuedSlider";
import { BottomSlider } from "./style";

import tempBg from 'core/assets/img/map-awe.jpg'

export const ArtworkAwe = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <ArtworkAweWrap className="content-wrapper">
      <ImbuedInfo />
      {/*(isAuctionStarted() && !isAuctionEnd()) && <Bidding/>*/}
      <TheArtwork />
      {/*<OwnedBy id={100} />*/}
      <GridSection from={100} to={199} image={tempBg} />
      <GridSlogan />
      <BottomSlider>
        <h3>Discover the Imbued series</h3>
        <ImbuedSlider cls={'BottomSlider'} />
      </BottomSlider>
    </ArtworkAweWrap>
  )
}