import React from "react";
import { Button } from "./styles";

interface IButtonAnchor {
    href: string,
    target?: string;
    rel?: string;
    disabled?: boolean;
    className?: string;
}

export const ButtonAnchor: React.FC<IButtonAnchor> = ({ href, target = "_blank", rel = "", disabled = false, className = "", children }) => {
    return (
        <Button
            className={`button-anchor ${className} ${disabled ? 'disabled' : ''}`}
            href={href}
            rel={rel}
            target={target}
        >
            {children}
        </Button>
    )
}
