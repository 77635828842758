import { marginBottomStyle2, marginBottomStyle3 } from "core/styles/mixins";
import styled from "styled-components";

export const TheArtworkWrap = styled.div`
  margin-top: ${({ theme }) => theme.contentSpacing};
  @media (min-width: 992px) {
    margin: ${({ theme }) => theme.contentSpacing} auto 0;
    display: flex;
  }
  & .artworkSlider {
    width: 100%;
    height: auto;
    @media (min-width: 1200px) {
      width: 50%;
    }
  }
`;

export const TheArtworkInfo = styled.div`
  padding: 2rem;
  background: ${({ theme }) => theme.palette.mainText};
  border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 0;
  @media (min-width: 992px) {
    width: 50%;
    order: 1;
    padding: 50px;
    margin-left: -1px;
  }

  & p {
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.white};
    ${marginBottomStyle2}
    @media (min-width: 992px) {
      ${marginBottomStyle3}
    }

    & a {
      color: ${({ theme }) => theme.palette.greenishCyan};
      text-decoration: underline;
    }
  }
`;