import styled from "styled-components";
import bgImg from 'core/assets/img/nftwp.jpg'
import { paddingStyle1, marginBottomStyle4 } from "core/styles/mixins";

export const EmptyNftWeavesWrap = styled.div`
  @media (min-width: 992px) {
    display: flex;
  }
`;

export const EmptyNftWeavesInfo = styled.div`
  background-color: ${({ theme }) => theme.palette.white};
  ${paddingStyle1}
  border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 0;
  @media (min-width: 992px) {
    width: 50%;
    padding: 50px;
    border-radius: 0 0 0 ${({ theme }) => theme.borderRadius.block};
  }

  & p {
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.paragraph};
    ${marginBottomStyle4}
  }

  & a {
    margin: auto;
    @media (min-width: 992px) {
      margin: unset;
    }
  }
`;

export const EmptyNftWeavesImg = styled.div`
  height: 172px;
  background: url(${bgImg}) no-repeat;
  border-radius: 0 0 0 ${({ theme }) => theme.borderRadius.block};
  @media (min-width: 992px) {
    width: 50%;
    height: auto;
    border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 0;
  }
`;