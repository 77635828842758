import { paddingStyle1, marginBottomStyle4 } from 'core/styles/mixins';
import styled from 'styled-components';

export const ImbuedInfoSection = styled.div`
  position: relative;
  padding-bottom: 160px;
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    margin: auto;
    padding-bottom: 170px;
  }
`;

export const ImbuedInfoWrap = styled.div`
  background: ${({ theme }) => theme.palette.gray900};
  padding: 40px 35px;
  border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 0;
  @media (min-width: 992px) {
    padding: 50px;
    border-radius: 0;
  }

  & h3 {
    padding-right: 40px;
  }

  & p {
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 22px;
    color: ${({ theme }) => theme.palette.vermilion};
    ${marginBottomStyle4}
    margin-top: 20px;
    @media (min-width: 992px) {
      margin-bottom: 0;
    }
  }
  
  & .signUpText {
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.paragraph};
    margin-bottom: 0;
  }

  & .headForm {
    @media (min-width: 992px) {
      margin-top: 20px;
    }
  }
`;

export const ImbuedVideoWrap = styled.div`
  position: relative;
  grid-column: 1 / -1;
  min-height: 414px;
  @media (min-width: 992px) {
    border-radius: 0 0 0 ${({ theme }) => theme.borderRadius.block};
    overflow: hidden;
  }

  & p {
    position: absolute;
    top: 20px;
    left: 26px;
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 22px;
    color: ${({ theme }) => theme.palette.white};
    @media(min-width: 1200px) {
      font-weight: ${({theme}) => theme.fontWeight.bold};
      font-size: 28px;
    }
  }
  & img {
    object-fit: cover;
    display: flex;
    width: 100%;
    height: 400px;
    @media (min-width: 768px) {
      height: auto;
    }
  }
  & video {
    object-fit: cover;
    display: flex;
    width: 100%;
    height: 350px;
    @media (min-width: 768px) {
      height: auto;
      min-height:600px;
    }
  }
`;

export const ImbuedInfoSlogan = styled.div`
  box-shadow: 10px 10px 15px ${({ theme }) => theme.palette.gray1000};
  border-radius: ${({ theme }) => theme.borderRadius.block} 0;
  ${paddingStyle1}
  background: ${({ theme }) => theme.palette.white};
  max-width: 342px;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 50%;
  transform: translate(50%);
  @media (min-width: 992px) {
    max-width: 545px;
    padding: 60px 40px;
  }
  & p {
    font-style: italic;
    font-weight: ${({theme}) => theme.fontWeight.light};
    font-size: 14px;
    text-align: center;
    color: ${({ theme }) => theme.palette.mainText};
    @media (min-width: 992px) {
      font-weight: ${({theme}) => theme.fontWeight.medium};
      font-size: 17px;
    }
  }
`;