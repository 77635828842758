import {StyledNavLink} from "./styles";

interface INavLinkComponent {
    url: string
    label: string
}

export const NavLinkComponent: React.FC<INavLinkComponent> = ({url, label}) => {
    return (
        <StyledNavLink exact  to={url}>
            {label}
        </StyledNavLink>
    )
}