import {
  FooterWrapper,
  FooterBottomSection,
  FooterCAIWrap,
  FooterCAI,
  FooterCAIBtns,
} from "./styles";
import { FooterNavigation } from "./components/FooterNavigation";
import { FooterSocialButtons } from "./components/FooterSocialButtons";
import { FAQ_ROUTE, NFTS } from "routing/appRoute/paths";
import { Link } from "react-router-dom";
import { ButtonAnchor } from "shared/components/ButtonAnchor";
import { ButtonLink } from "shared/components/ButtonLink";
import { SubscribeForm } from "shared/components/SubscribeForm";

export const Footer = () => {
  return (
    <FooterWrapper>
      <FooterCAIWrap className="content-wrapper">
        <FooterCAI>
          <h2>Follow the project</h2>
          <p>
            Get updates about NFT drops, exhibitions, events and other surprises.
          </p>
          <FooterCAIBtns>
            <SubscribeForm />
          </FooterCAIBtns>
        </FooterCAI>

      </FooterCAIWrap>
      <FooterBottomSection>
        {/*<FooterNavigation />*/}
        <FooterSocialButtons />
      </FooterBottomSection>
    </FooterWrapper>
  );
};
