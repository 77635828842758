import "reactjs-popup/dist/index.css";
import { StyledWalletConnectPopUp } from "./style";
import {
  injected,
  walletconnect,
} from "core/components/Header/components/connectors";
import walletConnectIco from 'core/assets/img/icons/walletConnect.svg'
import metaMaskIco from 'core/assets/img/icons/metamask.svg'
import { Button } from "../Button";
import { correctNetwork, infuraId } from "core/common";
import { providers } from "ethers";
import { useCallback, useEffect, useState, useMemo } from "react";

export const Modal = ({ context, setIsHeaderShown = null }: any) => {
  const { connector, activate, deactivate, active, error, account } = context;

  const infuraProvider = useMemo(() => new providers.InfuraProvider(correctNetwork, infuraId), []);

  const [accountId, setAccountId] = useState("");

  const getData = useCallback(async (account) => {
    if (!account) return;
    const accountENSAddress = await infuraProvider.lookupAddress(account).catch((e) => "");
    accountENSAddress && setAccountId(accountENSAddress);
  }, [infuraProvider])

  useEffect(() => {
    getData(account);
  }, [account, getData])

  function Account() {
    return (
      <>
        <span>
          {!account
            ? "Connect wallet"
            : accountId || `${account.substring(0, 5)}...${account.substring(account.length - 4)}`}
        </span>
      </>
    );
  }

  function metaMaskBtn(closePopUp: any) {
    if (!window.ethereum && window.innerWidth > 992) {
      return (
        <a href={'https://metamask.io/'} target={'_blank'}
          rel={'noreferrer noopener nofollow'}>
          <button className="connectBtn connectBtn__metamask">
            Install MetaMask
            <img src={metaMaskIco} alt="" />
          </button>
        </a>
      )
    } else if (window.ethereum) {
      return (
        <button className="connectBtn connectBtn__metamask" onClick={() => {
          activate(injected).then(() => {
            closePopUp();
            setIsHeaderShown && setIsHeaderShown(false);
          }).catch();
        }}>
          MetaMask
          <img src={metaMaskIco} alt="" />
        </button>)
    } else {
      return null;
    }

  }

  return (
    <StyledWalletConnectPopUp
      trigger={() => <span><Button>{Account()}</Button></span>}
      modal
    >
      {(close: any) => (
        <>
          <button className={"close"} onClick={close}>
            ×
          </button>
          <p className="connectTitle">Connect wallet</p>
          {metaMaskBtn(close)}
          <button className="connectBtn" onClick={() => {
            activate(walletconnect).then(() => {
              close();
              setIsHeaderShown && setIsHeaderShown(false);
            }).catch();
          }}>
            WalletConnect
            <img src={walletConnectIco} alt="" />
          </button>
          {connector === walletconnect &&
            ((error && window.location.reload()) || active) && (
              <button
                className={"disconnectBtn"}
                onClick={async () => {
                  deactivate();
                  await (connector as any).close();
                  close();
                  setIsHeaderShown && setIsHeaderShown(false);
                }}
              >
                Disconnect from WalletConnect Session
              </button>
            )}
        </>
      )}
    </StyledWalletConnectPopUp>
  );
};
