import { MyArtworkDesc, MusicLink } from "pages/MyWeaveDetailed/style";
import { ImbueArtwork } from "../ImbueArtwork";

export const ImbueLife = ({ id }) => (
  <>
    <ImbueArtwork
      id={id}
      headerText={`Imbued with Life #${id}`}
      videoId="774494875"
    >
      <MyArtworkDesc>
        <p>
          As the caretaker of an NFT Photograph, you have the power to Imbue the artwork with Life.<br/>
          You will express this in a few words, which will be stored on the blockchain, and imprinted into the visual appearence of the physical artwork.<br />
        </p>
        <p>
          Make sure to find yourself in a space where you can be alone and at peace.<br />We suggest you use headphones.<br/>
          Turn on sound and start the meditation.
        </p>
        <MusicLink>
            <p>
                Music: <a href="https://open.spotify.com/track/5hTcVE3SGZnha7RlxHQWmb?si=064d25102cd145d7">Anek totem, by Ola Claesson</a>.
            </p>
        </MusicLink>
      </MyArtworkDesc>
    </ImbueArtwork>
  </>
)