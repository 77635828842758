import { GET_ARTS, GET_WEAVES } from "./types";

export const getArts = (artData) => {
    return {
        type: GET_ARTS,
        payload: artData
    }
}

export const getWeaves = (weaveData) => {
    return {
        type: GET_WEAVES,
        payload: weaveData
    }
}