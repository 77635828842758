import {
  ImbuedInfoSection,
  ImbuedVideoWrap,
  ImbuedInfoSlogan,
} from "./style";
import videoNft from "core/assets/life-nft-720p.mp4";

export const ImbuedInfo = () => (
  <ImbuedInfoSection>

    <ImbuedVideoWrap>
      <p>Imbued with Life</p>
      <video src={videoNft} autoPlay muted playsInline loop />
    </ImbuedVideoWrap>
    <ImbuedInfoSlogan>
      <p>"Your life, our life,<br/>
      the force of life.<br/>
an unbroken thread,<br/>
beyond control,<br/>
utterly beautiful."</p>
    </ImbuedInfoSlogan>
  </ImbuedInfoSection>
)