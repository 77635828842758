import { paddingStyle1, marginBottomStyle2, marginBottomStyle4 } from "core/styles/mixins";
import styled from "styled-components";
import swipe2 from "../../../../core/assets/img/swipe2.png";

export const WavesAboutText = styled.div`
  display: flex;
  flex-direction: column;
  ${marginBottomStyle4}
  @media (max-width: 1600px) {
    ${marginBottomStyle2}
  }
  @media (max-width: 1440px) {
    ${marginBottomStyle2}
  }
  & p {
    font-size: 17px;
    color: ${({ theme }) => theme.palette.paragraph};
    @media (max-width: 1600px) {
      font-weight: ${({theme}) => theme.fontWeight.semiBold};
      font-size: 14px;
    }
    @media (max-width: 1440px) {
      font-weight: ${({theme}) => theme.fontWeight.semiBold};
      font-size: 14px;
    }
    @media (max-width: 425px) {
      font-weight: ${({theme}) => theme.fontWeight.semiBold};
      font-size: 17px;
    }
  }
`;
export const WavesAboutSection = styled.div`
  position: relative;
  @media (max-width: 425px) {
    padding-top: 40px;
  }
  & h2 {
    text-align center;
  }
`;
export const WavesAboutBg = styled.div`
  background-image: url(${swipe2});
  position: absolute;
  left: 0;
  right: 0;
  top: 19%;
  bottom: 10%;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  mix-blend-mode: overlay;
  
`;

export const WavesAboutWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.block} 0 ${({ theme }) => theme.borderRadius.block} ${({ theme }) => theme.borderRadius.block};
  background: ${({ theme }) => theme.palette.gray900};
  margin: auto;
  margin-top: ${({ theme }) => theme.contentSpacing};
  position: relative;
  z-index: 2;
  @media (max-width: 992px) {
    flex-wrap: wrap;
    border-radius: 0 ${({ theme }) => theme.borderRadius.block};
  }
`;
export const WavesAboutTextWrap = styled.div`
  padding: 4%;
  width: 50%;
  @media (max-width: 992px) {
    width: 100%;
    ${paddingStyle1}
  }
  & div:nth-last-child(1) {
    margin-bottom: 0;
  }
`;
export const WavesAboutImgWrap = styled.div`
  width: 50%;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.block};
  @media (max-width: 992px) {
    width: 100%;
    border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 0;
  }
`;
export const WavesAboutImg = styled.p<{ backgroundImg?: string }>`
  background-image: url(${(props) => props.backgroundImg});
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  border-top-left-radius: ${({ theme }) => theme.borderRadius.block};
  @media (max-width: 992px) {
    height: 514px;
    border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 0;
  }
`;
