import { paddingStyle1, marginBottomStyle2, marginBottomStyle4 } from "core/styles/mixins";
import styled from "styled-components";

export const ArtwSliderTitle = styled.div`
  text-align: center;
  margin: 40px 0 20px;
`

export const ArtwSliderText = styled.div`
    display: flex;
    flex-direction: column;
    ${marginBottomStyle4}
    @media(max-width: 1600px){
        ${marginBottomStyle2}
    }
    @media(max-width: 1440px){
        ${marginBottomStyle2}
    }
    & p{
        color: ${({ theme }) => theme.palette.paragraph};
        font-weight: ${({ theme }) => theme.fontWeight.semiBold};
        font-size: 17px;
        @media(max-width: 1600px){
            font-size: 14px;
        }
        @media(max-width: 1440px){
            font-size: 14px;
        }
        @media(max-width: 425px){
            font-size: 14px;
        }
    }
`
export const ArtwSliderSection = styled.div`
    position: relative;
    z-index: 2;
    &.content-wrapper {
        margin-bottom: 0;
    }
`

export const ArtwSliderWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 0 0 ${({ theme }) => theme.borderRadius.block} 0;
    width: 100%;
    position: relative;
    z-index:2;
    @media(min-width: 992px) {
      flex-direction: row;
    }
`
export const ArtwSliderTextWrap = styled.div`
    width:100%;
    ${paddingStyle1}
    background: ${({ theme }) => theme.palette.gray900};
    & div:nth-last-child(1) {
        margin-bottom: 0;
    }
`
export const ArtwSliderImgWrap = styled.div`
    width:100%;
    height: 514px;
    @media(min-width: 992px) {
        height: auto;
    }
`
export const ArtwSliderImg = styled.p<{ backgroundImg?: string }>`
    background-image: url(${props => props.backgroundImg});
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    border-top-left-radius: ${({ theme }) => theme.borderRadius.block};
`