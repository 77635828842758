import { TheArtworkInfo, TheArtworkWrap } from "./style";
import { ImbuedSlider } from "shared/components/Artwork/ImbuedSlider";

import { ARTWORK_LONGING } from 'routing/appRoute/paths';

import longing1 from "core/assets/img/longing1.jpg";
import longing2 from "core/assets/img/longing2.jpg";
import longing3 from "core/assets/img/longing3.jpg";
import longing4 from "core/assets/img/longing4.jpg";
import longing5 from "core/assets/img/longing4.jpg";
const longingSlider = [
  {
    img: longing1,
    link: ARTWORK_LONGING,
  },
  {
    img: longing2,
    link: ARTWORK_LONGING,
  },
  {
    img: longing3,
    link: ARTWORK_LONGING,
  },
  {
    img: longing4,
    link: ARTWORK_LONGING,
  },
  {
    img: longing5,
    link: ARTWORK_LONGING,
  }
];
export const TheArtwork = () => (
  <TheArtworkWrap>
    <TheArtworkInfo>
      <h2 className="white">The Artwork</h2>
      <p>
      Imbued with Longing is a dedication to the driving force of humanity: our ability to dream of something that does not yet exist. By staying present in the moment, while still honoring what we hope for, our longing can become a path.
      </p><p>
      The 99 photographic NFTs invite their owners to a Ritual of Longing, where they get to connect with their deepest sense of longing, and express it through words on the blockchain. The physical art piece uses these words of longing to generate the patterns flowing through the fabric.
    </p><p>
    <b>Medium:</b> Custom woven optical fiber fabric. Woven by the artist at Ekelund Weavers in Horred, Sweden. Made with a cotton warp and optical fiber, cotton and shrinking yarn in the weft. 556 addressable LEDs, controlled by a ESP32 microcontroller. Wooden frame.
    </p><p>
      <b>Dimensions:</b> 65 x 75 x 25 cm (2.1 x 2.4 x .8 ft)
      </p>
      <p>
      <b>Exhibiting:</b><br/>
      <a href="https://artwithme.org/">Art with Me</a> (Nov 26 - 27)<br/>
      <a href="https://goo.gl/maps/Du5G9JuFbVGWzvrYA">ARES Design</a> (Nov 18 - Dec 12)
      </p>
    </TheArtworkInfo>
    <ImbuedSlider imgArr={longingSlider} cls={'artworkSlider'} />
  </TheArtworkWrap>
)