import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { EffectFade, Autoplay, Navigation, Pagination } from 'swiper';
import { ImbuedSliderWrap } from "./style";
import love from "core/assets/img/slider1.jpg";
import awe from "core/assets/img/awemalin.jpg";
import life from "core/assets/img/life3.jpg";
import longing from "core/assets/img/longing4.jpg";
import friendship from "core/assets/img/friendship3.jpg";
import 'swiper/swiper.scss';
import 'swiper/modules/effect-fade/effect-fade.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';
import { ARTWORK_AWE, ARTWORK_FRIENDSHIP, ARTWORK_LIFE, ARTWORK_LOVE, ARTWORK_LONGING } from 'routing/appRoute/paths';
import { Link } from 'react-router-dom';

interface IImgArr {
  img: string;
  link: string;
}

interface IImbuedSliderProps {
  imgArr?: IImgArr[];
  cls?: string;
}

const defaultArr = [
  {
    img: love,
    link: ARTWORK_LOVE,
  },
  {
    img: awe,
    link: ARTWORK_AWE,
  },
  {
    img: life,
    link: ARTWORK_LIFE,
  },
  {
    img: longing,
    link: ARTWORK_LONGING,
  },
  {
    img: friendship,
    link: ARTWORK_FRIENDSHIP,
  }
];

export const ImbuedSlider: React.FC<IImbuedSliderProps> = ({ imgArr = defaultArr, cls = "" }) => {
  return (
    <ImbuedSliderWrap className={cls}>
      <Swiper
        modules={[EffectFade, Autoplay, Navigation, Pagination]}
        effect="fade"
        navigation
        pagination={{ clickable: true }}
        autoplay
        loop
      >
        {imgArr.map((item, index) => (
          <SwiperSlide className={'swiper-slide'} key={index}>
            <Link to={item.link}>
              <img src={item.img} alt="slider state" />
            </Link>
          </SwiperSlide>
        )
        )}
      </Swiper>
    </ImbuedSliderWrap>
  )
}
