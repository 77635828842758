import { FC } from "react";
import { EmailEditPopUp } from "../EmailEditPopUp";
import { AccountInfoWrap } from "./style";

interface IAccountInfoProps {
  email: string;
  onEmailEdit: (email: string) => void;
}

export const EmailInfo: FC<IAccountInfoProps> = ({ email, onEmailEdit }) => {
  return (
    <AccountInfoWrap>
      <h4>Email registered</h4>
      <EmailEditPopUp email={email} onEdit={onEmailEdit} />
    </AccountInfoWrap>
  )
}
