import styled from "styled-components";

export const DrawerInputWrapper = styled.div<{
  width?: number;
  margin?: string;
}>`
  padding-bottom: 4px;
  border-bottom: 2px solid ${(props) => props.theme.palette.paragraph};
  width: ${(props) => (props.width ? props.width : "100%")};
  position: relative;
  overflow: hidden;
  margin: ${(props) => props.margin};
  font-weight: ${({theme}) => theme.fontWeight.bold};
  font-size: 28px;
  color: ${({ theme }) => theme.palette.mainText};
`;

export const Triangle = styled.span`
  width: 0;
  height: 0;
  border-width: 5px 5px 0 5px;
  border-radius: 40%;
  border-color: ${({ theme }) => theme.palette.black} transparent transparent transparent;
  border-style: solid;
`;

export const FrontView = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
  & span {
    font-weight: ${({theme}) => theme.fontWeight.bold};
    font-size: 28px;
    color: ${({ theme }) => theme.palette.mainText};
    @media (max-width: 425px) {
      font-weight: ${({theme}) => theme.fontWeight.semiBold};
      font-size: 22px;
      color: ${({ theme }) => theme.palette.mainText};
    }
  }
`;

export const CollapsedSection = styled.div<{ isCollapsed: boolean }>`
  max-height: 0;
  overflow: hidden;
  font-weight: ${({theme}) => theme.fontWeight.semiBold};
  font-size: 17px;
  color: ${({ theme }) => theme.palette.paragraph};
  ${(props) =>
    !props.isCollapsed &&
    `{
        max-height: max-content;
        transition: max-height .6s;
        @media(max-width: 420px){
          padding-top: 20px;
          padding-bottom: 20px;
        }
    }`}
`;
