import { Route, Switch } from "react-router-dom";
import { PageNotFound } from "pages/PageNotFound";

interface IRoute {
  path: string;
  key: string;
  component: React.FunctionComponent<any>;
  exact?: boolean;
}

interface IRenderRoutes extends IRoute {
  routes?: IRoute[];
}

export const RouteWithSubRoutes = (route: IRenderRoutes) => {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
};

export const RenderRoutes = ({ routes }: { routes: IRenderRoutes[] }) => {
  return (
    <Switch>
      {routes.map((route: IRenderRoutes) => {
        return <RouteWithSubRoutes {...route} />;
      })}
      <Route component={() => <PageNotFound />} />
    </Switch>
  );
};

