import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  z-index: 2;
`;

export const BottomSlider = styled.div`
  margin-top: ${({ theme }) => theme.contentSpacing};
  
  & h3 {
    display: none;
    @media(min-width: 992px) {
      display: block;
      text-align: center;
    }
  }
  
  & .BottomSlider {
    border-radius: 0;
    @media(min-width: 992px) {
      border-radius: 0 0 0 ${({ theme }) => theme.borderRadius.block};
    }
  }
`;