import { useEffect } from "react";
import { HeaderWrapper } from "shared/components/HeaderWrapper";
import { ArtwListSec } from "./components/ArtwListSec";
import { ArtwSlider } from "./components/ArtwSlider";
import { ArtwStory } from "./components/ArtwStory";
import { ArtworksWrapper } from "./styles";

export const Artworks = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <ArtworksWrapper >
            <HeaderWrapper className="content-wrapper">
                <h1>The Artworks</h1>
            </HeaderWrapper>
            <ArtwStory />
            <ArtwSlider />
            <ArtwListSec />
        </ArtworksWrapper>
    )
}