import { YourArtworksWrap } from "./style";
import { Spinner } from "core/components/Spinner";
import { LoadingNftWeaves } from "../YourNftWeaves/style";
import { DownloadedArtworks } from "./DownloadedArtworks";
import { theme } from "core/styles/theme";

export const YourArtworks = ({ artData, loading }) => {
  return (
    <YourArtworksWrap>
      {loading ?
        <LoadingNftWeaves>
          <p>Loading your artworks</p>
          <Spinner color={theme.palette.mainText} />
        </LoadingNftWeaves> :
        (!artData.length ?
          null :
          (artData.map((item) => {
            return (
              <DownloadedArtworks key={item.id} item={item} />
            )
          }))
        )
      }
    </YourArtworksWrap>
  )
}