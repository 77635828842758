import {
  ImbuedInfoSection,
  ImbuedVideoWrap,
  ImbuedInfoSlogan,
} from "./style";
import videoNft from "core/assets/LOVENFT.mp4";

export const ImbuedInfo = () => (
  <ImbuedInfoSection>

    <ImbuedVideoWrap>
      <p>Imbued with Love</p>
      <video src={videoNft} autoPlay muted playsInline loop />
    </ImbuedVideoWrap>
    <ImbuedInfoSlogan>
      <p>”Your umbrella is a treasure<br />
        It will keep me dry<br />
        I bring it with me everywhere I go<br />
        The sense of being held<br />
        The ease of falling<br />
        Being caught again”</p>
    </ImbuedInfoSlogan>
  </ImbuedInfoSection>
)