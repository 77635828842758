import { TttDesc, TttIntro, TttWrap, TttVideo } from "./styles";
import videoNft from 'core/assets/imbuedweb.mp4'
import { SubscribeForm } from "shared/components/SubscribeForm";

export const ThreadToToken = () => {
  return (
    <TttWrap className="content-wrapper">
      <TttVideo>
        <video src={videoNft} autoPlay muted playsInline loop />
      </TttVideo>
      <TttIntro>
        <p>Art imbued with emotion</p>
        <h3>Imbued - Thread&nbsp;to&nbsp;token</h3>
        <p>Physical art controlled by NFTs</p>
        <SubscribeForm />
      </TttIntro>
      <TttDesc>
        <p>
          <b>Imbued</b> is a series of optical fibre textile artworks, connected in real-time, via <b>Wi-Fi</b>, to the blockchain.<br />Each artwork comes with a set of <b>NFTs</b>, which powers a ritual where people across the world share their emotions on the blockchain itself. These words then drive the changing color and light, flowing through the artworks.
        </p>
      </TttDesc>
    </TttWrap>
  );
};
