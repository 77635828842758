import { paddingStyle1, marginBottomStyle2, marginBottomStyle4 } from "core/styles/mixins";
import styled from "styled-components";

export const DownloadedNftWeavesSection = styled.div`
  &:not(:last-of-type) {
    ${marginBottomStyle4}
  }

  @media (min-width: 992px) {
    display: flex;
  }
`;

export const NftWeavesInfo = styled.div`
  ${paddingStyle1}
  background: ${({ theme }) => theme.palette.white};
  border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 0;

  @media (min-width: 992px) {
    width: 50%;
    border-radius: 0 0 0 ${({ theme }) => theme.borderRadius.block};
    padding: 50px;
  }

  & .itemName {
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 22px;
    color: ${({ theme }) => theme.palette.mainText};
    ${marginBottomStyle2}
    @media (min-width: 992px) {
      font-weight: ${({theme}) => theme.fontWeight.bold};
      font-size: 28px;
    }
  }

  & .itemComment {
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.paragraph};
    ${marginBottomStyle4}
  }
`;

export const NftWeavesInfoBtns = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & .button-link, .button {
    margin: 10px 0;
  }
`;

export const NftWeavesImg = styled.div`
  border-radius: 0 0 0 ${({ theme }) => theme.borderRadius.block};
  overflow: hidden;
  @media (min-width: 992px) {
    width: 50%;
    border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 0;
  }

  & img {
    height: 270px;
    width: 100%;
    object-fit: cover;
    @media (min-width: 992px) {
      height: 100%;
    }
  }
`;