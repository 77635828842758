import styled from "styled-components";
import tactile_pattern from "core/assets/img/tactile_pattern.png";
import { marginBottomStyle4 } from "core/styles/mixins";

export const HeaderWrapper = styled.header`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 30px 0 30px;
  margin-bottom: 24vw;
  z-index: 5;

  @media (max-width: 630px) {
    margin-bottom: 280px;
  }

  @media (max-width: 500px) {
    margin-bottom: 260px;
  }

  @media (min-width: 1200px) {
    margin-bottom: 180px;
  }

  @media (min-width: 1500px) {
    margin-bottom: 185px;
  }

  @media(min-width: 1800px){
    margin-bottom: 200px;
  }

  @media(min-width: 2000px){
    margin-bottom: 220px;
  }

  #burger {
    display: none;
  }

  @media (max-width: 630px) {
    padding: 0;
    #burger {
      display: flex;
    }
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 25px;
  align-items: center;
  border-radius: 8px;
  height: 100%;
  @media (max-width: 630px) {
    flex-direction: column;
    display: none;
    height: 100vh;
  }
  & .active {
    cursor: pointer;
  }
  & .disabled {
    background: ${({ theme }) => theme.palette.gray500};
  }
`;

export const HeaderNav = styled.nav`
  margin-right: 10px;
  padding: 14px 40px;
  border-radius: 31px;
  background: ${({ theme }) => theme.palette.gray900};
  z-index: 2;
  & ul {
    display: flex;
    justify-content: space-evenly;
    @media (max-width: 630px) {
      flex-direction: column;
    }
    li {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      font-size: 17px;
      color: ${({ theme }) => theme.palette.mainText};
      @media (min-width: 1281px) and (max-width: 1440px) {
        font-size: 15px;
        line-height: 29px;
      }
    }
    li:not(:last-child) a {
      margin-right: 30px;
    }
  }
`;

export const HeaderNavMob = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 25px;
  align-items: center;
  height: 100%;
  background-image: linear-gradient(${({ theme }) => theme.palette.gray900},
  ${({ theme }) => theme.palette.gray900}),
  url(${tactile_pattern});
  border-radius: 0 0 0 64px;
  image-rendering: pixelated;

  & ul {
    width: 100%;
    margin-top: 40%;
  }

  & ul li {
    ${marginBottomStyle4}
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 28px;
    color: ${({ theme }) => theme.palette.paragraph};
    &:last-child {
      font-size: 19px;
      line-height: 26px;
    }
  }
  & ul li a {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: 28px;
    color: ${({ theme }) => theme.palette.paragraph} !important;
  }
  @media (max-width: 425px) {
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 4;
    left: 10%;
    height: auto;
    padding-top: 36px;
  }
`;
export const HeaderNavMobBg = styled.div`
  position: fixed;
  background: #000000be;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  @media (max-width: 425px) {
    display: flex;
  }
`;