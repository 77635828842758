export const theme = {
  mainFontFamily: "'Montserrat', sans-serif",
  contentWidth: '73%',
  contentSpacing: '60px',
  palette: {
    background: "#f4f5f5",
    mainText: "#484040",
    secondary: "#817291",
    paragraph: "#808080",

    white: '#ffffff',
    black: '#000000',

    greenishCyan: '#59a79a',
    vermilion: '#f34727',

    gray100: '#f4f5f5c9',
    gray200: '#e6e6e6',
    gray300: '#e2e2e2',
    gray400: '#cfcece',
    gray500: '#a7a3ab',
    gray600: '#868686',
    gray700: '#5b5b5b',
    gray800: '#494040',
    gray900: '#ffffffbf',
    gray1000: '#0000000d',
  },
  fontWeight: {
    light: 300,
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700
  },
  borderRadius: {
    default: "25px",
    block: "80px",
    button: "10px",
    small: "5px",
    round: "50%"
  },
};