import {
  HeaderContent,
  HeaderNav,
  HeaderNavMob,
  HeaderNavMobBg,
  HeaderWrapper,
} from "./styles";
import { NavLinkComponent } from "shared/components/NavLink";
import {
  ARTWORKS_ROUTE,
  DEFAULT_ROUTE,
  FAQ_ROUTE,
  NFTS,
  MY_WALLET,
} from "routing/appRoute/paths";
import { ToggleBurger } from "./components/ToggleBurger";
import { useEffect, useRef, useState } from "react";
import { Modal } from "shared/components/WalletPopUp";
import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

function useOnClickOutside(ref: any, handler: any) {
  useEffect(() => {
    const listener = (event: any) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

export const Header = () => {
  const context = useWeb3React<Web3Provider>();
  const ref = useRef();
  const [isHeaderShown, setIsHeaderShown] = useState<boolean>(false);
  useOnClickOutside(ref, () => setIsHeaderShown(false));

  return (
    <HeaderWrapper>
      <HeaderContent>
        <HeaderNav>
          <ul>
            <li>
              <NavLinkComponent url={DEFAULT_ROUTE} label="Imbued" />
            </li>
            <li>
              <NavLinkComponent url={ARTWORKS_ROUTE} label="Artworks" />
            </li>
            <li>
              <NavLinkComponent url={NFTS} label="NFTs" />
            </li>
            <li>
              <NavLinkComponent url={MY_WALLET} label="My Wallet" />
            </li>
            <li>
              <NavLinkComponent url={FAQ_ROUTE} label="FAQ" />
            </li>
          </ul>
        </HeaderNav>
        <Modal context={context} setIsHeaderShown={setIsHeaderShown} />
      </HeaderContent>
      {isHeaderShown ? (
        <HeaderNavMobBg
          ref={ref.current}
          onClick={() => setIsHeaderShown(false)}
        >
          <HeaderNavMob>
            <ul>
              <li>
                <NavLinkComponent url={DEFAULT_ROUTE} label="Imbued" />
              </li>
              <li>
                <NavLinkComponent url={ARTWORKS_ROUTE} label="Artworks" />
              </li>
              <li>
                <NavLinkComponent url={NFTS} label="NFTs" />
              </li>
              <li>
                <NavLinkComponent url={MY_WALLET} label="My Wallet" />
              </li>
              <li>
                <NavLinkComponent url={FAQ_ROUTE} label="FAQ" />
              </li>
              <li>
                <Modal context={context} setIsHeaderShown={setIsHeaderShown} />
              </li>
            </ul>
          </HeaderNavMob>
        </HeaderNavMobBg>
      ) : null}
      <ToggleBurger onClick={() => setIsHeaderShown(!isHeaderShown)} />
    </HeaderWrapper>
  );
};
