import styled from "styled-components";

export const MyArtworkDesc = styled.div`
  padding: 40px;
  background: ${({ theme }) => theme.palette.gray900};
  & p {
    margin: 0 0 15px 0;
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.paragraph};
  }
`;

export const MusicLink = styled.div`
  & p {
    margin: 0 0 15px 0;
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.paragraph};
  }
  & a {
    color: ${({ theme }) => theme.palette.paragraph};
  }
`;