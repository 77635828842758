import React from "react";
import { Button } from "./styles";

interface IButtonLink {
    to: string,
    disabled?: boolean;
    className?: string;
}

export const ButtonLink: React.FC<IButtonLink> = ({ to, className = "", disabled = false, children }) => {
    return (
        <Button
            className={`button-link ${className} ${disabled ? 'disabled' : ''}`}
            to={to}
        >
            {children}
        </Button>
    )
}
