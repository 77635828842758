import styled from "styled-components";
import portrait_mob from "core/assets/img/portrait_mob.png";
import { paddingStyle1, marginBottomStyle2 } from "core/styles/mixins";

export const WeavesDescWrap = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 992px) {
    flex-direction: row;
    width: 80%;
    margin: auto;
  }
`;

export const WavesIntroDesc = styled.div`
  background: ${({ theme }) => theme.palette.white};
  border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 0;
  ${paddingStyle1}
  padding-right: 33px;
  @media (min-width: 992px) {
    border-radius: 0 0 0 ${({ theme }) => theme.borderRadius.block};
    padding: 50px;
    width: 60%;
  }

  & .slogan {
    font-style: italic;
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.paragraph};
    ${marginBottomStyle2}
    @media (min-width: 992px) {
      font-size: 28px;
    }
  }

  & .name {
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.secondary};
  }
`;

export const WeavesImg = styled.div`
  background: url(${portrait_mob}) no-repeat;
  background-size: cover;
  height: 414px;
  width: 100%;
  @media (min-width: 992px) {
    height: auto;
    width: 40%;
    border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 0;
  }
`;

export const WavesIntroWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  z-index: 3;
 
`;
