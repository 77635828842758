import { SubscribeForm } from "shared/components/SubscribeForm";
import {
  ImbuedInfoSection,
  ImbuedInfoWrap,
  ImbuedVideoWrap,
  ImbuedInfoSlogan,
  ImbuedOnSale
} from "./style";
import aweMain from 'core/assets/img/awebrick.jpg';
import { ButtonAnchor } from "shared/components/ButtonAnchor";

export const ImbuedInfo = () => (
  <ImbuedInfoSection>
    <ImbuedInfoWrap>
      <h2>Imbued with Appreciation</h2>
      <p className={'signUpText'}>Sign up for our latest updates:</p>
      <SubscribeForm />
    </ImbuedInfoWrap>
    <ImbuedOnSale>
      <h2 className="white">On sale now</h2>
      <p>Price: $45,000 USD / 17.5 ETH</p>
      <ButtonAnchor
        href="https://opensea.io/assets/0x000001e1b2b5f9825f4d50bd4906aff2f298af4e/100"
        rel={'noopener noreferrer nofollow'}
      >
        Buy now on OpenSea
      </ButtonAnchor>
      <p>Or <a className={"link"} href="mailto:info@imbuedart.com">email</a> us for concierge service</p>
    </ImbuedOnSale>
    <ImbuedVideoWrap>
      <p>2. Imbued with Awe</p>
      <img src={aweMain} alt="nft" />
    </ImbuedVideoWrap>
    <ImbuedInfoSlogan>
      <p><p>"It's like a superpower, flicking the switch in my mind,<br />
        Experiencing familiar things, as if it were the first time.<br />
        A beautiful flower,<br />
        The waves crashing on the shore,<br />
        The eternity of my children's eyes,<br />
        The sensation is breaking my body open like an egg,<br />
        Making my inside melt out on the ground."</p></p>
    </ImbuedInfoSlogan>
  </ImbuedInfoSection>
)