import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  margin: 60px 0;
`;

export const PageNotFound = () => <Wrapper><h1>Page not found!</h1></Wrapper>;

