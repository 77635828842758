import { MyArtworkDesc, MusicLink } from "pages/MyWeaveDetailed/style";
import { ImbueArtwork } from "../ImbueArtwork";

export const ImbueLonging = ({ id }) => (
  <>
    <ImbueArtwork
      id={id}
      headerText={`Imbued with Longing #${id}`}
      videoId="774496017"
    >
      <MyArtworkDesc>
        <p>
          As the caretaker of an NFT Photograph, you have the power to Imbue the Weave with your own interpretation of Love. You will express this in a few words, which will be stored on the blockchain, and imprinted into the visual appearence of the physical artwork.<br />
          Each caretaker may only do this once, and the words with be a part of the artwork forever.
        </p>
        <p>
          Make sure to find yourself in a space where you can be alone and at peace.<br />We suggest you use headphones.<br/>
          Turn on sound and start the meditation.
        </p>
        <MusicLink>
            <p>
                Music: <a href="https://open.spotify.com/track/64r8lmV7bUD0CBkFoL6E5b?si=6470d6154168434a">Until Forever, by Ola Claesson</a>.
            </p>
        </MusicLink>
        
      </MyArtworkDesc>
    </ImbueArtwork>
  </>
)