import styled from "styled-components";
import to_be_announced from "../../../../core/assets/img/to_be_announced.png";
import swipebg2 from "../../../../core/assets/img/swipebg2.png";
import { paddingStyle1, marginBottomStyle4 } from "core/styles/mixins";


export const ArtwListSecHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${marginBottomStyle4}
  padding: 0 36px;
  @media(min-width: 1200px) {
    padding: 0;
  }
  & p{
      font-weight: ${({ theme }) => theme.fontWeight.semiBold};
      font-size: 17px;
      color: ${({ theme }) => theme.palette.mainText};
  }
`

export const ArtwListSecWrap = styled.div`
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-top: 150px;
  padding-bottom: 104px;
  position: relative;
  z-index: 2;

  & .disabled {
    pointer-events: none;
  }
`
export const ArtwListSecSection = styled.div`
  background-image: url(${swipebg2});
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  background-position: center left;
`
export const ArtwSectionMask = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background-color: ${({ theme }) => theme.palette.gray100};
`
export const ArtwListSecCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;

  .highlight {
    color: ${({ theme }) => theme.palette.vermilion};
  }

  @media(min-width: 992px) {
    flex-direction: row;
  }
`

export const CardImg = styled.div<{ src?: string, height?: string }>`
  background-image: url(${props => props.src || to_be_announced});
  background-size: cover;
  background-position: center;
  width: 100%;
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius.block} 0 0 0;
  height: ${props => props.height || 'auto'};
  @media(max-width: 992px) {
    min-height: 185px;
  }
  @media(min-width: 992px) {
    width: 60%;
    min-height: ${props => props.height || 'auto'};
    height: auto;
  }
`

export const ArtwListSecCardText = styled.div`
  width: 100%;
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.block};
  background: ${({ theme }) => theme.palette.mainText};
  ${paddingStyle1}
  @media(min-width: 992px) {
    width: 40%;
  }

  & p{
    color: ${({ theme }) => theme.palette.white};
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: 16px;
    padding: 10px 0;
  }
  & a{
    color: ${({ theme }) => theme.palette.secondary};
    text-decoration: underline;
  }
  & .sold {
    text-decoration: line-through red;
  }
`

export const CountLabel = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: 28px;
  color: ${({ theme }) => theme.palette.vermilion};
  position: absolute;
  top: 30px;
  left: 30px;
  background: ${({ theme }) => theme.palette.white};
  box-shadow: 0 3px 6px #00000029;
  width:70px;
  height: 70px;
  text-align: center;
  border-radius: ${({ theme }) => theme.borderRadius.round};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ArtwListSecCardList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .button {
    margin-top: ${({ theme }) => theme.contentSpacing};
  }
`

export const ArtwCardListImgText = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: 22px;
  color: ${({ theme }) => theme.palette.white};
  position: absolute;
  bottom: 20px;
  right: 50px;
  @media(min-width: 1281px) and (max-width: 1440px){
    bottom: 10px;
    right: 40px;
  }
` 