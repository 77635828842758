import {Swiper, SwiperSlide} from 'swiper/react/swiper-react';
import {EffectFade, Autoplay, Navigation, Pagination} from 'swiper';
import {AweSliderWrap} from "./style";
import slider1 from "core/assets/img/awebrick.jpg";
import slider2 from "core/assets/img/awe1.jpg";
import slider3 from "core/assets/img/awe2.jpg";
import slider4 from "core/assets/img/awe4.jpg";
import 'swiper/swiper.scss';
import 'swiper/modules/effect-fade/effect-fade.scss';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/pagination/pagination.scss';

export const AweSlider = (props) => {
  let images: string[];
  const defaultImages: string[] = [slider1, slider2, slider3, slider4]
  props.imgArr ? images = props.imgArr : images = defaultImages;


  return (
    <AweSliderWrap className={props.cls}>
      <Swiper
        modules={[EffectFade, Autoplay, Navigation, Pagination]}
        effect="fade"
        navigation
        pagination={{clickable: true}}
        autoplay
        loop
      >
        {images.map((item, index) => {
          return (
            <SwiperSlide className={'swiper-slide'} key={index}>
              <img src={item} alt="slider state"/>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </AweSliderWrap>
  )
}