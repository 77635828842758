import {
  GridInfo,
  GridInfoBtns,
  GridClickText,
  GridInfoText,
  GridSectionWrap,
} from "./style";

import { ButtonAnchor } from "shared/components/ButtonAnchor";
import { ButtonLink } from "shared/components/ButtonLink";

import { GridWrapper } from "shared/components/Artwork/GridWrapper";

import NFTExample from 'core/assets/img/nft-love.jpg'


interface IGridSectionProps {
  from: number;
  to: number;
  image: string;
}

export const GridSection: React.FC<IGridSectionProps> = ({ from, to, image }) => (
  <GridSectionWrap>
    <GridInfo>
      <GridInfoText>
        <h2>The 99 Photo NFTs</h2>
        <p>Imbued with Life is connected to 99 unique photographic NFTs. By owning an NFT, you get invited to enter a Love Meditation, where you get to dedicate your love. Your interpretation of this ritual will be imprinted as words on the blockchain, and mold the light patterns of the physical artwork.</p>
                <p>
          Each NFT also includes the right to a 50x50cm photo print on Hahnemuhle Studio Enhanced 210gsm Fine Art Paper and framed in a black 20×30mm wooden frame with anti-reflective art glass.<br/>
          A proof of authenticity, signed by the artist, is attached to the print.<br/>Shipping costs apply.
        </p>

        <p>
          Do you own an NFT?<br/>Go through the Imbuing ritual now, or claim your print, by logging in and clicking "My Wallet" in the menu.
        </p>
        <ButtonLink to={'/digital-weave'}>Read about our NFTs</ButtonLink>
        <br/>
</GridInfoText>
      <GridInfoBtns>
      <img src={NFTExample} alt={'Imbued with Love NFT #1'}/>
      Imbued with Love NFT #1
        <ButtonAnchor href={'https://opensea.io/collection/imbued-art?search[stringTraits][0][name]=Emotion&search[stringTraits][0][values][0]=Love'}>
          View all on OpenSea
        </ButtonAnchor>
        <ButtonAnchor href={'https://www.tadaa.se/product-category/love/'}>
          Buy in webshop
        </ButtonAnchor>
      </GridInfoBtns>
    </GridInfo>
{/*
    <GridClickText>
      <p className={'mobileInfo'}>Flip your phone sideways</p>
      <div className={'desktopInfo'}>
        <p>Click on a part of the weave<br />to see current owner<br />and imbued words</p>
        <span />
      </div>
    </GridClickText>
    <GridWrapper from={from} to={to} image={image} />*/}  </GridSectionWrap>
)