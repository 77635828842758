import { GridSloganWrap } from "./style";

export const GridSlogan = () => (
  <GridSloganWrap>
    <p>
      "The most beautiful thing we can experience is the mysterious.<br />
      It is the source of <b>all true art</b> and science.<br />
      She to whom the emotion is a stranger,<br />
      who can no longer pause to wonder and stand <b>rapt in awe,</b><br />
      is as good as dead; her eyes are closed."<br />
      - Albert Einstein
    </p>
  </GridSloganWrap>
)