import { ButtonAnchor } from "shared/components/ButtonAnchor";
import { SubscribeForm } from "shared/components/SubscribeForm";

import {
  WavesInterImg,
  WavesInterSection,
  WavesInterText,
  WavesInterWrap,
} from "./styles";

export const NFTHowToBuy = () => {
  return (
    <WavesInterSection className="content-wrapper">
      <WavesInterWrap>
        <WavesInterText>
        <h3>Buying an NFT</h3>
          <p>
            The creation of the NFTs will be distributed over a number
            of drops, where we release a number of weaves at a designated
            time to be bought on this web page.<br />
            <br />
            Sign up to our email list to get notified.
            <SubscribeForm />
            For completed mints, the NFTs are tradable on OpenSea and other NFT marketplaces.
          </p>
          <ButtonAnchor href={'https://opensea.io/collection/imbued-art'}>Buy NFT on OpenSea</ButtonAnchor>

        </WavesInterText>
        <WavesInterImg>
          <p />
        </WavesInterImg>
      </WavesInterWrap>
    </WavesInterSection>
  );
};
