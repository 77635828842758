import { marginBottomStyle2, paddingStyle1 } from "core/styles/mixins";
import styled from "styled-components";

import ethIcon from 'core/assets/img/icons/Ethereum-Icon-Purple-Logo.svg'

export const OwnedByWrap = styled.div`
  ${paddingStyle1}
  background: ${({ theme }) => theme.palette.white};
  max-width: 632px;
  margin: ${({ theme }) => theme.contentSpacing} auto 0;
  border-radius: 0 ${({ theme }) => theme.borderRadius.block};
  box-shadow: 0 20px 35px ${({ theme }) => theme.palette.gray1000};

  & .button-anchor {
    margin: 0 auto;
  }
`;

export const LoadingNftData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
`;

export const BidInfo = styled.div`
  margin-bottom: 45px;

  & .bidLabel {
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.mainText};
    margin-bottom: 5px;
  }

  & .bidInfo {
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.paragraph};
    word-wrap: break-word;

    &:not(:last-of-type) {
      ${marginBottomStyle2}
    }
  }

  & a {
    color: ${({ theme }) => theme.palette.secondary};
  }
`;

export const CurrentBidAmount = styled.div`
  display: flex;
  ${marginBottomStyle2}
  align-items: center;

  & span {
    display: block;
    width: 18px;
    height: 26px;
    background: url(${ethIcon}) no-repeat;
    margin-right: 10px;
  }
`;