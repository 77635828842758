import React from "react";
import ReactDOM from "react-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { composeWithDevTools } from "@redux-devtools/extension";
import { BrowserRouter } from "react-router-dom";
import { MetaMaskProvider } from "metamask-react";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import App from "./App";
import { MainLayout } from "./core/MainLayout";
import { ThemeProvider } from "styled-components";
import { theme } from "./core/styles/theme";
import { rootReducer } from "./redux/rootReducer";
import GlobalStyles from "./core/styles/global.styles";
import "./core/styles/reset.styles.css";

require("dotenv").config();

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

declare global {
  interface Window {
    ethereum: any;
    dataLayer: any;
  }
}

const store = createStore(rootReducer, composeWithDevTools());

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Web3ReactProvider getLibrary={getLibrary}>
          <MetaMaskProvider>
            <Provider store={store}>
              <MainLayout>
                <App />
              </MainLayout>
            </Provider>
          </MetaMaskProvider>
        </Web3ReactProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);