import styled from "styled-components";
import Popup from "reactjs-popup";

export const StyledWalletConnectPopUp = styled(Popup)`
  &-content {
    width: 415px;
    border-radius: 0 ${({ theme }) => theme.borderRadius.block};
    background: ${({ theme }) => theme.palette.white};
    padding: 1.5rem 1.5rem 3.5rem;
    position: relative;

    & .close {
      cursor: pointer;
      position: absolute;
      display: block;
      padding: 2px 5px;
      right: 0;
      top: 0;
      font-size: 24px;
      line-height: 20px;
      color: ${({ theme }) => theme.palette.white};
      background: ${({ theme }) => theme.palette.gray700};
      border-radius: 18px;
      border: 1px solid ${({ theme }) => theme.palette.gray400};
    }

    & .connectTitle {
      font-weight: ${({ theme }) => theme.fontWeight.semiBold};
      font-size: 22px;
      color: ${({ theme }) => theme.palette.paragraph};
      margin: 1.5rem 0;
      text-align: center;
    }

    & .connectBtn {
      font-weight: ${({ theme }) => theme.fontWeight.semiBold};
      font-size: 17px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 1rem;
      margin-top: 1rem;
      background: ${({ theme }) => theme.palette.gray300};
      border-radius: 15px;
      border: 1px solid ${({ theme }) => theme.palette.white};
      cursor: pointer;
      color: ${({ theme }) => theme.palette.mainText};
      @media (min-width: 992px) {
        &:hover {
          border: 1px solid ${({ theme }) => theme.palette.secondary};
        }
      }
      &__metamask {
        display: flex;
        & a {
          color: ${({ theme }) => theme.palette.mainText};
        }
      }
      & img {
        width: 24px;
        height: 24px;
      }
    }

    & .errorMessage {
      margin-top: 1rem;
      font-weight: ${({ theme }) => theme.fontWeight.semiBold};
      font-size: 17px;
      color: ${({ theme }) => theme.palette.paragraph};
    }

    & .disconnectBtn {
      width: 100%;
      text-align: center;
      margin: 1em auto 0;
      padding: 1em;
      border-radius: ${({ theme }) => theme.borderRadius.button};
      cursor: pointer;
      color: ${({ theme }) => theme.palette.red200};
    }
  }
`;
