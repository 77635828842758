import {
  CollapsedSection,
  DrawerInputWrapper,
  FrontView,
  Triangle,
} from "./styles";
import { useEffect, useState } from "react";

interface IDrawerInput {
  label: string;
  onClick?: () => void;
  margin?: string;
  text?: string;
  id?: string;
}

export const FAQInput: React.FC<IDrawerInput> = ({
  id,
  label,
  margin,
  text,
  children,
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DrawerInputWrapper id={id} margin={margin}>
      <FrontView onClick={() => setIsCollapsed(!isCollapsed)}>
        <span>{label}</span>
        <Triangle />
      </FrontView>
      <CollapsedSection isCollapsed={isCollapsed}>
        {text
          ? text
          : children
          ? children
          : "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ut, veritatis."}
      </CollapsedSection>
    </DrawerInputWrapper>
  );
};
