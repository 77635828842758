import { useEffect } from "react";
import { MintNftWrapper } from "./style";
import { HeaderWrapper } from "shared/components/HeaderWrapper";
import { ImbuedWithFriendship } from "./components/ImbuedWithFriendship";

export const MintFriendship = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MintNftWrapper className="content-wrapper">
      <ImbuedWithFriendship />
    </MintNftWrapper>
  );
};
