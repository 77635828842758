import { useEffect } from "react";
import { ImbuedWithLonging } from "./components/ImbuedWithLonging";
import { MintNftWrapper } from "./style";
import { HeaderWrapper } from "shared/components/HeaderWrapper";

export const MintLonging = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MintNftWrapper className="content-wrapper">
      <HeaderWrapper>
        <h1>Enter the Ritual, mint your NFT</h1>
      </HeaderWrapper>
      <ImbuedWithLonging />
    </MintNftWrapper>
  );
};
