import styled from "styled-components";
import badw from "../../../../core/assets/img/badw.png";
import weavecloseup from "core/assets/img/nft-love.jpg";
import pcard1 from "../../../../core/assets/img/pcard1.svg";
import pcard2 from "../../../../core/assets/img/pcard2.svg";
import { paddingStyle1} from "core/styles/mixins";


export const NftWavesImg = styled.div`
    width:50%;
    @media(max-width: 1024px){
        width: 100%;
        height: 414px;
    }
    & div{
        background: url(${weavecloseup}) no-repeat center center;
        width: 100%;
        height: 100%;
        background-size: cover;
        border-radius: 0 0 0 ${({ theme }) => theme.borderRadius.block};
        image-rendering: pixelated;
        @media(min-width: 1025px){
          background-image: url(${weavecloseup});
          border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 0;
        }
    }
`
export const NtfWavesWrap = styled.div`
    &.content-wrapper {
        @media (max-width: 1024px) {
            width: 100%;
        }
        @media (min-width: 992px) {
            max-width: 57%;
          }
    }
    width:57%;
    display: flex;
    flex-direction: row;
    position: relative;
    @media(max-width: 1024px){
        width:100%;
        flex-wrap: wrap;
    }
    @media(max-width: 425px){
        width:100%;
        flex-wrap: wrap;
    }
    ::before{
        content: "";
        display: inline-block;
        background-image: url(${pcard1});
        width: 260px;
        height: 260px;
        background-size: cover;
        margin-top:40px;
        position: absolute;
        left: -275px;
        top: 0;
        @media(min-width: 1281px) and (max-width: 1440px){
            width: 210px;
            height: 210px;
            background-size: cover;
            position: absolute;
            left: -220px;
            top: 0;
        }
        @media(min-width: 1025px) and (max-width: 1280px) {
            width: 200px;
            height: 200px;
            left: -210px;
        }
        @media(max-width: 1024px){
            display: none;
        }
        @media(max-width: 425px){
            display: none;
        }
    }
    ::after{
        content: "";
        display: inline-block;
        background-image: url(${pcard2});
        width: 260px;
        height: 260px;
        background-size: cover;
        position: absolute;
        right: -275px;
        bottom: 0;
        @media(min-width: 1281px) and (max-width: 1440px){
            width: 210px;
            height: 210px;
            background-size: cover;
            position: absolute;
            right: -220px;
        }
        @media(min-width: 1025px) and (max-width: 1280px) {
            width: 200px;
            height: 200px;
            right: -210px;
        }
        @media(max-width: 1024px){
            display: none;
        }
        @media(max-width: 425px){
            display: none;
        }
    }

    .button-link {
        margin: 23px auto 0;
        background: ${({ theme }) => theme.palette.white};
        color: ${({ theme }) => theme.palette.mainText};
    }
`

export const NftWavesText = styled.div`
    width:50%;
    padding: 4%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${badw});
    background-size: cover;
    border-radius: 0 0 0 ${({ theme }) => theme.borderRadius.block};
    image-rendering: pixelated;
    @media(min-width: 1281px) and (max-width: 1440px){
        padding: 4%;
    }
    @media(max-width: 1024px){
        width: 100%;
        border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 0;
    }
    @media(max-width: 992px){
        width: 100%;
        ${paddingStyle1}
    }
    & p{
        font-weight: ${({theme}) => theme.fontWeight.semiBold};
        font-size: 17px;
        color: ${({ theme }) => theme.palette.gray200};
        @media(min-width: 1281px) and (max-width: 1440px){
            font-size: 15px;
        }
    }
    & a{
        text-align: center;
    }
`
