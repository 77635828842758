import { paddingStyle1 } from "core/styles/mixins";
import styled from "styled-components";

export const GridSloganWrap = styled.div`
  background: ${({ theme }) => theme.palette.white};
  border-radius: 0 ${({ theme }) => theme.borderRadius.block};
  box-shadow: 10px 10px 15px ${({ theme }) => theme.palette.gray1000};
  padding:60px;
  margin: ${({ theme }) => theme.contentSpacing} auto 0;
  max-width: 382px;
  @media (min-width: 1200px) {
    max-width: ${({ theme }) => theme.contentWidth};
  }

  & p {
    font-style: italic;
    font-weight: ${({theme}) => theme.fontWeight.light};
    font-size: 14px;
    color: ${({ theme }) => theme.palette.mainText};
    text-align: left;
    @media (min-width: 1200px) {
      font-weight: ${({theme}) => theme.fontWeight.medium};
      font-size: 17px;
      text-align: left;
    }
  }
`;