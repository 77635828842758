import {GET_ARTS, GET_WEAVES} from "./types";

const initialState = {
    artData: [],
    weaveData: []
}

export const nftDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ARTS:
            return {...state, artData: [...action.payload]}
        case GET_WEAVES:
            return {...state, weaveData: [...action.payload]}
        default:
            return state
    }
}