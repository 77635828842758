import { MainLayoutWrapper } from "./styles";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { AniLogo } from "../components/AniLogo";

interface IMainLayout {
  children: JSX.Element;
}

export const MainLayout: React.FC<IMainLayout> = ({ children }) => {
  return (
    <MainLayoutWrapper>
      <Header />
      <AniLogo />
      <>{children}</>
      <Footer />
    </MainLayoutWrapper>
  );
};
