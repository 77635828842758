import { TheArtworkInfo, TheArtworkWrap } from "./style";
import { AweSlider } from "pages/ArtworkAwe/components/AweSlider";

export const TheArtwork = () => (
  <TheArtworkWrap>
    <TheArtworkInfo>
    <h2 className="white">The Artwork</h2>
      <p>
      Imbued with Awe is inspired by the religious use of textiles, the Swedish “Åkdyna”, the pillow you place in the wagon to carry you to church. It is an invitation to connect with that which is larger than ourselves.
      </p><p>
      The 99 photographic NFTs invite their owners to a Ritual of Awe, a meditation designed to experience “the overview effect”. Through this, they get to express their sense of Awe, in the form of sacred words, written on the blockchain. The physical art piece uses these words of love to generate the patterns flowing through the fabric.
    </p><p>
    <b>Medium:</b> Custom woven optical fiber fabric. Woven by the artist at Ekelund Weavers in Horred, Sweden. Made with a cotton warp and optical fiber, cotton and shrinking yarn in the weft. 250 addressable LEDs, controlled by a ESP32 microcontroller. Wooden frame.
    </p>
    <p>
      <b>Dimensions:</b> 135 cm diameter (4.5 ft)
      </p>
      <p>
        <i>“My work is my meditation, and Imbued with Awe came out of the dedication to that meditation. I believe the experience of Awe comes from within ourselves, and that through practice, we all have the ability to create it.”  - Malin Bobeck Tadaa
        </i>
      </p>
    </TheArtworkInfo>
    <AweSlider cls={'artworkSlider'} />
  </TheArtworkWrap>
)