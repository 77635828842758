export const minterAbi = [
    "function mintMinor(uint256 edition) payable",
    "function mintPrice() view returns (uint256)",
    "function nextMinorId(uint256) view returns (uint256)",
    "function maxMinorId(uint256) view returns (uint256)",
    "function minorMintOngoing(uint256) view returns (bool)",
];

export const minterAbiV2 = [
    "function maxWhiteListId()         external view returns (uint16)",
    "function nextId()                 external view returns (uint16)",
    "function maxId()                  external view returns (uint16)",
    "function whitelistPrice()         external view returns (uint256)",
    "function tokenid2claimed(uint256) external view returns (bool)",
    /// Minting using whitelisted tokens.  You pass a list of token ids under
    /// your own, pay `whitelistPrice` * `tokenIds.length`, and receive
    /// `tokenIds.length` newly minted tokens.
    /// @param tokenIds a list of tokens
    "function mint(uint16[] calldata tokenIds) external payable",
];

export const minterAbiV3 = [
    "function mintInfos(uint edition) view returns (uint16 nextId, uint16 maxId, bool openMint, uint216 price)",
    "function mint(uint8 edition, uint8 amount) payable",
    "function mintFriendshipMiami(uint256 tokenId, address friend, string imbuement) payable",
    "function miamiTicketId2claimed(uint256) external view returns (bool)",
]

export const minterAbiV4 = [
    "function mintInfos(uint edition) view returns (uint16 nextId, uint16 maxId, bool openMint, bool canSelfMint, uint208 price)",
    "function mint(uint edition, uint8 amount, address receiver, string imbuement) payable",
]

export const dataAbi = [
    "function imbue(uint256 tokenId, bytes32 imbuement)",
    "function getNumImbuements(uint256 tokenId) view returns (uint256)",
    "function getLastImbuement(uint256 tokenId) view returns (bytes32,address,uint96)",
    "function getEntropy(uint8 edition) view returns (bytes)",
];
export const nftAbi = [
    "function name() view returns (string)",
    "function symbol() view returns (string)",
    "function mintContract() view returns (address)",
    "function dataContract() view returns (address)",
    "function NUM_EDITIONS() view returns (uint256)",
    "function idAndOwner2imbuement(uint256 id, address owner) view returns (string)",

    // From the ERC721 spec.
    // =====================
    /**
     * @dev Emitted when `tokenId` token is transferred from `from` to `to`.
     */
    "event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)",

    /**
     * @dev Emitted when `owner` enables `approved` to manage the `tokenId` token.
     */
    "event Approval(address indexed owner, address indexed approved, uint256 indexed tokenId)",

    /**
     * @dev Emitted when `owner` enables or disables (`approved`) `operator` to manage all of its assets.
     */
    "event ApprovalForAll(address indexed owner, address indexed operator, bool approved)",

    /**
     * @dev Returns the number of tokens in ``owner``'s account.
     */
    "function balanceOf(address owner) external view returns (uint256 balance)",

    /**
     * @dev Returns the owner of the `tokenId` token.
     *
     * Requirements:
     *
     * - `tokenId` must exist.
     */
    "function ownerOf(uint256 tokenId) external view returns (address owner)",

    /**
     * @dev Safely transfers `tokenId` token from `from` to `to`, checking first that contract recipients
     * are aware of the ERC721 protocol to prevent tokens from being forever locked.
     *
     * Requirements:
     *
     * - `from` cannot be the zero address.
     * - `to` cannot be the zero address.
     * - `tokenId` token must exist and be owned by `from`.
     * - If the caller is not `from`, it must be have been allowed to move this token by either {approve} or {setApprovalForAll}.
     * - If `to` refers to a smart contract, it must implement {IERC721Receiver-onERC721Received}, which is called upon a safe transfer.
     *
     * Emits a {Transfer} event.
     */
    "function safeTransferFrom(address from, address to, uint256 tokenId) external",

    /**
     * @dev Transfers `tokenId` token from `from` to `to`.
     *
     * WARNING: Usage of this method is discouraged, use {safeTransferFrom} whenever possible.
     *
     * Requirements:
     *
     * - `from` cannot be the zero address.
     * - `to` cannot be the zero address.
     * - `tokenId` token must be owned by `from`.
     * - If the caller is not `from`, it must be approved to move this token by either {approve} or {setApprovalForAll}.
     *
     * Emits a {Transfer} event.
     */
    "function transferFrom(address from, address to, uint256 tokenId) external",

    /**
     * @dev Gives permission to `to` to transfer `tokenId` token to another account.
     * The approval is cleared when the token is transferred.
     *
     * Only a single account can be approved at a time, so approving the zero address clears previous approvals.
     *
     * Requirements:
     *
     * - The caller must own the token or be an approved operator.
     * - `tokenId` must exist.
     *
     * Emits an {Approval} event.
     */
    "function approve(address to, uint256 tokenId) external",

    /**
     * @dev Returns the account approved for `tokenId` token.
     *
     * Requirements:
     *
     * - `tokenId` must exist.
     */
    "function getApproved(uint256 tokenId) external view returns (address operator)",

    /**
     * @dev Approve or remove `operator` as an operator for the caller.
     * Operators can call {transferFrom} or {safeTransferFrom} for any token owned by the caller.
     *
     * Requirements:
     *
     * - The `operator` cannot be the caller.
     *
     * Emits an {ApprovalForAll} event.
     */
    "function setApprovalForAll(address operator, bool _approved) external",

    /**
     * @dev Returns if the `operator` is allowed to manage all of the assets of `owner`.
     *
     * See {setApprovalForAll}
     */
    "function isApprovedForAll(address owner, address operator) external view returns (bool)",

    /**
     * @dev Safely transfers `tokenId` token from `from` to `to`.
     *
     * Requirements:
     *
     * - `from` cannot be the zero address.
     * - `to` cannot be the zero address.
     * - `tokenId` token must exist and be owned by `from`.
     * - If the caller is not `from`, it must be approved to move this token by either {approve} or {setApprovalForAll}.
     * - If `to` refers to a smart contract, it must implement {IERC721Receiver-onERC721Received}, which is called upon a safe transfer.
     *
     * Emits a {Transfer} event.
     */
    "function safeTransferFrom(address from, address to, uint256 tokenId, bytes calldata data) external",

    /**
     * @dev Returns the total amount of tokens stored by the contract.
     */
    "function totalSupply() external view returns (uint256)",

    /**
     * @dev Returns a token ID owned by `owner` at a given `index` of its token list.
     * Use along with {balanceOf} to enumerate all of ``owner``'s tokens.
     */
    "function tokenOfOwnerByIndex(address owner, uint256 index) external view returns (uint256 tokenId)",

    /**
     * @dev Returns a token ID at a given `index` of all the tokens stored by the contract.
     * Use along with {totalSupply} to enumerate all tokens.
     */
    "function tokenByIndex(uint256 index) external view returns (uint256)"
  ];
