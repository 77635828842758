import { Wrapper } from "./styles";

interface IHeaderWrapper {
    className?: string;
  }

export const HeaderWrapper: React.FC<IHeaderWrapper> = ({ className='', children }) => {
    return (
        <Wrapper className={className}>
            {children}
        </Wrapper>
    )
}