import { css } from "styled-components";
import { theme } from "./theme";

export const paddingStyle1 = css`
    padding: 40px 30px;
`;

export const paddingStyle2 = css`
    padding: 15px 40px;
`;

export const marginBottomStyle1 = css`
    margin-bottom: 10px;
`;

export const marginBottomStyle2 = css`
    margin-bottom: 20px;
`;

export const marginBottomStyle3 = css`
    margin-bottom: 30px;
`;

export const marginBottomStyle4 = css`
    margin-bottom: 40px;
`;

export const backgroundPrimary = css`
    background: ${theme.palette.greenishCyan};
`;

export const backgroundSecondary = css`
    background: ${theme.palette.secondary};
`;

export const disableButtonStyle = css`
    opacity: 0.6;
    pointer-events: none;
`;

export const buttonStyle = css`
    font-weight: ${theme.fontWeight.bold};
    border-radius: ${theme.borderRadius.default};
    color: ${theme.palette.white};
    cursor: pointer;
`;

export const buttonAlignStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export const buttonSmallSize = css`
    height: 36px;
    width: 145px;
`;

export const buttonMediumSize = css`
    height: 50px;
    width: 182px;
`;

export const buttonBigSize = css`
    height: 50px;
    width: fit-content;
    max-width: 400px;
`;

export const defaultButtonStyle = css`
    ${paddingStyle2}
    ${buttonAlignStyle}
    font-size: 17px;
    ${buttonStyle}
`;

export const smallButtonStyle = css`
    padding: 10px;
    ${buttonSmallSize}
    font-size: 15px;
`;

export const mediumButtonStyle = css`
    padding: 10px;
    ${buttonMediumSize}
    font-size: 15px;
`;


export const buttonCSS = css`
  ${buttonBigSize}
  ${defaultButtonStyle}
  ${backgroundPrimary}
  
  @media (max-width: 425px) {
    padding: 15px 20px;
  }

  &.disabled {
    ${disableButtonStyle}
  }

  &.small{
    ${smallButtonStyle}
  }

  &.medium {
    ${mediumButtonStyle}
  }

  &.secondary {
    ${backgroundSecondary}
  }
`
