import {marginBottomStyle1, paddingStyle1 } from "core/styles/mixins";
import styled from "styled-components";

export const MyArtworkDetailedSection = styled.div`
  position: relative;
  z-index: 2;
`;

export const MyArtworkInfo = styled.div`
  ${paddingStyle1}
  background: ${({ theme }) => theme.palette.gray900};
  border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 0;
  @media (min-width: 992px) {
    padding: 50px;
  }
`;

export const MyArtworkDisclaimer = styled.div`
  padding: 40px;
  background: ${({ theme }) => theme.palette.gray900};
  & p {
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 14px;
    color: ${({ theme }) => theme.palette.paragraph};
    text-align: center;
  }
`;

export const MyArtworkComment = styled.div`
  ${paddingStyle1}
  background: ${({ theme }) => theme.palette.mainText};
  @media (min-width: 992px) {
    padding: 50px;
  }

  & h3 {
    text-align: center;
    color: ${({ theme }) => theme.palette.white};
  }
`;

export const FormWrap = styled.div`
  margin: auto;
  max-width: 420px;

  & form {
    display: flex;
  }

  & input {
    background: ${({ theme }) => theme.palette.white};
    border-radius: ${({ theme }) => theme.borderRadius.default} 0 0 ${({ theme }) => theme.borderRadius.default};
    padding: 14px 0 14px 22px;
    width: 100%;
    font-weight: ${({theme}) => theme.fontWeight.semiBold};
    font-size: 15px;
    color: ${({ theme }) => theme.palette.paragraph};

    &::placeholder {
      font-weight: ${({theme}) => theme.fontWeight.medium};
      font-size: 15px;
      color: ${({ theme }) => theme.palette.mainText};
    }
  }

  & button {
    padding: 14px 25px;
    border-radius: 0 ${({ theme }) => theme.borderRadius.default} ${({ theme }) => theme.borderRadius.default} 0;
    background: ${({ theme }) => theme.palette.gray200};
    color: ${({ theme }) => theme.palette.mainText};
  }

  & .formError {
    font-weight: ${({theme}) => theme.fontWeight.bold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.greenishCyan};
    margin: 10px 0 0;
  }
`;

export const MyArtworkVideo = styled.div`
  max-height: 745px;
  height: 100%;
  margin-bottom: 0;
  
  & .imbuedVideo {
    width: 100%;
    margin-bottom: -10px;
  }
`;
