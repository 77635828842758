import ethIcon from 'core/assets/img/icons/Ethereum-Icon-Purple-Logo.svg'
import { paddingStyle1 } from "core/styles/mixins";
import styled from "styled-components";

export const NFTDetailedSection = styled.div`
  position: relative;
  ${paddingStyle1}
  background: ${({ theme }) => theme.palette.white};
  border-radius: 0 ${({ theme }) => theme.borderRadius.block} 0 ${({ theme }) => theme.borderRadius.block};
  z-index: 2;

  .button-anchor {
    display: flex;
    margin-left: auto;
  }
`;

export const NFTDetailedHeader = styled.div`
  margin: 20px 0 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h1 {
    text-align: left;
  }
`;

export const LoadingNftData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
`;


// Delete class: &.life, &.grief, &.longing, &.appreciation, &.friendship ... if need 1x1 image format
export const NftImage = styled.div`
  max-width: 1200px;
  max-height: 1200px;
  margin: 40px auto;
  background-color: ${({ theme }) => theme.palette.secondary};

  &.love,
  &.life,
  &.grief,
  &.longing,
  &.appreciation,
  &.friendship
  {
    max-height: 800px;
    max-width:  800px;
  }
  

  & img {
    display: flex;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const NftInformationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.contentSpacing};
  color: ${({ theme }) => theme.palette.paragraph};
  flex-wrap: wrap;
  & > div {
    width: 48%;
    margin: 20px 0;
    
    p {
      margin-bottom: 20px;

      &.eth-price-wrapper {
        display: flex;
        align-items: center;
      }
      font-size: 17px;
      span {
        color: ${({ theme }) => theme.palette.mainText};
      }
      .eth-icon {
          width: 18px;
          height: 26px;
          background: url(${ethIcon}) no-repeat;
          margin-right: 10px;
      }
    }
  }

  @media (max-width: 1300px) {
    & > div {
      width: 100%;
    }
  }
`;