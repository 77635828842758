import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

const RPC_URLS: { [chainId: number]: string } = {
  1: "https://mainnet.infura.io/v3/d8d910306de34a6e994efdcce0fae846",
  137: "https://polygon-mainnet.infura.io/v3/d8d910306de34a6e994efdcce0fae846",
};

export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 5, 42, 137],
});

export const walletconnect = new WalletConnectConnector({
  rpc: { 1: RPC_URLS[1], 4: RPC_URLS[137] },
  qrcode: true,
});
