import { paddingStyle1, marginBottomStyle2, marginBottomStyle4, paddingStyle2 } from "core/styles/mixins";
import styled from "styled-components";

export const DownloadedArtworksSection = styled.div`
  @media (min-width: 992px) {
    display: flex;
  }
`;

export const ArtworksInfo = styled.div`
  ${paddingStyle1}
  background: ${({ theme }) => theme.palette.white};
  @media (min-width: 992px) {
    width: 50%;
    border-radius: 0 0 0 ${({ theme }) => theme.borderRadius.block};
    padding: 50px;
  }

  & h2 {
    text-align: center;
    @media (min-width: 992px) {
      text-align: start;
    }
  }

  & .itemName {
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: 22px;
    color: ${({ theme }) => theme.palette.mainText};
    ${marginBottomStyle2}
    @media (min-width: 992px) {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      font-size: 28px;
    }
  }

  & .itemComment {
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: 17px;
    color: ${({ theme }) => theme.palette.paragraph};
    ${marginBottomStyle4}
  }

  & button {
    ${paddingStyle2}
    background: ${({ theme }) => theme.palette.greenishCyan};
    display: block;
    margin: auto;
    @media (min-width: 992px) {
      margin: unset;
    }
  }
`;
export const ArtworksImg = styled.div`
  border-radius: 0 0 0 ${({ theme }) => theme.borderRadius.block};
  overflow: hidden;
  @media (min-width: 992px) {
    width: 50%;
    border-radius: unset;
  }

  & img {
    height: 270px;
    width: 100%;
    object-fit: cover;
    @media (min-width: 992px) {
      height: 100%;
    }
  }
`;