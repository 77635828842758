import {
    ARTWORKS_ROUTE, ARTWORK_AWE,
    DEFAULT_ROUTE,
    DIGITAL_WEAVE_ROUTE,
    NFTS,
    FAQ_ROUTE,
    MINT_NFT,
    MINT_LIFE,
    MINT_LONGING,
    MINT_FRIENDSHIP,
    MY_WALLET,
    MY_WEAVE,
    ARTWORK_LOVE,
    MY_NFT,
    ARTWORK_LIFE,
    ARTWORK_GRIEF,
    ARTWORK_LONGING,
    ARTWORK_FRIENDSHIP,
    ARTWORK_APPRECIATION
} from "./paths";
import { HomePage } from "pages/HomePage";
import { Artworks } from "pages/Artworks";
import { DigitalWeave } from "pages/DigitalWeave";
import { FAQ } from "pages/FAQ";
import { MintNft } from "pages/MintNft";
import { MintLife } from "pages/MintLife";
import { MintLonging } from "pages/MintLonging";
import { MintFriendship } from "pages/MintFriendship";
import { ArtworkLove } from "pages/ArtworkLove";
import { ArtworkAwe } from "pages/ArtworkAwe";
import { ArtworkLife } from "pages/ArtworkLife";
import { ArtworkGrief } from "pages/ArtworkGrief";
import { ArtworkLonging } from "pages/ArtworkLonging";
import { ArtworkAppreciation } from "pages/ArtworkAppreciation";
import { ArtworkFriendship } from "pages/ArtworkFriendship";
import { MyWallet } from "pages/MyWallet";
import { MyWeaveDetailed } from "pages/MyWeaveDetailed";
import { NFTDetailed } from "pages/NFTDetailed";
import { Redirect } from "react-router-dom";
import { PageNotFound } from "pages/PageNotFound";

export const PUBLIC_ROUTES = [
    { path: DEFAULT_ROUTE, key: "ROOT", exact: true, component: HomePage },
    { path: ARTWORKS_ROUTE, key: "ARTWORKS", exact: true, component: Artworks },
    { path: NFTS, key: "NFTS", exact: true, component: DigitalWeave },
    { path: DIGITAL_WEAVE_ROUTE, key: "DIGITAL_WEAVE", exact: true, component: () => <Redirect to={NFTS} /> },
    { path: MY_NFT, key: "MY_NFT", exact: true, component: NFTDetailed },
    { path: FAQ_ROUTE, key: "FAQ", exact: true, component: FAQ },
    { path: MINT_NFT, key: "MINT_NFT", exact: true, component: MintNft },
    { path: MINT_LIFE, key: "MINT_LIFE", exact: true, component: MintLife },
    { path: MINT_LONGING, key: "MINT_LONGING", exact: true, component: MintLonging },
    { path: MINT_FRIENDSHIP, key: "MINT_FRIENDSHIP", exact: true, component: MintFriendship },
    { path: ARTWORK_LOVE, key: "ARTWORK_LOVE", exact: true, component: ArtworkLove },
    { path: ARTWORK_AWE, key: "ARTWORK_AWE", exact: true, component: ArtworkAwe },
    { path: ARTWORK_LIFE, key: "ARTWORK_LIFE", exact: true, component: process.env.REACT_APP_LIFE === 'true' ? ArtworkLife : PageNotFound },
    { path: ARTWORK_GRIEF, key: "ARTWORK_GRIEF", exact: true, component: process.env.REACT_APP_GRIEF === 'true' ? ArtworkGrief : PageNotFound },
    { path: ARTWORK_LONGING, key: "ARTWORK_LONGING", exact: true, component: ArtworkLonging },
    { path: ARTWORK_APPRECIATION, key: "ARTWORK_APPRECIATION", exact: true, component: ArtworkAppreciation },
    { path: ARTWORK_FRIENDSHIP, key: "ARTWORK_FRIENDSHIP", exact: true, component: ArtworkFriendship },
    { path: MY_WALLET, key: "MY_WALLET", exact: true, component: MyWallet },
    { path: MY_WEAVE, key: "MY_WEAVE", exact: true, component: MyWeaveDetailed },

    
    //example for the nested routes
    // {
    //     path: "/app",
    //     key: "APP",
    //     component: RenderRoutes,
    //     routes: [
    //         {
    //             path: "/app",
    //             key: "APP_ROOT",
    //             exact: true,
    //             component: () => <h1>App Index</h1>,
    //         },
    //         {
    //             path: "/app/page",
    //             key: "APP_PAGE",
    //             exact: true,
    //             component: () => <h1>App Page</h1>,
    //         },
    //     ],
    // },
];