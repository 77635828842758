export const DEFAULT_ROUTE = '/';
export const ARTWORKS_ROUTE = '/artworks';
export const DIGITAL_WEAVE_ROUTE = '/digital-weave';
export const NFT = '/nft';
export const NFTS = `${NFT}s`;
export const MY_NFT = `${NFT}/:id`;
export const FAQ_ROUTE = '/faq';
export const MINT_NFT = '/mint-nft';
export const MINT_LIFE = '/mint/life';
export const MINT_LONGING = '/mint/longing';
export const MINT_FRIENDSHIP = '/mint/friendship';
export const ARTWORK_LOVE = '/artworks/love';
export const ARTWORK_AWE = '/artworks/awe';
export const ARTWORK_LIFE = '/artworks/life';
export const ARTWORK_GRIEF = '/artworks/grief';
export const ARTWORK_LONGING = '/artworks/longing';
export const ARTWORK_APPRECIATION = '/artworks/appreciation';
export const ARTWORK_FRIENDSHIP = '/artworks/friendship';
export const MY_WALLET = '/my-wallet';
export const MY_WEAVE = `${MY_WALLET}/:id`;
