import { paddingStyle1 } from "core/styles/mixins";
import styled from "styled-components";

export const YourNftWeavesWrap = styled.div`
  margin-top: ${({ theme }) => theme.contenSpacing};
`;

export const YourNftWeavesTitle = styled.h2`
  text-align: center;
`;
export const LoadingNftWeaves = styled.div`
  ${paddingStyle1}
  background: ${({ theme }) => theme.palette.white};
  font-weight: ${({theme}) => theme.fontWeight.semiBold};
  font-size: 22px;
  color: ${({ theme }) => theme.palette.mainText};
  display: flex;
  justify-content: center;
  @media(min-width: 1200px) {
    align-items: center;
  }
  & p {
    margin-right: 10px;
    @media(min-width: 1200px) {
      font-weight: ${({theme}) => theme.fontWeight.bold};
      font-size: 28px;
    }
  }
  & svg {
    width: 30px;
    height: 30px;
  }
`;
