import styled from "styled-components";

export const LogoPlaceholder = styled.div`
    width: 100%;
    position: absolute;
    top: -12%;
    z-index: 2;
    height: 1600px;
    @media(max-width: 1440px){
        top: -12%;
    }
    @media(max-width: 1280px){
        top: -5%;
    }
    @media(max-width: 425px){
        top: -37px;
        height: 369px;
    }
    @media(max-width: 375px){
        top: -37px;
        height: 369px;
    }
    & iframe{
        width: 100%;
        height: 100%;
    }
`;