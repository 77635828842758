import { useEffect } from "react";
import { ImbuedWithLove } from "./components/ImbuedWithLove";
import { ImbuedWithAwe } from "./components/ImbuedWithAwe";
import { ImbuedWithLife } from "../MintLife/components/ImbuedWithLife";
import { MintNftWrapper } from "./style";
import { HeaderWrapper } from "shared/components/HeaderWrapper";
import { ImbuedWithFriendship } from "../MintFriendship/components/ImbuedWithFriendship";
import { ImbuedWithLonging } from "../MintLonging/components/ImbuedWithLonging";
import { ImbuedWithFriendshipSpecial } from "../MintFriendship/components/ImbuedWithFriendshipMiamiSpecial";

export const MintNft = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MintNftWrapper className="content-wrapper">
      <HeaderWrapper>
        <h1>Mint your own NFT weave</h1>
      </HeaderWrapper>
      <ImbuedWithFriendship />
      <ImbuedWithLife />
      <ImbuedWithLonging />
    </MintNftWrapper>
  );
};
